import { Col, Layout, Typography, Table, Row } from "antd";
import React, { useContext, useEffect } from "react";
import moment from "moment";
import HeaderMain from "../../Components/Headers/Header";
import ViewContext from "../../Context/ViewContext";
import { amountFormatter } from "../../Functions/formatters";
import { colorsLight } from "../../Styles/Themes";
import UserContext from "../../Context/UserContext";

const { Content } = Layout;

const columns = [
	{
		title: "Description",
		dataIndex: "description",
		key: "description",
	},
	{
		title: "Amount",
		dataIndex: "amount",
		key: "amount",
		render: (text, record) => (
			<p
				style={
					record.type === "credit"
						? styles.credit
						: record.type === "debit" || record.type === "purchase"
						? styles.debit
						: { margin: 0 }
				}
				data-testid="transactions-amount"
			>
				{amountFormatter(text, record.type)}
			</p>
		),
	},
	{
		title: "Initiator Name",
		dataIndex: "initiatorName",
		render: (text) => <span data-testid="transactions-initiator">{text || "-"}</span>,
	},
	{
		title: "Reason",
		dataIndex: "reason",
		key: "reason",
		render: (text) => <span data-testid="transactions-reason">{text || "-"}</span>,
	},
	{
		title: "Date",
		dataIndex: "created",
		key: "created",
		render: (text) => (
			<span data-testid="transactions-date">
				{moment(text).format("MMMM D YYYY @ h:mm a")}
			</span>
		),
	},
];

const MyTransactionsPage = () => {
	const { isMobile } = useContext(ViewContext);
	const { transactions } = useContext(UserContext);
	const scrollRef = React.createRef();

	useEffect(() => {
		scrollRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
		// eslint-disable-next-line
	}, []);

	return (
		<Layout className={isMobile ? "layout" : "App"}>
			<HeaderMain active="t" />
			<Content
				style={{ overflowY: "scroll", paddingBottom: 42, paddingTop: isMobile ? 54 : 0 }}
				className="content"
			>
				<Row ref={scrollRef}>
					<Col
						style={{
							height: "100%",
							backgroundColor: colorsLight("lightgrey"),
							padding: isMobile ? 12 : 24,
						}}
					>
						<Typography.Title level={1} data-testid="transactions-title">
							My Transactions
						</Typography.Title>
						<Col className="Card">
							<Table
								dataSource={transactions}
								columns={columns}
								style={{ overflowY: "auto" }}
							/>
						</Col>
					</Col>
				</Row>
			</Content>
		</Layout>
	);
};

const styles = {
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	credit: {
		fontSize: 14,
		fontWeight: 600,
		margin: 0,
		color: colorsLight("green"),
	},
	debit: {
		fontSize: 14,
		fontWeight: 600,
		margin: 0,
		color: colorsLight("darkRed"),
	},
};

export default MyTransactionsPage;
