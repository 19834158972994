import { auth, database, firestore } from "firebase";
import React, { useEffect, useState } from "react";

const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
	const [authd, setAuthd] = useState(false);
	const [uid, setUid] = useState("");
	const [userInfo, setUserInfo] = useState({ firstName: " ", lastName: "" });
	const [loading, setLoading] = useState(true);
	const [facilitator, setFacilitator] = useState({});
	const [transactions, setTransactions] = useState([]);
	const [accountBalance, setAccountBalance] = useState(0);
	const [playlistData, setPlaylistData] = useState("none");
	const [events, setEvents] = useState([]);

	useEffect(() => {
		setLoading(true);
		auth().onAuthStateChanged((user) => {
			if (user) {
				setAuthd(true);
				setUid(user.uid);
				console.log(user);
			} else {
				setAuthd(false);
				setLoading(false);
			}
		});
	}, []);

	useEffect(() => {
		if (uid) {
			let listener = firestore()
				.collection("Members")
				.doc(uid)
				.onSnapshot((doc) => {
					if (doc.exists) {
						setUserInfo({ ...doc.data(), key: doc.id });
						setLoading(false);
					} else {
						auth().signOut();
					}
				});

			let listener2 = firestore()
				.collection("Events")
				.where("attendees", "array-contains", uid)
				.onSnapshot((docs) => {
					let list = [];
					docs.forEach((doc) => {
						list.push({ ...doc.data(), key: doc.id });
					});
					setEvents(list);
				});

			return () => {
				listener();
				listener2();
			};
		}
	}, [uid]);

	useEffect(() => {
		if (uid) {
			const transactionsRef = database().ref(`Ledger/Accounts/${uid}/transactions`);
			const balanceRef = database().ref(`Ledger/Accounts/${uid}/balance`);

			transactionsRef.on("value", (snapshot) => {
				const dat = snapshot.val();
				if (dat) {
					let keys = Object.keys(dat);
					let list = [];
					keys.forEach((item) => {
						list.push({ ...dat[item], key: item });
					});
					const sorted = list.sort((a, b) => new Date(b.created) - new Date(a.created));
					setTransactions(sorted);
				}
			});

			balanceRef.on("value", (snapshot) => {
				const data = snapshot.val();
				console.log(data);
				setAccountBalance(data);
			});

			return () => {
				transactionsRef.off();
				balanceRef.off();
			};
		}
	}, [uid]);

	//GETS FACILITATOR DATA - IS HARDCODED TO BE DAN
	useEffect(() => {
		if (!facilitator.firstName) {
			firestore()
				.collection("Admins")
				.doc("MgBXZe9czqfGBCBaLZb9KYD0GFX2")
				.get()
				.then((doc) => doc.exists && setFacilitator({ ...doc.data(), key: doc.id }));
		}
	});

	useEffect(() => {
		if (userInfo.activePlaylist) {
			firestore()
				.collection("Playlists")
				.doc(userInfo.activePlaylist)
				.get()
				.then((doc) => {
					if (doc.exists) {
						setPlaylistData({ ...doc.data(), key: doc.id });
					} else {
						console.log("Could not get playlist data");
					}
				})
				.catch((err) => console.log(err));
		} else if (userInfo.firstName.length > 1) {
			setPlaylistData(false);
		} else {
			setPlaylistData("none");
		}
	}, [userInfo]);

	return (
		<UserContext.Provider
			value={{
				authd,
				setAuthd,
				uid,
				setUid,
				userInfo,
				setUserInfo,
				loading,
				facilitator,
				setFacilitator,
				transactions,
				accountBalance,
				setAccountBalance,
				playlistData,
				setPlaylistData,
				events,
				setEvents,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

export default UserContext;
