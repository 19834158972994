import { Col, Layout, message, Row } from "antd";
import { firestore } from "firebase";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import HeaderMain from "../../Components/Headers/Header";
import FacilitatorPanel from "../../Components/Home/FacilitatorPanel";
import BackButton from "../../Components/Navigation/BackButton";
import AllPackages from "../../Components/ViewCoach/AllPackages";
import CoachDetails from "../../Components/ViewCoach/CoachDetails";
import ViewContext from "../../Context/ViewContext";
import { colorsLight, maxContentWidth } from "../../Styles/Themes";

const { Content } = Layout;

const ViewCoachPage = () => {
	const { isMobile, isIpad } = useContext(ViewContext);
	const { coachId } = useParams();
	const history = useHistory();
	const [coachData, setCoachData] = useState({});
	const [packages, setPackages] = useState([]);

	useEffect(() => {
		firestore()
			.collection("Coaches")
			.doc(coachId)
			.get()
			.then((doc) => {
				doc.exists && setCoachData({ ...doc.data(), key: doc.id });
			})
			.catch((err) => message.error(err));

		firestore()
			.collection("Packages")
			.where("owner", "==", coachId)
			.get()
			.then((snap) => {
				let list = [];
				snap.forEach((doc) => {
					list.push({ ...doc.data(), key: doc.id });
				});
				setPackages(list);
			})
			.catch((err) => message.error(err));
	}, [coachId]);

	return (
		<Layout className={isMobile ? "layout" : "App"}>
			<HeaderMain />
			<Content
				style={{
					overflow: "scroll",
					paddingBottom: isMobile ? 42 : 24,
					paddingTop: isMobile ? 54 : 0,
				}}
				className="content"
			>
				<Row justify="center">
					<Col
						style={{
							backgroundColor: colorsLight("lightgrey"),
							height: "100%",
							maxWidth: isMobile || isIpad ? null : maxContentWidth,
						}}
						span={24}
					>
						<Row style={{ padding: isMobile ? "12px" : "12px 24px 12px 24px" }}>
							<BackButton history={history} />
						</Row>
						<Row style={{ padding: isMobile ? "12px" : "0px 24px 12px 24px" }}>
							<Col span={!isMobile && !isIpad ? 16 : 24} data-testid="coach">
								<CoachDetails data={coachData} />
							</Col>
							{!isMobile && !isIpad && (
								<Col
									style={{ paddingLeft: 24 }}
									span={8}
									data-testid="coach-facilitator"
								>
									<FacilitatorPanel />
								</Col>
							)}
						</Row>
						{packages.length > 0 && (
							<Row
								style={{
									padding: isMobile ? "12px 0px 12px 12px" : "24px 0px 12px 24px",
								}}
								data-testid="coach-packages"
							>
								<AllPackages data={packages} />
							</Row>
						)}
					</Col>
				</Row>
			</Content>
		</Layout>
	);
};

export default ViewCoachPage;
