import {
	CrownOutlined,
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	PieChartOutlined,
	// RocketOutlined,
	// SolutionOutlined,
	// ThunderboltOutlined,
} from "@ant-design/icons";
import { Drawer, Menu, Row } from "antd";
import { motion } from "framer-motion";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../Assets/logo-small.png";
import ViewContext from "../../Context/ViewContext";
import { colorsLight } from "../../Styles/Themes";
import TopMenu from "../Account/TopMenu";

const menus = [
	{
		name: "Home",
		icon: <PieChartOutlined />,
		link: "/Home",
		testId: "menu-home",
	},
	{
		name: "My Program",
		icon: <PieChartOutlined />,
		link: "/My-Program",
		testId: "menu-program",
	},
	{
		name: "Featured",
		icon: <CrownOutlined />,
		link: "/Featured",
		testId: "menu-featured",
	},
	// {
	// 	name: "Coaches",
	// 	icon: <RocketOutlined />,
	// 	link: "/Browse-Coaches",
	// 	testId: "menu-coaches",
	// },
	// {
	// 	name: "Courses",
	// 	icon: <SolutionOutlined />,
	// 	link: "/Browse-Courses",
	// 	testId: "menu-courses",
	// },
	// {
	// 	name: "Services",
	// 	icon: <ThunderboltOutlined />,
	// 	link: "/Browse-Services",
	// 	testId: "menu-services",
	// },
];

const variants = {
	open: { marginLeft: "150px", paddingRight: "150px" },
	closed: { marginLeft: "0px", paddingRight: "0px" },
};

const MobileMenu = ({ active }) => {
	const { drawerOpen, setDrawerOpen, setIsCollapsed, isLaptop, isIpad, windowWidth } = useContext(
		ViewContext
	);

	useEffect(() => {
		isLaptop || isIpad ? setIsCollapsed(true) : setIsCollapsed(false);
	}, [isLaptop, isIpad, setIsCollapsed]);

	return (
		<motion.div
			data-testid="header-mobile"
			animate={drawerOpen ? "open" : "closed"}
			transition={{ duration: 0.25 }}
			variants={variants}
			style={{
				height: 54,
				width: windowWidth,
				minHeight: 54,
				backgroundColor: "white",
				display: "flex",
				flexDirection: "row",
				zIndex: 2,
				boxShadow: "1px 2px 7px rgba(0,0,0,0.1)",
				paddingLeft: 12,
				position: "fixed",
			}}
		>
			<Row justify="space-between" style={{ width: "100%" }}>
				<Row
					onClick={() => setDrawerOpen(!drawerOpen)}
					justify="center"
					align="middle"
					style={{ height: "100%" }}
				>
					{drawerOpen ? (
						<MenuFoldOutlined style={{ fontSize: 22 }} data-testid="menu-close" />
					) : (
						<MenuUnfoldOutlined style={{ fontSize: 22 }} data-testid="menu-open" />
					)}
				</Row>
				<Row
					style={{ height: "100%", paddingRight: 12 }}
					justify="end"
					align="middle"
					data-testid="menu-options"
				>
					<TopMenu />
				</Row>
			</Row>
			<Drawer
				style={{ zIndex: 1, position: "fixed" }}
				visible={drawerOpen}
				placement="left"
				closable={false}
				bodyStyle={{ padding: 0, backgroundColor: "#001529" }}
				width={150}
				data-testid="menu-drawer"
			>
				<div
					style={{
						backgroundColor: colorsLight("primary"),
						height: 54,
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<img
						src={Logo}
						alt="Careerbase logo"
						style={{ height: drawerOpen ? 18 : 22, alignSelf: "center" }}
						data-testid="menu-logo"
					/>
				</div>
				<Menu theme="dark" mode="inline" defaultSelectedKeys="1" selectedKeys={active}>
					{menus.map((menu, index) => (
						<Menu.Item key={`${index + 1}`} icon={menu.icon}>
							<Link
								to={menu.link}
								onClick={() => setDrawerOpen(false)}
								data-testid={menu.testId}
							>
								{menu.name}
							</Link>
						</Menu.Item>
					))}
				</Menu>
			</Drawer>
		</motion.div>
	);
};

export default MobileMenu;
