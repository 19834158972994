import { Avatar, Badge, Button, Col, Row, Typography, Skeleton } from "antd";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ViewContext from "../../../Context/ViewContext";
import { coachTagFormatter, getInitials, priceFormatter } from "../../../Functions/formatters";
import { getImage } from "../../../Functions/utilities";
import { colorsLight } from "../../../Styles/Themes";

const { Paragraph } = Typography;

const CoachCard = ({ data = {}, loading, index, width }) => {
	const hideBadge = window.location.pathname.split("/")[1].toLocaleLowerCase().includes("view");
	const { isMobile } = useContext(ViewContext);
	const buttonLink = data && `/View-Package/${data.owner}&${data.key}`;

	let namCount = data.name ? data.name.length : 0;

	const styles = {
		wrapper: {
			width: width === "free" ? "100%" : 494,
			borderRadius: 9,
			backgroundColor: colorsLight("white"),
			margin: width === "free" ? 0 : "0px 26px 36px 0px",
			display: "block",
		},
		headerImage: {
			backgroundImage: data ? `url(${data.coverPic ? data.coverPic : getImage()})` : null,
			backgroundSize: "cover",
			height: 123,
			width: width === "free" ? "100%" : 494,
			borderRadius: "9px 9px 0px 0px",
		},
		name: {
			fontSize: 18,
			color: colorsLight("white"),
			fontWeight: "bold",
			lineHeight: 1.3,
			marginTop: 20,
		},
		avatar: {
			padding: 4,
			backgroundColor: "white",
			boxShadow: "1px 1px 6px rgba(0,0,0,0.2)",
			borderRadius: 62,
			marginTop: 0,
			maxWidth: 88,
		},
		imageOverlay: {
			backgroundColor: loading ? "rgb(216 216 216)" : "rgba(2, 48, 71,0.7)",
			height: "100%",
			width: width === "free" ? "100%" : 494,
			borderRadius: "9px 9px 0px 0px",
			margin: 0,
			padding: "0px 20px",
		},
		label: {
			fontSize: 13,
			color: "#565656",
		},
		text: {
			fontWeight: 600,
			fontSize: 14,
			color: "#000000",
		},
		pts: {
			fontWeight: 600,
			fontSize: 18,
			color: colorsLight("lightBlue"),
			margin: 0,
		},
		row: {
			width: "100%",
			padding: "6px 20px",
		},
		summary: {
			marginBottom: 0,
			minHeight: 65,
		},
		button: {
			width: "100%",
			padding: 12,
		},
	};

	const mobStyles = {
		wrapper: {
			width: width === "free" ? "100%" : 330,
			borderRadius: 9,
			backgroundColor: colorsLight("white"),
			margin: 0,
			display: "block",
		},
		headerImage: {
			backgroundImage: data ? `url(${data.coverPic ? data.coverPic : getImage()})` : null,
			backgroundSize: "cover",
			height: 117,
			width: width === "free" ? "100%" : 330,
			borderRadius: "9px 9px 0px 0px",
		},
		name: {
			fontSize: 16,
			color: colorsLight("white"),
			fontWeight: "bold",
			margin: 0,
		},
		avatar: {
			padding: 2,
			backgroundColor: "white",
			boxShadow: "1px 1px 6px rgba(0,0,0,0.2)",
			borderRadius: 62,
			marginTop: 0,
			maxWidth: 74,
		},
		imageOverlay: {
			backgroundColor: loading ? "rgb(216 216 216)" : "rgba(2, 48, 71,0.7)",
			height: 117,
			width: width === "free" ? "100%" : 330,
			borderRadius: "9px 9px 0px 0px",
			margin: 0,
			padding: "0px 12px",
		},
		label: {
			fontSize: 13,
			color: "#565656",
		},
		text: {
			fontWeight: 600,
			fontSize: 14,
			color: "#000000",
		},
		pts: {
			fontWeight: 600,
			fontSize: 16,
			color: colorsLight("lightBlue"),
			margin: 0,
		},
		row: {
			width: "100%",
			padding: "6px 12px",
		},
		summary: {
			marginBottom: 0,
			minHeight: 65,
		},
		button: {
			width: "100%",
			padding: 12,
		},
	};

	return (
		<>
			{!isMobile ? (
				//DESKTOP VERSION
				<Row key={index} style={styles.wrapper}>
					<Badge.Ribbon
						style={{ display: hideBadge || loading ? "none" : "block" }}
						color={coachTagFormatter(data.primaryCategory)}
						placement="start"
						text={
							<span data-testid="package-tag">
								{data.primaryCategory
									? data.primaryCategory.toUpperCase()
									: "LOADING"}
							</span>
						}
					>
						<Row style={styles.headerImage} data-testid="package-header">
							<Row
								justify="space-between"
								align="top"
								style={styles.imageOverlay}
								data-testid="package-overlay"
							>
								<Col
									style={{
										paddingTop: namCount > 28 ? 36 : loading ? 22 : 42,
										paddingRight: 20,
									}}
									span={17}
								>
									{loading ? (
										<Skeleton active paragraph={{ rows: 1 }} />
									) : (
										<>
											{" "}
											<p style={{ margin: 0, color: "white" }}>
												{data.coachName}
											</p>
											<Typography.Title
												style={{ fontSize: 18, color: "white" }}
												ellipsis={{
													rows: 2,
													expandable: false,
												}}
												data-testid="package-name"
												level={3}
											>
												{data.name}
											</Typography.Title>
										</>
									)}
								</Col>
								<Col
									style={{ height: "100%" }}
									span={7}
									data-testid="package-coach"
								>
									<Row
										style={{ width: "100%", height: "100%" }}
										justify="end"
										align="middle"
									>
										<Row justify="center" align="middle" style={styles.avatar}>
											{loading ? (
												<Skeleton.Avatar active size={80} shape="circle" />
											) : (
												<Avatar src={data.displayPic} size={80}>
													{getInitials(data.name || "")}
												</Avatar>
											)}
										</Row>
									</Row>
								</Col>
							</Row>
						</Row>
						<Row justify="space-between" style={styles.row}>
							<Col span={8}>
								<div className="Key" style={{ margin: 0 }}>
									Delivery:
								</div>
								<div className="Value" data-testid="package-delivery">
									{loading ? (
										<Skeleton
											active
											paragraph={false}
											title={{ width: "80%" }}
										/>
									) : (
										<>
											{data.delivery === "Zoom or In-person"
												? "Any"
												: data.delivery}
										</>
									)}
								</div>
							</Col>
							<Col span={8}>
								<div className="Key" style={{ margin: 0 }}>
									Length:
								</div>
								<div className="Value" data-testid="package-sessions">
									{loading ? (
										<Skeleton
											active
											paragraph={false}
											title={{ width: "80%" }}
										/>
									) : (
										<>{data.sessions} Sessions</>
									)}
								</div>
							</Col>
							<Col span={8}>
								<div className="Key" style={{ margin: 0 }}>
									Price:
								</div>
								<Row>
									{loading ? (
										<Skeleton
											active
											paragraph={false}
											title={{ width: "80%" }}
										/>
									) : (
										<div style={styles.pts} data-testid="package-price">
											<>{priceFormatter(data.price, "points")} pts</>
										</div>
									)}
								</Row>
							</Col>
						</Row>
						<Row style={styles.row}>
							{loading ? (
								<Skeleton active paragraph={{ rows: 3 }} title={false} />
							) : (
								<Paragraph
									className="Value"
									style={styles.summary}
									ellipsis={{
										rows: 3,
										expandable: false,
										symbol: "...",
										suffix: <a href={`${buttonLink}`}>Read on</a>,
									}}
									data-testid="package-summary"
								>
									{data.summary}
								</Paragraph>
							)}
						</Row>
						{!loading && (
							<Row style={styles.button} justify="end">
								<Button>
									<Link to={`${buttonLink}`} data-testid="package-button">
										View Package
									</Link>
								</Button>
							</Row>
						)}
					</Badge.Ribbon>
				</Row>
			) : (
				// MOBILE VERSION
				<Row key={index} style={mobStyles.wrapper}>
					<Badge.Ribbon
						style={{ display: hideBadge || loading ? "none" : "block" }}
						color={coachTagFormatter(data.primaryCategory)}
						placement="start"
						text={
							<span data-testid="package-tag">
								{data.primaryCategory
									? data.primaryCategory.toUpperCase()
									: "LOADING"}
							</span>
						}
					>
						<Row style={mobStyles.headerImage} data-testid="package-header">
							<Row
								justify="space-between"
								align="middle"
								style={mobStyles.imageOverlay}
								data-testid="package-overlay"
							>
								<Col style={{ paddingTop: loading ? 0 : 24 }} span={17}>
									{loading ? (
										<Skeleton active paragraph={{ rows: 1 }} />
									) : (
										<>
											<p style={{ margin: 0, color: "white", fontSize: 12 }}>
												{data.coachName}
											</p>

											<Typography.Title
												ellipsis={{
													rows: 2,
													expandable: false,
												}}
												style={{ color: "white" }}
												data-testid="package-name"
												level={5}
											>
												{data.name}
											</Typography.Title>
										</>
									)}
								</Col>
								<Col span={7} style={mobStyles.avatar} data-testid="package-coach">
									{loading ? (
										<Skeleton.Avatar active size={70} shape="circle" />
									) : (
										<Avatar src={data.displayPic} size={70}>
											{getInitials(data.name || "")}
										</Avatar>
									)}
								</Col>
							</Row>
						</Row>
						<Row justify="space-between" style={mobStyles.row}>
							<Col span={8}>
								<div className="Key" style={{ margin: 0 }}>
									Delivery:
								</div>
								<div className="Value" data-testid="package-delivery">
									{loading ? (
										<Skeleton
											active
											paragraph={false}
											title={{ width: "80%" }}
										/>
									) : (
										<>
											{data.delivery === "Zoom or In-person"
												? "Any"
												: data.delivery}
										</>
									)}
								</div>
							</Col>
							<Col span={8}>
								<div className="Key" style={{ margin: 0 }}>
									Length:
								</div>
								<div className="Value" data-testid="package-sessions">
									{loading ? (
										<Skeleton
											active
											paragraph={false}
											title={{ width: "80%" }}
										/>
									) : (
										<>{data.sessions} Sessions</>
									)}
								</div>
							</Col>
							<Col span={8}>
								<div className="Key" style={{ margin: 0 }}>
									Price:
								</div>
								<Row>
									{loading ? (
										<Skeleton
											active
											paragraph={false}
											title={{ width: "80%" }}
										/>
									) : (
										<div style={mobStyles.pts} data-testid="package-price">
											<>{priceFormatter(data.price, "points")} pts</>
										</div>
									)}
								</Row>
							</Col>
						</Row>
						<Row style={mobStyles.row}>
							{loading ? (
								<Skeleton active paragraph={{ rows: 3 }} title={false} />
							) : (
								<Paragraph
									style={mobStyles.summary}
									ellipsis={{
										rows: 3,
										expandable: false,
										symbol: "...",
										suffix: <a href={`${buttonLink}`}>Read on</a>,
									}}
									data-testid="package-summary"
								>
									{data.summary}
								</Paragraph>
							)}
						</Row>
						{!loading && (
							<Row
								style={mobStyles.button}
								justify="end"
								data-testid="package-button"
							>
								<Button>
									<Link to={`${buttonLink}`}>View Package</Link>
								</Button>
							</Row>
						)}
					</Badge.Ribbon>
				</Row>
			)}
		</>
	);
};

export default CoachCard;
