import { Col, Layout, Typography, Tabs } from "antd";
import React, { useContext, useState, useEffect } from "react";
import HeaderMain from "../../Components/Headers/Header";
import ViewContext from "../../Context/ViewContext";
import { colorsLight } from "../../Styles/Themes";
import ScheduleList from "../../Components/MySchedule/ScheduleList";
import { sortSessions } from "../../Functions/utilities";

const { Content } = Layout;
const { TabPane } = Tabs;

const sessions = [
	{
		title: "REACH Inclusive Leadership",
		startTime: new Date("1 December 2020,13:00:00"),
		endTime: new Date("1 December 2020,15:00:00"),
	},
	{
		title: "Leadership Coaching Package",
		startTime: new Date("29 December 2020,9:00:00"),
		endTime: new Date("29 December 2020,9:45:00"),
	},
	{
		title: "Coaching Career Package",
		startTime: new Date("27 December 2020,14:00:00"),
		endTime: new Date("27 December 2020,15:00:00"),
	},
	{
		title: "12 Week Strength Program: Functional Powerlifting",
		startTime: new Date("25 December 2021,13:00:00"),
		endTime: new Date("25 December 2021,13:30:00"),
	},
	{ title: "Flourish! Parent Wellbeing Coaching Package", startTime: "TBA", endTime: "TBA" },
	{ title: "Nutrition Package (initial + 3 subsequent)", startTime: "TBA", endTime: "TBA" },
	{
		title: "REACH Inclusive Leadership",
		startTime: new Date("1 December 2020,13:00:00"),
		endTime: new Date("1 December 2020,15:00:00"),
	},
	{
		title: "Leadership Coaching Package",
		startTime: new Date("29 December 2020,9:00:00"),
		endTime: new Date("29 December 2020,9:45:00"),
	},
	{
		title: "Coaching Career Package",
		startTime: new Date("27 December 2020,14:00:00"),
		endTime: new Date("27 December 2020,15:00:00"),
	},
	{
		title: "12 Week Strength Program: Functional Powerlifting",
		startTime: new Date("25 December 2021,13:00:00"),
		endTime: new Date("25 December 2021,13:30:00"),
	},
	{ title: "Flourish! Parent Wellbeing Coaching Package", startTime: "TBA", endTime: "TBA" },
	{ title: "Nutrition Package (initial + 3 subsequent)", startTime: "TBA", endTime: "TBA" },
];

const MySchedulePage = () => {
	const { isMobile } = useContext(ViewContext);
	const [sortedSessions, setSortedSessions] = useState({
		completed: [],
		booked: [],
		unbooked: [],
	});

	useEffect(() => {
		const sorted = sortSessions(sessions);
		setSortedSessions(sorted);
		// eslint-disable-next-line
	}, [sessions]);

	return (
		<Layout className={isMobile ? "layout" : "App"}>
			<HeaderMain active="t" />
			<Content
				style={{ overflowY: "scroll", paddingBottom: 42, paddingTop: isMobile ? 54 : 0 }}
				className="content"
			>
				<Col style={styles.main}>
					<Typography.Title level={1} data-testid="myschedule-title">
						My Schedule
					</Typography.Title>
					<Tabs defaultActiveKey="1" data-testid="schedule-list">
						<TabPane tab="Upcoming" key="1">
							<ScheduleList sessions={sortedSessions.booked} />
						</TabPane>
						<TabPane tab="Past" key="2">
							<ScheduleList sessions={sortedSessions.completed} />
						</TabPane>
						<TabPane tab="Unscheduled" key="3">
							<ScheduleList sessions={sortedSessions.unbooked} />
						</TabPane>
					</Tabs>
				</Col>
			</Content>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100%",
		backgroundColor: colorsLight("lightgrey"),
		padding: 24,
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	credit: {
		fontSize: 14,
		fontWeight: 600,
		margin: 0,
		color: colorsLight("green"),
	},
	debit: {
		fontSize: 14,
		fontWeight: 600,
		margin: 0,
		color: colorsLight("darkRed"),
	},
};

export default MySchedulePage;
