import { Button, Col, Row } from "antd";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { colorsLight } from "../../Styles/Themes";

const UpcomingSessionComponent = ({ data }) => {
	return (
		<Col>
			<Row>
				<p style={styles.date}>
					{moment(data.startTime).format("h:mm a dddd | MMM Do YYYY")}
				</p>
			</Row>
			<Row>
				<p style={styles.title}>{data.memberTitle || data.title}</p>
			</Row>
			<Row justify="space-between">
				<Row>
					<p>Status:</p>
					<p style={styles.booked}>Confirmed</p>
				</Row>
				<Link to={`/View-Coaching-Program/${data.programId}`}>
					<Button style={styles.button} type="link">
						View Program
					</Button>
				</Link>
			</Row>
		</Col>
	);
};

const styles = {
	wrapper: {
		width: "100%",
	},
	booked: {
		fontSize: 14,
		color: "#2dc460",
		marginLeft: 6,
		fontWeight: "bold",
	},
	unbooked: {
		fontSize: 14,
		color: "#e0b91b",
		marginLeft: 6,
		fontWeight: "bold",
	},
	date: {
		fontSize: 14,
		margin: 0,
		color: colorsLight("grey"),
	},
	completed: {
		fontSize: 14,
		color: "#2dc460",
		marginLeft: 6,
		fontWeight: "bold",
	},
	title: {
		margin: 0,
		fontSize: 16,
		color: colorsLight("primary"),
		fontWeight: "bold",
	},
	button: {
		lineHeight: 1,
		height: "fit-content",
	},
};

export default UpcomingSessionComponent;
