import { LoadingOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Row, Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useIntercom } from "react-use-intercom";
import UserContext from "../../Context/UserContext";
import { getInitials } from "../../Functions/formatters";
import { colorsLight } from "../../Styles/Themes";
import MyAccountDropdown from "./AccountSettingsDropdown";

const TopMenu = () => {
	const { userInfo } = useContext(UserContext);
	const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
	const { hide, show, boot } = useIntercom();
	const [intercomVis, setIntercomVis] = useState("init");

	const handleIntercom = () => {
		intercomVis ? setIntercomVis(false) : setIntercomVis(true);
	};

	useEffect(() => {
		boot();
	}, [boot]);

	useEffect(() => {
		if (intercomVis) {
			hide();
		} else if (!intercomVis) {
			show();
		} else {
		}
	}, [intercomVis, hide, show]);

	return (
		<Row justify="space-between" style={{ width: 100 }}>
			<div style={{ height: "100%", paddingLeft: 12 }}>
				<Row style={{ paddingTop: 15, height: "100%" }} justify="center" align="middle">
					<QuestionCircleOutlined
						onClick={handleIntercom}
						style={{ fontSize: 20, color: colorsLight("darkGrey"), cursor: "pointer" }}
						data-testid="menu-help"
					/>
				</Row>
			</div>
			{/* <div>
				<Dropdown
					overlay={<NotificationsDropdown />}
				placement="bottomRight"
					trigger={["click"]}
				>
					<Row
						style={{ height: "100%", paddingLeft: 12, paddingRight: 24 }}
						align="middle"
						justify="center"
					>
						<Badge count={6}>
							<BellOutlined
								style={{
									fontSize: 22,
									color: colorsLight("darkGrey"),
									cursor: "pointer",
								}}
								data-testid="menu-notification"
							/>
						</Badge>
					</Row>
				</Dropdown>
			</div> */}
			<div>
				<Dropdown
					overlay={<MyAccountDropdown />}
					placement="bottomRight"
					trigger={["click"]}
					data-testid="menu-account"
				>
					<Avatar
						style={{ cursor: "pointer" }}
						src={userInfo.displayPic}
						size={50}
						data-testid="menu-avatar"
					>
						{userInfo.firstName !== " " ? (
							getInitials(`${userInfo.firstName} ${userInfo.lastName}`)
						) : (
							<div data-testid="menu-spinner">
								<Spin indicator={antIcon} />
							</div>
						)}
					</Avatar>
				</Dropdown>
			</div>
		</Row>
	);
};

export default TopMenu;
