import { Col, Layout, message, Row } from "antd";
import { firestore } from "firebase";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import HeaderMain from "../../Components/Headers/Header";
import BackButton from "../../Components/Navigation/BackButton";
import CoachingProgram from "../../Components/ViewCoachingProgram/CoachingProgram";
import Schedule from "../../Components/ViewCoachingProgram/Schedule";
import ViewContext from "../../Context/ViewContext";
import { colorsLight, maxContentWidth } from "../../Styles/Themes";

const { Content } = Layout;

const ViewCoachingProgram = () => {
	const { isMobile, isIpad } = useContext(ViewContext);
	const [data, setData] = useState({});
	const [coachData, setCoachData] = useState({});
	const { packageId } = useParams();
	const [blocks, setBlocks] = useState([]);
	const history = useHistory();
	const scrollRef = React.createRef();

	useEffect(() => {
		scrollRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		let listener1 = firestore()
			.collection("Programs")
			.doc(packageId)
			.onSnapshot((doc) => {
				if (doc.exists) {
					setData({ ...doc.data(), key: doc.id });
					return;
				} else {
					message.error("Can't find program record!");
				}
			});

		return () => {
			listener1();
		};
	}, [packageId]);

	useEffect(() => {
		if (data.coachId) {
			firestore()
				.collection("Coaches")
				.doc(data.coachId)
				.get()
				.then((doc) => {
					if (doc.exists) {
						setCoachData({ ...doc.data(), key: doc.id });
						return firestore()
							.collection("AnonAvailability")
							.where("owner", "==", doc.id)
							.get();
					} else {
						console.error("COULD NOT GET COACH RECORD");
					}
				})
				.then((docs) => {
					let list = [];
					docs.forEach((doc) => {
						list.push({ ...doc.data(), key: doc.id });
					});
					setBlocks(list);
				})
				.catch((err) =>
					message.error(
						"Something went wrong getting program record. Please refresh the page"
					)
				);
		}
	}, [data, packageId]);

	return (
		<Layout className={isMobile ? "layout" : "App"}>
			<HeaderMain />
			<Content
				style={{
					overflow: "scroll",
					padding: isMobile ? 12 : 24,
					paddingBottom: isMobile ? 42 : 24,
					paddingTop: isMobile ? 54 : 0,
				}}
				className="content"
			>
				<Row justify="center" ref={scrollRef}>
					<Col
						style={{
							backgroundColor: colorsLight("lightgrey"),
							height: "100%",
							maxWidth: maxContentWidth,
						}}
						span={24}
					>
						<Row style={{ padding: "12px 0px 12px 0px" }}>
							<BackButton history={history} />
						</Row>
						<Row style={{ padding: "0px 0px 12px 0px" }}>
							<Col span={!isMobile && !isIpad ? 16 : 24} data-testid="program">
								<CoachingProgram data={data} />
							</Col>
							{/* <Col
								style={{
									padding:
										!isMobile && !isIpad ? "0px 0px 0px 24px" : "24px 0px 0px",
								}}
								span={!isMobile && !isIpad ? 8 : 24}
								data-testid="program-schedule"
							>
								<Schedule data={data} coachData={coachData} blocks={blocks} />
							</Col> */}
						</Row>
					</Col>
				</Row>
			</Content>
		</Layout>
	);
};

export default ViewCoachingProgram;
