import { Layout, Row, Typography } from "antd";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import HeaderMain from "../../Components/Headers/Header";
import CoachCard from "../../Components/Store/Cards/CoachPackage";
import CourseProviderCard from "../../Components/Store/Cards/CourseProviderCard";
import DataContext from "../../Context/DataContext";
import ViewContext from "../../Context/ViewContext";
import { colorsLight } from "../../Styles/Themes";

const { Content } = Layout;

const FeaturedPage = () => {
	const { packages, courseProviders, serviceProviders } = useContext(DataContext);
	const { isMobile } = useContext(ViewContext);
	const loadingBoxes = Array(5).fill(0);

	const packagesToDisplay = packages.length === 0 ? loadingBoxes : packages;
	const courseProvidersToDisplay = courseProviders.length === 0 ? loadingBoxes : courseProviders;
	const serviceProvidersToDisplay =
		serviceProviders.length === 0 ? loadingBoxes : serviceProviders;

	return (
		<Layout className={isMobile ? "layout" : "App"}>
			<HeaderMain active="3" />
			<Content
				style={{ overflowY: "scroll", paddingBottom: 42, paddingTop: isMobile ? 54 : 0 }}
				className="content"
			>
				<Row style={{ padding: isMobile ? 12 : 24 }}>
					<Typography.Title>Featured</Typography.Title>
				</Row>
				<Row
					justify="space-between"
					align="middle"
					style={{ padding: isMobile ? "24px 12px" : "24px 24px" }}
				>
					<Typography.Title level={3}>Coaching Packages</Typography.Title>
					<Link to="/Browse-Coaching-Packages" style={{ marginBottom: 4 }}>
						See more
					</Link>
				</Row>
				<Row>
					<div style={styles.itemRow}>
						{packagesToDisplay.map((item, index) => (
							<>
								{index < 8 && (
									<div
										style={{ padding: isMobile ? "0px 12px 0px 12px" : 0 }}
										key={index}
									>
										<CoachCard data={item} loading={packages.length === 0} />
									</div>
								)}
							</>
						))}
					</div>
				</Row>
				<Row
					justify="space-between"
					align="middle"
					style={{ padding: isMobile ? "24px 12px" : "24px 24px" }}
				>
					<Typography.Title level={3}>Course Providers</Typography.Title>
					{/* <Link style={{ marginBottom: 4 }}>See more</Link> */}
				</Row>
				<Row>
					<div style={styles.itemRow}>
						{courseProvidersToDisplay.map((item, index) => (
							<div key={index}>
								<CourseProviderCard
									link={`/view-provider-courses/${item.key}`}
									data={item}
									loading={courseProviders.length === 0}
								/>
							</div>
						))}
					</div>
				</Row>

				<Row
					justify="space-between"
					align="middle"
					style={{ padding: isMobile ? "24px 12px" : "24px 24px" }}
				>
					<Typography.Title level={3}>Service Providers</Typography.Title>
					{/* <Link style={{ marginBottom: 4 }}>See more</Link> */}
				</Row>
				<Row>
					<div style={styles.itemRow}>
						{serviceProvidersToDisplay.map((item, index) => (
							<div key={index}>
								<CourseProviderCard
									link={`/view-service-provider/${item.key}`}
									data={item}
									loading={serviceProviders.length === 0}
								/>
							</div>
						))}
					</div>
				</Row>
			</Content>
		</Layout>
	);
};

const styles = {
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	itemRow: {
		flexDirection: "row",
		overflowY: "hidden",
		overflowX: "scroll",
		display: "flex",
		paddingLeft: 20,
		marginBottom: 24,
		width: "100%",
	},
};

export default FeaturedPage;
