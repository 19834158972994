import { Col, Layout, message, Row } from "antd";
import { firestore } from "firebase";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import HeaderMain from "../../Components/Headers/Header";
import BackButton from "../../Components/Navigation/BackButton";
import CourseProviderInfoCard from "../../Components/ViewCourse/CourseProviderInfoCard";
import ServicePurchasePanel from "../../Components/ViewService/ServicePurchasePanel";
import ViewServiceHeader from "../../Components/ViewService/ViewServiceHeader";
import ViewContext from "../../Context/ViewContext";
import { colorsLight, maxContentWidth } from "../../Styles/Themes";

const { Content } = Layout;

const ViewServicePage = () => {
	const { isMobile } = useContext(ViewContext);
	const [data, setData] = useState({});
	const [providerData, setProviderData] = useState({});
	const { providerIdANDserviceId } = useParams();
	const [providerId, serviceId] = providerIdANDserviceId.split("&");
	const history = useHistory();
	const scrollRef = React.createRef();

	useEffect(() => {
		scrollRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
		// eslint-disable-next-line
	}, [providerIdANDserviceId]);

	useEffect(() => {
		firestore()
			.collection("Services")
			.doc(serviceId)
			.get()
			.then((doc) => {
				if (doc.exists) {
					setData({ ...doc.data(), key: doc.id });
				}
				return;
			})
			.then(() => {
				return firestore().collection("ServiceProviders").doc(providerId).get();
			})
			.then((doc) => {
				if (doc.exists) {
					setProviderData({ ...doc.data(), key: doc.id });
				}
			})
			.catch((err) => message.error(err));
		// eslint-disable-next-line
	}, []);

	return (
		<Layout className={isMobile ? "layout" : "App"}>
			<HeaderMain />
			<Content
				style={{
					overflow: "scroll",
					padding: isMobile ? "0px 12px 120px 12px" : "12px 24px 24px 24px",
				}}
				className="content"
			>
				<Row ref={scrollRef} justify="center">
					<Col
						span={24}
						style={{
							backgroundColor: colorsLight("lightgrey"),
							height: "100%",
							maxWidth: isMobile ? null : maxContentWidth,
						}}
					>
						<Row style={{ height: isMobile ? 66 : 0, width: "100%" }}></Row>
						<Row style={{ paddingBottom: 12 }}>
							<BackButton history={history} />
						</Row>
						<Row gutter={24}>
							<Col span={isMobile ? 24 : 16}>
								<ViewServiceHeader data={data} />
							</Col>
							<Col span={isMobile ? 24 : 8}>
								<CourseProviderInfoCard
									data={providerData}
									link="/view-service-provider/"
								/>
								<ServicePurchasePanel data={data} providerData={providerData} />
							</Col>
						</Row>
					</Col>
				</Row>
			</Content>
		</Layout>
	);
};

export default ViewServicePage;
