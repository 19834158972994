import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Row, Spin, Typography, Avatar } from "antd";
import moment from "moment";
import React, { useContext } from "react";
import UserContext from "../../Context/UserContext";
import ViewContext from "../../Context/ViewContext";
import { getInitials } from "../../Functions/formatters";

const AccountDetails = ({ toggleEdit }) => {
	const { userInfo } = useContext(UserContext);
	const { isMobile } = useContext(ViewContext);

	const loadingIcon = <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />;

	return (
		<>
			<Row justify="space-between">
				<Row>
					<Avatar src={userInfo.displayPic} size={80} data-testid="avatar">
						{userInfo.displayName ? (
							getInitials(userInfo.displayName)
						) : (
							<Spin indicator={loadingIcon} />
						)}
					</Avatar>
					<Col style={{ marginLeft: 12, paddingTop: 6 }}>
						<Typography.Title style={{ margin: 0 }} level={2} data-testid="name">
							{userInfo.displayName}
						</Typography.Title>
					</Col>
				</Row>
				<Button
					style={{ marginTop: 10 }}
					onClick={toggleEdit}
					size="small"
					data-testid="edit"
				>
					Edit Info
				</Button>
			</Row>
			<Row style={{ paddingLeft: 6, paddingRight: 6 }}>
				<Divider style={{ marginTop: 12, marginBottom: 12 }} />
			</Row>
			<Row>
				<Typography.Title level={4}>Basic Information</Typography.Title>
			</Row>
			<Row>
				<Col style={{ paddingLeft: 12, paddingRight: 12 }} span={isMobile ? 24 : 12}>
					<Row justify="space-between">
						<p className="Key">First Name</p>
						<p className="Value" data-testid="fname">
							{userInfo.firstName || "Loading"}
						</p>
					</Row>
					<Row justify="space-between">
						<p className="Key">Last Name</p>
						<p className="Value" data-testid="lname">
							{userInfo.lastName || "Loading"}
						</p>
					</Row>
					<Row justify="space-between">
						<p className="Key">Email</p>
						<p className="Value" data-testid="email">
							{userInfo.email || "Loading"}
						</p>
					</Row>
				</Col>
				<Col style={{ paddingLeft: 12, paddingRight: 12 }} span={isMobile ? 24 : 12}>
					<Row justify="space-between">
						<p className="Key">Last Online</p>
						<p className="Value" data-testid="lastSignIn">
							{userInfo.lastSignIn !== "never"
								? moment(userInfo.lastSignIn).fromNow()
								: "Never"}
						</p>
					</Row>
				</Col>
			</Row>
		</>
	);
};

export default AccountDetails;
