import { Col, Divider, Row } from "antd";
import React from "react";
import { priceFormatter } from "../../../Functions/formatters";
import { colorsLight } from "../../../Styles/Themes";

const styles = {
	balanceWrapper: {
		border: "1px solid #828282",
		width: "fit-content",
		padding: "10px 30px",
		borderRadius: 9,
		textAlign: "center",
		margin: "0 auto",
	},
	title: {
		fontSize: 16,

		color: colorsLight("darkGrey"),
		marginBottom: 0,
	},
	balance: {
		fontWeight: "bold",
		fontSize: 36,
		color: colorsLight("lightBlue"),
		marginBottom: 0,
	},
	price: {
		fontWeight: "bold",
		fontSize: 24,
		color: colorsLight("lightBlue"),
		marginBottom: 0,
	},
	label: {
		fontSize: 14,
		color: colorsLight("grey"),
		marginBottom: 0,
	},
	desciption: {
		fontSize: 16,
		fontWeight: "bold",
		color: colorsLight("black"),
		marginBottom: 0,
		paddingBottom: 20,
	},
};

const BookingModal = ({ accountBalance, packData }) => (
	<div>
		<Col style={styles.balanceWrapper}>
			<p style={styles.title}>Your current balance:</p>
			<p style={styles.balance} data-testid="booking-balance">
				{priceFormatter(accountBalance, "points") + " pts"}
			</p>
		</Col>
		<Divider />
		<Row>
			<Col sm={8} xs={24}>
				<p style={styles.label}>Package Name:</p>
				<p style={styles.desciption} data-testid="booking-name">
					{packData.name}
				</p>
			</Col>
			<Col sm={8} xs={24}>
				<p style={styles.label}>Coach:</p>
				<p style={styles.desciption} data-testid="booking-coach">
					{packData.coachName}
				</p>
			</Col>
			<Col sm={8} xs={24}>
				<p style={styles.label}>Sessions:</p>
				<p style={styles.desciption} data-testid="booking-sessions">
					{packData.sessions}
				</p>
			</Col>
		</Row>
		<Row justify="end">
			<Col sm={8} xs={24}>
				<p style={styles.label}>Delivery:</p>
				<p style={styles.desciption} data-testid="booking-delivery">
					{packData.delivery}
				</p>
			</Col>
			<Col sm={8} xs={24}>
				<p style={styles.label}>Price:</p>
				<p style={styles.price} data-testid="booking-price">
					{priceFormatter(packData.price, "points") + " pts"}
				</p>
			</Col>
		</Row>
	</div>
);

export default BookingModal;
