import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Avatar, Col, Row, Typography, Skeleton } from "antd";
import { motion } from "framer-motion";
import React, { useContext, useState } from "react";
import ViewContext from "../../Context/ViewContext";
import { getTitleSize } from "../../Functions/formatters";
import { colorsLight } from "../../Styles/Themes";

const ProviderMainPanel = ({ data }) => {
	const { isMobile } = useContext(ViewContext);
	const [expDets, setExpDets] = useState(false);
	const styles = {
		imageHeader: {
			backgroundImage: `url(${data.coverPic})`,
			backgroundSize: "cover",
			width: "100%",
			height: isMobile ? 140 : 150,
			backgroundPosition: "center",
		},
		imageCover: {
			backgroundColor: colorsLight("cardCoverDark"),
			height: "100%",
			width: "100%",
			padding: isMobile ? 12 : "24px 24px 12px 24px",
		},
		title: {
			color: "white",
			margin: 0,
			marginLeft: isMobile ? 0 : 150,
		},
		row: {
			padding: isMobile ? "12px 12px" : "12px 24px",
		},
		icon: {
			fontSize: 18,
			marginTop: 6,
		},
	};

	const animVariants = {
		open: {
			height: null,
		},
		closed: {
			height: 0,
		},
	};

	const toggleExp = () => setExpDets(!expDets);

	return (
		<Col className="Card" style={{ padding: 0, overflow: "hidden" }}>
			<Row style={styles.imageHeader}>
				<Row
					justify="start"
					align={isMobile ? "middle" : "bottom"}
					style={styles.imageCover}
				>
					{!isMobile ? (
						<>
							<Row
								style={{
									padding: 4,
									boxShadow: "2px 2px 3px rgba(0,0,0,0.4)",
									borderRadius: 79,
									backgroundColor: "white",
									position: "absolute",
									top: 50,
									left: 16,
								}}
								justify="center"
								align="middle"
							>
								{data.displayPic ? (
									<Avatar size={130} src={data.displayPic} />
								) : (
									<Skeleton.Avatar active size={130} />
								)}
							</Row>
							{data.name ? (
								<Typography.Title
									style={styles.title}
									level={getTitleSize(data.name || 0)}
								>
									{data.name}
								</Typography.Title>
							) : (
								<Skeleton
									active
									paragraph={false}
									title={{ width: "50%", style: { margin: "0px 0px 0px 150px" } }}
								/>
							)}
						</>
					) : (
						<Row style={{ width: "100%", flexWrap: "nowrap" }}>
							<Col flex="auto">
								{data.name ? (
									<Typography.Title
										style={styles.title}
										level={getTitleSize(data.name || 0, isMobile)}
									>
										{data.name}
									</Typography.Title>
								) : (
									<Skeleton
										active
										paragraph={false}
										title={{
											width: "50%",
										}}
									/>
								)}
							</Col>
							<Col flex="100px">
								<Row
									style={{
										padding: 4,
										boxShadow: "2px 2px 3px rgba(0,0,0,0.4)",
										borderRadius: 49,
										backgroundColor: "white",
									}}
									justify="center"
									align="middle"
								>
									{data.displayPic ? (
										<Avatar size={90} src={data.displayPic} />
									) : (
										<Skeleton.Avatar active size={90} />
									)}
								</Row>
							</Col>
						</Row>
					)}
				</Row>
			</Row>

			<Row style={{ ...styles.row, marginTop: isMobile ? 0 : 50 }}>
				<Col span={24}>
					<Typography.Title level={3}>Summary</Typography.Title>
					{data.summary ? (
						<p>{data.summary}</p>
					) : (
						<Skeleton active paragraph={{ rows: 3 }} title={false} />
					)}
				</Col>
			</Row>
			{isMobile ? (
				<Row style={styles.row}>
					<Col span={24}>
						<Row justify="space-between" style={{ width: "100%" }}>
							<Col flex="auto">
								<Typography.Title level={3}>Details</Typography.Title>
							</Col>

							<Col flex="auto">
								<Row justify="end" align="middle" onClick={toggleExp}>
									{expDets ? (
										<UpOutlined style={styles.icon} />
									) : (
										<DownOutlined style={styles.icon} />
									)}
								</Row>
							</Col>
						</Row>

						<motion.div
							style={{ overflow: "hidden" }}
							variants={animVariants}
							animate={expDets ? "open" : "closed"}
						>
							<div dangerouslySetInnerHTML={{ __html: data.details }} />
						</motion.div>
					</Col>
				</Row>
			) : (
				<Row style={styles.row}>
					<Col span={24}>
						<Typography.Title level={3}>Details</Typography.Title>

						{data.details ? (
							<div dangerouslySetInnerHTML={{ __html: data.details }} />
						) : (
							<Skeleton active paragraph={{ rows: 3 }} title={false} />
						)}
					</Col>
				</Row>
			)}
		</Col>
	);
};

export default ProviderMainPanel;
