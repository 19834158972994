export const colorsLight = (selection) => {
	switch (selection) {
		case "primary":
			return "#1C3144";
		case "lightgrey":
			return "#F0F2F5";
		case "grey":
			return "#848587";
		case "darkGrey":
			return "#262626";
		case "lightBlue":
			return "#1890FF";
		case "brightGreen":
			return "#1cde18";
		case "green":
			return "#32b363";
		case "burntOrange":
			return "#f59802";
		case "navyBlue":
			return "#033360";
		case "cardCover":
			return "rgba(3, 51, 96, 0.5)";
		case "cardCoverDark":
			return "rgba(3, 51, 96, 0.7)";
		case "darkRed":
			return "#de3d21";
		case "black":
			return "#323232";
		default:
			return "white";
	}
};

export const maxContentWidth = 1300;
