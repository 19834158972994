import { Button, Col, Divider, Row, Skeleton, Typography } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React, { useContext } from "react";
import UserContext from "../../Context/UserContext";

const FacilitatorPanel = () => {
	const { facilitator } = useContext(UserContext);

	return (
		<Col className="Card" style={{ padding: 12 }}>
			<Typography.Title level={4}>Need some advice?</Typography.Title>
			<Divider style={{ margin: "12px 0px" }} />
			<Row>
				<p className="Key">Your Facilitator:</p>
			</Row>
			<Row style={{ paddingTop: 12 }}>
				<Row
					justify="center"
					align="middle"
					style={{
						padding: 4,
						backgroundColor: "white",
						boxShadow: "2px 2px 3px rgba(0,0,0,0.3)",
						borderRadius: 39,
					}}
				>
					{facilitator.displayPic ? (
						<Avatar src={facilitator.displayPic} size={70} />
					) : (
						<Skeleton.Avatar active size={70} />
					)}
				</Row>
				<div style={{ marginTop: 3 }} data-testid="facilitator-name">
					<p style={styles.name}>{facilitator.displayName}</p>
					<p style={styles.title}>{facilitator.title}</p>
				</div>
			</Row>
			{/* <Row style={{ marginTop: 12 }}>
				<p className="Key">How it works?</p>
			</Row>
			<Row>
				<p className="Value">
					As a Careerbase member you have direct access to your facilitator. They can help
					you get the most out of your investment into yourself.
				</p>
			</Row> */}
			<Row align="middle" type="flex" style={{ marginTop: 24 }}>
				<Col style={{ paddingRight: 12 }} span={12}>
					<Button
						disabled={true}
						style={{ width: "100%", whiteSpace: "normal", height: "fit-content" }}
						data-testid="facilitator-book"
					>
						Book Office Hours
					</Button>
				</Col>
				<Col style={{ paddingLeft: 12 }} span={12}>
					<Button
						block
						href={`mailto:${facilitator.email}`}
						target="_blank"
						style={{ width: "100%", whiteSpace: "normal", height: "fit-content" }}
						data-testid="facilitator-message"
						displayName={!facilitator.email}
					>
						Send Message
					</Button>
				</Col>
			</Row>
		</Col>
	);
};

const styles = {
	name: {
		marginLeft: 12,
		marginTop: 0,
		marginBottom: 0,
		fontSize: 22,
		fontWeight: 400,
	},
	title: {
		marginLeft: 12,
		marginTop: 0,
		marginBottom: 0,
		fontSize: 16,
		fontWeight: 300,
	},
};

export default FacilitatorPanel;
