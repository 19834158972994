import React, { useState, useEffect } from "react";
import { Card, Collapse, Steps, Row } from "antd";
import StepComponent from "../Common/StepComponent";
import BookingModal from "../../Components/Common/BookingModal";
import { colorsLight } from "../../Styles/Themes";
import { getNextSession, sortSessions } from "../../Functions/utilities";

const { Panel } = Collapse;
const { Step } = Steps;

const Schedule = ({ data, coachData, blocks }) => {
	const [isVisible, setIsVisible] = useState(false);
	const [modalTitle, setModalTitle] = useState("Book your next session");
	const [selectedSession, setSelectedSession] = useState({});
	const [activeKey, setActiveKey] = useState("1");
	const [sortedSessions, setSortedSessions] = useState({
		completed: [],
		booked: [],
		unbooked: [],
	});

	useEffect(() => {
		const sorted = sortSessions(data.timeline);
		setSortedSessions(sorted);
	}, [data]);

	useEffect(() => {
		if (sortedSessions.completed.length > 0) {
			setActiveKey("1");
		} else if (sortedSessions.booked.length > 0) {
			setActiveKey("2");
		} else {
			setActiveKey("3");
		}
	}, [sortedSessions]);

	const toggleModal = (title = "Book your next session", sessionData) => {
		setSelectedSession(sessionData);
		setModalTitle(title);
		setIsVisible(!isVisible);
	};

	const styles = {
		title: {
			fontWeight: "bold",
			fontSize: 14,
			color: colorsLight("black"),
			marginBottom: 0,
			paddingLeft: 20,
		},
		description: {
			fontSize: 20,
			color: colorsLight("black"),
			padding: "0px 10px 0px 20px",
		},
	};
	return (
		<>
			<Card style={{ borderRadius: 12 }} bodyStyle={{ padding: "10px 0px" }} title="Schedule">
				<div>
					<p style={styles.title}>Your next session:</p>
					<p style={styles.description} data-testid="schedule-nextSession">
						{getNextSession(sortedSessions.booked)}
					</p>
				</div>
				<Collapse
					onChange={(val) => setActiveKey(val)}
					activeKey={activeKey}
					ghost
					accordion
					data-testid="schedule-panel"
				>
					<Panel header={`Unbooked Sessions (${sortedSessions.unbooked.length})`} key="1">
						<Steps
							current={0}
							status="finish"
							direction="vertical"
							data-testid="schedule-unbookedSessions"
						>
							{sortedSessions.unbooked.map((session, index) => (
								<Step
									key={`unbooked-${index}`}
									description={
										<StepComponent
											data={session}
											handleOnClick={() => toggleModal("Book", session)}
											ind={index}
										/>
									}
								/>
							))}
						</Steps>
					</Panel>
					<Panel header={`Booked Sessions (${sortedSessions.booked.length})`} key="2">
						<Steps
							current={0}
							status="finish"
							direction="vertical"
							data-testid="schedule-bookedSessions"
						>
							{sortedSessions.booked.map((session, index) => (
								<Step
									key={`booked-${index}`}
									description={
										<StepComponent
											data={session}
											handleOnClick={() => toggleModal("Edit", session)}
											ind={index}
										/>
									}
								/>
							))}
						</Steps>
						{sortedSessions.booked.length < 1 && (
							<Row style={{ width: "100%" }} justify="center">
								<p className="Value">You have no bookings</p>
							</Row>
						)}
					</Panel>
					<Panel
						header={`Completed Sessions (${sortedSessions.completed.length})`}
						key="3"
					>
						<Steps
							current={sortedSessions.completed.length}
							direction="vertical"
							data-testid="schedule-completedSessions"
						>
							{sortedSessions.completed.map((session, index) => (
								<Step
									key={`completed-${index}`}
									description={<StepComponent data={session} />}
								/>
							))}
						</Steps>
						{sortedSessions.completed.length < 1 && (
							<Row style={{ width: "100%" }} justify="center">
								<p className="Value">You have no completed sessions</p>
							</Row>
						)}
					</Panel>
				</Collapse>
				<BookingModal
					isVisible={isVisible}
					toggleModal={toggleModal}
					packData={data}
					coachData={coachData}
					blocks={blocks}
					modalTitle={modalTitle}
					setActiveKey={setActiveKey}
					selectedSession={selectedSession}
				/>
			</Card>
		</>
	);
};

export default Schedule;
