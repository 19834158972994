import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, message } from "antd";
import moment from "moment";
import BookingAvailability from "./BookingAvailability";
import BookingPreview from "./BookingPreview";
import BookingConfirm from "./BookingConfirm";
import { colorsLight } from "../../../Styles/Themes";
import UserContext from "../../../Context/UserContext";
import { app, firestore } from "firebase";
import ProcessingPayment from "../../Common/ProcessingPayment";
import PurchaseOutcome from "./PurchaseOutcome";
import { Link } from "react-router-dom";

const BookingModal = ({ isVisible, setIsVisible, packData, type, coachData }) => {
	const [step, setStep] = useState(1);
	const [session, setSession] = useState({});
	const { accountBalance, userInfo } = useContext(UserContext);
	const [loading, setLoading] = useState(false);
	const [blocks, setBlocks] = useState([]);
	const [programId, setProgramId] = useState("");

	useEffect(() => {
		coachData.key &&
			firestore()
				.collection("AnonAvailability")
				.where("owner", "==", coachData.key)
				.get()
				.then((docs) => {
					let list = [];
					docs.forEach((doc) => {
						list.push({ ...doc.data(), key: doc.id });
					});
					setBlocks(list);
				})
				.catch((err) => {
					console.log(err);
				});
	}, [coachData]);

	const handleTransition = (type) => {
		if (type === "forward") {
			if (step < 2) {
				setStep(step + 1);
			} else {
				purchasePackage();
			}
		} else {
			setStep(step - 1);
		}
	};

	const handleCloseModal = () => {
		setSession({});
		setIsVisible(false);
	};

	const checkValidBooking = () => {
		if (session.startTime && session.endTime) {
			const start = moment(session.startTime);
			return (
				new Date(start) < new Date() || coachData.availability[start.day()].active === false
			);
		}
		return true;
	};

	const purchasePackage = () => {
		console.log("purchasing");
		setLoading(true);
		setStep(3);
		const payload = {
			env:
				process.env.REACT_APP_PROJECT_ID === "careerbase-development-2610e"
					? "development"
					: "production",
			purchaserId: userInfo.key,
			providerId: packData.owner,
			packageId: packData.key,
			initiatorData: userInfo,
			initialSession: {
				startTime: session.startTime ? moment(session.startTime).format() : "TBD",
				endTime: session.endTime ? moment(session.endTime).format() : "TBD",
			},
			type: "coach",
		};
		app()
			.functions("asia-northeast1")
			.httpsCallable("PurchaseHandler")(payload)
			.then((res) => {
				if (res.data.status === "success") {
					setStep(4);
					setProgramId(res.data.programId);
					setLoading(false);
					message.success("Coaching package added to program");
				} else {
					message.error(res.data.message);
					setStep(3);
					setIsVisible(false);
					setLoading(false);
				}
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	};

	const skipBooking = () => {};

	const buttonRenderer = () => {
		switch (step) {
			case 1:
				return (
					<>
						<Button
							key="cancel"
							onClick={handleCloseModal}
							data-testid="booking-cancel"
						>
							Cancel
						</Button>

						<Button
							loading={loading}
							key="submit"
							type="primary"
							disabled={step === 2 ? checkValidBooking() : false}
							onClick={() => handleTransition("forward")}
							data-testid="booking-next"
						>
							{step === 3 ? "Confirm" : "Next"}
						</Button>
					</>
				);
			// case 2:
			// 	return (
			// 		<>
			// 			<Button
			// 				key="skip"
			// 				type="text"
			// 				onClick={() => handleTransition("forward")}
			// 				style={{ padding: "4px 0px", color: colorsLight("grey") }}
			// 				data-testid="booking-skip"
			// 			>
			// 				Book Session Later
			// 			</Button>

			// 			<Button
			// 				loading={loading}
			// 				key="submit"
			// 				type="primary"
			// 				disabled={step === 2 ? checkValidBooking() : false}
			// 				onClick={() => handleTransition("forward")}
			// 				data-testid="booking-next"
			// 			>
			// 				{step === 3 ? "Confirm" : "Next"}
			// 			</Button>
			// 		</>
			// 	);
			case 2:
				return (
					<>
						<Button
							key="back"
							onClick={() => handleTransition("backward")}
							data-testid="booking-back"
						>
							Back
						</Button>

						<Button
							loading={loading}
							key="submit"
							type="primary"
							disabled={step === 3 ? checkValidBooking() : false}
							onClick={() => handleTransition("forward")}
							data-testid="booking-next"
						>
							{step === 2 ? "Confirm" : "Next"}
						</Button>
					</>
				);
			case 3:
				return null;
			case 4:
				return (
					<>
						<Link to={`/View-Coaching-Program/${programId}`}>
							<Button
								loading={loading}
								key="submit"
								type="primary"
								disabled={!programId}
							>
								View your program
							</Button>
						</Link>
					</>
				);
		}
	};

	const title =
		step === 1
			? "Let's book in your package"
			: step === 2
			? "Book in your first session"
			: step === 3
			? "Please confirm order details"
			: step === 4
			? "Processing your order"
			: "You're all ready to go!";

	return (
		<Modal
			title={title}
			visible={isVisible}
			onCancel={handleCloseModal}
			width={850}
			zIndex={9999999999}
			footer={buttonRenderer()}
		>
			{step === 1 && <BookingPreview packData={packData} accountBalance={accountBalance} />}
			{/* 
			{step === 2 && (
				<BookingAvailability
					pickSession={setSession}
					coachData={coachData}
					packageData={packData}
					blocks={blocks}
				/>
			)} */}

			{step === 2 && (
				<BookingConfirm
					packData={packData}
					accountBalance={accountBalance}
					session={session}
				/>
			)}

			{step === 3 && <ProcessingPayment />}

			{step === 4 && <PurchaseOutcome programId={programId} type="Coaching" />}
		</Modal>
	);
};

export default BookingModal;
