//import { CrownOutlined, PieChartOutlined, RocketOutlined, SolutionOutlined, ThunderboltOutlined } from "@ant-design/icons";
import React, { useContext } from "react";
import ViewContext from "../../Context/ViewContext";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";

const HeaderMain = ({ active }) => {
	const { isMobile } = useContext(ViewContext);

	// const styles = {
	// 	open: {
	// 		width: "calc(100% - 150px)",
	// 		padding: 0,
	// 		zIndex: 1005,
	// 		right: 0,
	// 		transition: "width 0.3s",
	// 		position: "fixed",
	// 		height: 69,
	// 	},
	// 	closed: {
	// 		position: "fixed",
	// 		display: "contents",
	// 		width: "100%",
	// 		height: 69,
	// 	},
	// };

	// const menus = [
	// 	{
	// 		name: "Home",
	// 		icon: <PieChartOutlined />,
	// 		link: "/Home",
	// 		testId: "menu-home",
	// 	},
	// 	{
	// 		name: "My Program",
	// 		icon: <PieChartOutlined />,
	// 		link: "/My-Program",
	// 		testId: "menu-program",
	// 	},
	// 	{
	// 		name: "Featured",
	// 		icon: <CrownOutlined />,
	// 		link: "/Featured",
	// 		testId: "menu-featured",
	// 	},
	// 	{
	// 		name: "Coaches",
	// 		icon: <RocketOutlined />,
	// 		link: "/Browse-Coaches",
	// 		testId: "menu-coaches",
	// 	},
	// 	{
	// 		name: "Courses",
	// 		icon: <SolutionOutlined />,
	// 		link: "/Browse-Courses",
	// 		testId: "menu-courses",
	// 	},
	// 	{
	// 		name: "Services",
	// 		icon: <ThunderboltOutlined />,
	// 		link: "/Browse-Services",
	// 		testId: "menu-services",
	// 	},
	// ];

	return (
		<>
			{isMobile ? (
				<div>
					<MobileMenu active={active} />
				</div>
			) : (
				<DesktopMenu active={active} />
			)}
		</>
	);
};

export default HeaderMain;
