import { Col, Layout, message, Row } from "antd";
import { firestore } from "firebase";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import HeaderMain from "../../Components/Headers/Header";
import FacilitatorPanel from "../../Components/Home/FacilitatorPanel";
import BackButton from "../../Components/Navigation/BackButton";
import ProcessFlow from "../../Components/ViewCourseProgram/ProcessFlow";
import ServiceProgram from "../../Components/ViewServiceProgram/ServiceProgram";
import ViewContext from "../../Context/ViewContext";
import { colorsLight, maxContentWidth } from "../../Styles/Themes";

const { Content } = Layout;

const ViewServiceProgram = () => {
	const { isMobile, isIpad, isLaptop, isDesktop } = useContext(ViewContext);
	const [data, setData] = useState({});
	const { programId } = useParams();
	const history = useHistory();

	useEffect(() => {
		let listener = firestore()
			.collection("Programs")
			.doc(programId)
			.onSnapshot((doc) => {
				if (doc.exists) {
					setData({ ...doc.data(), key: doc.id });
				} else {
					message.error("No such program can be found :(");
				}
			});

		return () => {
			listener();
		};
	}, [programId]);

	return (
		<Layout className={isMobile ? "layout" : "App"}>
			<HeaderMain />
			<Content
				style={{
					overflow: "scroll",
					padding: isMobile ? 12 : 24,
					paddingBottom: isMobile ? 42 : 24,
					paddingTop: isMobile ? 54 : 0,
					justifyContent: "center",
					display: "flex",
					flexDirection: "row",
				}}
				className="content"
			>
				<Col
					style={{
						backgroundColor: colorsLight("lightgrey"),
						height: "100%",
						maxWidth: maxContentWidth,
					}}
					span={24}
				>
					<Row style={{ padding: "12px 0px 12px 0px" }}>
						<BackButton history={history} />
					</Row>

					{(isLaptop || isDesktop) && (
						<div data-testid="service-laptopView">
							<Row style={{ padding: "0px 0px 12px" }}>
								<Col span={16}>
									<ServiceProgram data={data} />
								</Col>
								<Col
									style={{
										padding: "0px 0px 0px 24px",
									}}
									span={8}
								>
									<Row justify="space-between">
										<Col span={24}>
											<ProcessFlow />
										</Col>
										<Col span={24} style={{ paddingTop: 24 }}>
											<FacilitatorPanel />
										</Col>
									</Row>
								</Col>
							</Row>
						</div>
					)}
					{isIpad && (
						<div data-testid="service-ipadView">
							<Row style={{ padding: "0px 0px 12px" }}>
								<Col span={24}>
									<ServiceProgram data={data} />
								</Col>
								<Col
									style={{
										padding: "24px 0px 0px",
									}}
									span={24}
								>
									<Row justify="space-between">
										<Col span={12}>
											<ProcessFlow />
										</Col>
										<Col span={11}>
											<FacilitatorPanel />
										</Col>
									</Row>
								</Col>
							</Row>
						</div>
					)}
					{isMobile && (
						<div data-testid="service-mobileView">
							<Row style={{ padding: "0px 0px 12px" }}>
								<Col span={24}>
									<ServiceProgram data={data} />
								</Col>
								<Col
									style={{
										padding: "24px 0px 0px",
									}}
									span={24}
								>
									<Row justify="space-between">
										<Col span={24}>
											<ProcessFlow />
										</Col>
										<Col span={24} style={{ paddingTop: 24 }}>
											<FacilitatorPanel />
										</Col>
									</Row>
								</Col>
							</Row>
						</div>
					)}
				</Col>
			</Content>
		</Layout>
	);
};

export default ViewServiceProgram;
