import { Button, Col, Row, Skeleton } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ViewContext from "../../Context/ViewContext";
import { colorsLight } from "../../Styles/Themes";

const CoachDetailsPanel = ({ data }) => {
	const { isIpad } = useContext(ViewContext);

	const styles = {
		name: {
			// marginLeft: 12,
			marginTop: 12,
			marginBottom: 0,
			fontSize: 24,
			fontWeight: 400,
			lineHeight: 1,
		},
		title: {
			// marginLeft: 12,
			marginTop: 0,
			marginBottom: 0,
			fontSize: 18,
			fontWeight: 300,
			color: colorsLight("grey"),
		},
		label: {
			fontSize: 14,
			fontWeight: 700,
		},
		about: {
			fontSize: 14,
		},
		avatar: {
			padding: 5,
			backgroundColor: "white",
			boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
			borderRadius: "50%",
			margin: "0px 0px 10px 0px",
			height: 80,
		},
	};

	return (
		<Col className="Card" style={{ borderRadius: 12, marginBottom: isIpad ? 0 : 20 }}>
			<Row>
				<Col style={styles.avatar}>
					{data.displayPic ? (
						<Avatar src={data.displayPic} size={70} data-testid="coach-avatar" />
					) : (
						<Skeleton.Avatar active size={70} />
					)}
				</Col>
				<Col flex="auto" push={1}>
					{data.displayName && data.title ? (
						<>
							<p style={styles.name} data-testid="coach-name">
								{data.displayName}
							</p>
							<p style={styles.title} data-testid="coach-type">
								{data.title}
							</p>
						</>
					) : (
						<Skeleton active paragraph={{ rows: 1, width: "80%" }} />
					)}
				</Col>
			</Row>
			<Row style={{ marginTop: 24 }}>
				<p style={styles.label}>About</p>
			</Row>
			<Row>
				{data.summary ? (
					<p data-testid="coach-about">{data.summary}</p>
				) : (
					<Skeleton active paragraph={{ rows: 3 }} title={false} />
				)}
			</Row>
			<Row style={{ marginTop: 3 }}>
				<Col style={{ paddingRight: 12 }} span={12}>
					<Button
						href={`mailto:${data.email}`}
						target="_blank"
						style={{ width: "100%" }}
						data-testid="coach-contact"
						disabled={!data.email}
					>
						Contact
					</Button>
				</Col>
				<Col style={{ paddingLeft: 12 }} span={12}>
					<Link to={data.key ? `/View-Coach/${data.key}` : "#"}>
						<Button
							style={{ width: "100%", padding: "4px 10px" }}
							data-testid="coach-profile"
						>
							View Profile
						</Button>
					</Link>
				</Col>
			</Row>
		</Col>
	);
};

export default CoachDetailsPanel;
