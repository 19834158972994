import { Avatar, Button, Col, Row, Skeleton, Statistic, Typography } from "antd";
import moment from "moment";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ViewContext from "../../Context/ViewContext";
import { priceFormatter } from "../../Functions/formatters";
import { colorsLight } from "../../Styles/Themes";

const ProgramCard = ({ data }) => {
	const { windowWidth } = useContext(ViewContext);
	const styles = {
		backgroundImage: {
			backgroundImage: `url(${data.coverPic || ""})`,
			height: "100%",
			backgroundSize: "cover",
		},
		avatar: {
			borderStyle: "solid",
			borderColor: "white",
			borderWidth: 4,
			boxShadow: "2px 2px 4px rgba(0,0,0,0.3)",
			position: "absolute",
			top: 24,
		},
		bgOverlay: {
			backgroundColor: colorsLight("cardCover"),
			height: "100%",
			width: "100%",
		},
		button: {
			width: "100%",
		},
	};

	return (
		<Row
			className="Card"
			style={{ padding: 0, width: "100%", flexWrap: "nowrap", overflow: "hidden" }}
		>
			<Col flex="170px">
				<Col span={24} style={styles.backgroundImage}>
					<Row justify="center" align="middle" style={styles.bgOverlay}>
						{data.displayPic ? (
							<Avatar
								size={90}
								src={data.displayPic}
								style={{
									borderWidth: 4,
									borderColor: "white",
									borderStyle: "solid",
									boxShadow: "2px 2px 3px rgba(0,0,0,0.5)",
								}}
							/>
						) : (
							<Skeleton.Avatar
								active
								size={90}
								style={{
									borderWidth: 4,
									borderColor: "white",
									borderStyle: "solid",
									boxShadow: "2px 2px 3px rgba(0,0,0,0.5)",
								}}
							/>
						)}
					</Row>
				</Col>
			</Col>
			<Col style={{ padding: "12px 0px 12px 16px" }} flex="auto">
				<Row>
					<p style={{ margin: 0 }}>
						{data.type ? (
							data.type === "Packages" ? (
								data.coachName
							) : (
								data.providerName
							)
						) : (
							<Skeleton active paragraph={false} title={{ width: 150 }} />
						)}
					</p>
				</Row>
				<Row>
					{data.name ? (
						<Typography.Title level={4}>{data.name}</Typography.Title>
					) : (
						<Skeleton active paragraph={false} title={{ width: 150 }} />
					)}
				</Row>
				<Row
					style={{
						padding: 0,
						width: "100%",
						flexWrap: windowWidth < 450 ? "wrap" : "nowrap",
						overflow: "hidden",
					}}
				>
					<Col flex="auto">
						<Row gutter={32}>
							<Col>
								{data.type ? (
									<Statistic
										title={
											data.type === "Packages" ? "Next Session" : "Start Date"
										}
										value={
											data.type === "Packages"
												? "Tuesday 24th March 2021 @ 4:45pm"
												: moment(data.startTime).format(
														"dddd Do MMMM YYYY @ h:mma"
												  )
										}
										valueStyle={{ fontSize: 16 }}
									/>
								) : (
									<Skeleton active paragraph={false} title={{ width: 100 }} />
								)}
							</Col>

							<Col>
								{data.price ? (
									<Statistic
										title="Price"
										value={priceFormatter(data.price, "Points") + " pts"}
										valueStyle={{
											fontWeight: "bold",
											color: colorsLight("lightBlue"),
											fontSize: 18,
										}}
									/>
								) : (
									<Skeleton active paragraph={false} title={{ width: 100 }} />
								)}
							</Col>
						</Row>
					</Col>
					<Col flex="170px">
						<Row
							justify={windowWidth < 450 ? "flex-start" : "end"}
							align="bottom"
							style={{ height: "100%", padding: "0px 12px 0px 0px" }}
						>
							<Link
								to={
									data.type === "Packages"
										? `/View-Coaching-Program/${data.key}`
										: data.type === "Courses"
										? `/View-Course-Program/${data.key}`
										: `/View-Service-Program/${data.key}`
								}
							>
								<Button style={styles.button} disabled={!data.key}>
									View Program
								</Button>
							</Link>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default ProgramCard;
