import { Col, Divider, Row, Typography, Avatar, Button, Skeleton } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const { Title, Paragraph } = Typography;

const CourseProviderInfoCard = ({ data = {}, courseId, link = "/View-Course-Provider/" }) => {
	return (
		<Col className="Card">
			<Row style={{ flexWrap: "nowrap" }}>
				<Col flex="80px">
					{data.displayPic ? (
						<Avatar
							src={data.displayPic}
							size={84}
							style={{
								boxShadow: "1px 1px 4px rgba(0,0,0,0.5)",
								borderStyle: "solid",
								borderColor: "white",
								borderWidth: 3,
								backgroundColor: "white",
							}}
							data-testid="course-avatar"
						/>
					) : (
						<Skeleton.Avatar active size={84} />
					)}
				</Col>
				<Col flex="auto" push={1}>
					{data.name && data.websiteUrl ? (
						<>
							<Title
								style={{
									maxWidth: 250,
									marginTop: 0,
									fontWeight: 300,
									marginBottom: 0,
								}}
								level={3}
								data-testid="course-name"
							>
								{data.name}
							</Title>
							<a
								style={{ margin: 0, wordBreak: "break-word" }}
								href={data.websiteUrl}
								data-testid="course-website"
							>
								{data.websiteUrl}
							</a>
						</>
					) : (
						<Skeleton active paragraph={{ rows: 1 }} />
					)}
				</Col>
			</Row>
			<Divider style={{ marginTop: 12, marginBottom: 6 }} />
			<Row>
				<p className="Key">About</p>
			</Row>
			<Row>
				{data.summary ? (
					<Paragraph
						ellipsis={{
							rows: 3,
							expandable: false,
							symbol: "...",
							suffix: <a href={`${link}${data.key}`}>Read on</a>,
						}}
						data-testid="course-summary"
					>
						{data.summary}
					</Paragraph>
				) : (
					<Skeleton active title={false} paragraph={{ rows: 3 }} />
				)}
			</Row>
			<Row justify="end" align="bottom" style={{ width: "100%" }}>
				<Link
					style={{ marginLeft: 12 }}
					to={`${link}${data.key}`}
					data-testid="course-view"
				>
					<Button>View Profile</Button>
				</Link>
			</Row>
		</Col>
	);
};

export default CourseProviderInfoCard;
