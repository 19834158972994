import React from "react";
import "./Styles/main.scss";
import { UserProvider } from "./Context/UserContext";
import { DataProvider } from "./Context/DataContext";
import { ViewProvider } from "./Context/ViewContext";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Login from "./Pages/Authentication/login";
import PrivateRoute from "./Components/Navigation/PrivateRoute";
import { IntercomProvider } from "react-use-intercom";
import MyProgram from "./Pages/MyProgram/MyProgram";
import FeaturedPage from "./Pages/Featured/Featured";
import CoachesBrowse from "./Pages/Coaches/CoachesBrowse";
import CoursesBrowse from "./Pages/Courses/CoursesBrowse";
import ServicesBrowse from "./Pages/Services/ServicesPage";
import ViewPackagePage from "./Pages/ViewPackage/ViewPackage";
import ViewCoachPage from "./Pages/ViewCoach/ViewCoach";
import ViewCoachingProgram from "./Pages/ViewCoachingProgram/ViewCoachingProgram";
import ViewCourseProgram from "./Pages/ViewCourseProgram/ViewCourseProgram";
import ViewServiceProgram from "./Pages/ViewServicesProgram/ViewServiceProgram";
import ViewProviderCourses from "./Pages/ViewCourses/ViewProviderCourses";
import ViewCoursePage from "./Pages/ViewCourse/ViewCourse";
import ViewCourseProviderPage from "./Pages/ViewCourseProvider/ViewCourseProvider";
import ViewServiceProviderPage from "./Pages/ViewServiceProvider/ViewServiceProviderPage";
import SearchCoachingPackagesPage from "./Pages/SearchCoachPackages/SearchCoachPackages";
import MyAccountPage from "./Pages/MyAccount/MyAccountPage";
import MyTransactionsPage from "./Pages/MyTransactions/MyTransactionsPage";
import MySchedulePage from "./Pages/MySchedule/MySchedulePage";
import Activate from "./Pages/Authentication/activate";
import ViewServicePage from "./Pages/ViewServicePage/ViewServicePage";
import EnvChecker from "./Components/EnvChecker";

const App = () => {
	return (
		<IntercomProvider appId="is1ygk79">
			<ViewProvider>
				<UserProvider>
					<DataProvider>
						<EnvChecker />
						<Router>
							<Switch>
								<Route path="/activate/:params" component={Activate} />
								<Route path="/Login" component={Login} />

								<PrivateRoute path="/Home">
									<Home />
								</PrivateRoute>
								<PrivateRoute path="/My-Program">
									<MyProgram />
								</PrivateRoute>
								<PrivateRoute path="/Featured">
									<FeaturedPage />
								</PrivateRoute>
								<PrivateRoute path="/Browse-Coaches">
									<CoachesBrowse />
								</PrivateRoute>
								<PrivateRoute path="/Browse-Coaching-Packages">
									<SearchCoachingPackagesPage />
								</PrivateRoute>
								<PrivateRoute path="/View-Package/:coachIdANDpackageId">
									<ViewPackagePage />
								</PrivateRoute>
								<PrivateRoute path="/View-Coach/:coachId">
									<ViewCoachPage />
								</PrivateRoute>
								<PrivateRoute path="/Browse-Courses">
									<CoursesBrowse />
								</PrivateRoute>
								<PrivateRoute path="/View-Course/:providerIdANDcourseId">
									<ViewCoursePage />
								</PrivateRoute>
								<PrivateRoute path="/View-Service/:providerIdANDserviceId">
									<ViewServicePage />
								</PrivateRoute>
								<PrivateRoute path="/View-Course-Provider/:providerId">
									<ViewCourseProviderPage />
								</PrivateRoute>
								<PrivateRoute path="/View-Service-Provider/:providerId">
									<ViewServiceProviderPage />
								</PrivateRoute>
								<PrivateRoute path="/Browse-Services">
									<ServicesBrowse />
								</PrivateRoute>
								<PrivateRoute path="/View-Coaching-Program/:packageId">
									<ViewCoachingProgram />
								</PrivateRoute>
								<PrivateRoute path="/View-Course-Program/:programId">
									<ViewCourseProgram />
								</PrivateRoute>
								<PrivateRoute path="/View-Service-Program/:programId">
									<ViewServiceProgram />
								</PrivateRoute>
								<PrivateRoute path="/view-provider-courses/:providerId">
									<ViewProviderCourses />
								</PrivateRoute>
								<PrivateRoute path="/My-Account">
									<MyAccountPage />
								</PrivateRoute>
								<PrivateRoute path="/My-Transactions">
									<MyTransactionsPage />
								</PrivateRoute>
								<PrivateRoute path="/My-Schedule">
									<MySchedulePage />
								</PrivateRoute>
								<PrivateRoute path="/">
									<Home />
								</PrivateRoute>
							</Switch>
						</Router>
					</DataProvider>
				</UserProvider>
			</ViewProvider>
		</IntercomProvider>
	);
};

export default App;
