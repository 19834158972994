import { Row, Typography } from "antd";
import React from "react";
import CoachCard from "../Store/Cards/CoachPackage";
const { Title } = Typography;

const AllPackages = ({ data = {}, loading = false }) => {
	const styles = {
		title: {
			marginBottom: 32,
			// marginLeft: 12,
		},
		list: {
			overflowX: "scroll",
			display: "flex",
			flexWrap: "nowrap",
			width: "100%",
		},
		itemRow: {
			flexDirection: "row",
			overflowY: "hidden",
			overflowX: "scroll",
			display: "flex",
			marginBottom: 24,
			width: "100%",
		},
	};

	return (
		<>
			<Title level={3} style={styles.title}>
				Packages
			</Title>

			<Row style={{ width: "100%", padding: "0px 12px 0px 0px" }} data-testid="packages-list">
				{data.length > 0 && (
					<div style={styles.itemRow}>
						{data.map((item, index) => (
							<div key={index} style={{ marginRight: 12 }}>
								<CoachCard data={item} />
							</div>
						))}
					</div>
				)}
			</Row>
		</>
	);
};

export default AllPackages;
