import { Row } from "antd";
import React, { useContext } from "react";
import ViewContext from "../Context/ViewContext";

const EnvChecker = () => {
	const { isMobile } = useContext(ViewContext);
	return (
		<>
			{process.env.REACT_APP_PROJECT_ID === "careerbase-development-2610e" && !isMobile && (
				<Row justify="center" align="middle" className="WarningBanner">
					<p style={{ margin: "0px 24px" }}>🏖</p>
					<p style={{ margin: 0, color: "white" }}>SANDBOX ENVIRONMENT</p>
					<p style={{ margin: "0px 24px" }}>🏝</p>
				</Row>
			)}
		</>
	);
};

export default EnvChecker;
