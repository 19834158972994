import { Button, Col, Form, Input, notification, Row, Typography } from "antd";
import { auth, firestore } from "firebase";
import React, { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import logo from "../../Assets/logo-small-dark.png";
import UserContext from "../../Context/UserContext";
import ViewContext from "../../Context/ViewContext";
import { colorsLight } from "../../Styles/Themes";

const Login = () => {
	const [loading, setloading] = useState(false);
	const { setUid, setAuthd, authd } = useContext(UserContext);
	const { isMobile } = useContext(ViewContext);
	const [reset, setReset] = useState(false);

	const login = (val) => {
		setloading(true);
		auth()
			.signInWithEmailAndPassword(val.email, val.password)
			.then(async (user) => {
				const uid = user.user.uid;
				const check = await firestore()
					.collection("Members")
					.doc(uid)
					.get()
					.then((doc) => (doc.exists ? "exists" : "wrong"));
				if (check === "exists") {
					return uid;
				} else {
					auth().signOut();
					throw new SyntaxError("Wrong user type");
				}
			})
			.then((uid) => {
				setUid(uid);
				setAuthd(true);
				setloading(false);
			})
			.catch((error) => {
				console.log(error);
				openNotificationWithIcon("error", error.message);
				setloading(false);
			});
	};

	const resetPassword = (email) => {
		setloading(true);
		auth()
			.sendPasswordResetEmail(email.email)
			.then((res) => {
				openNotificationWithIcon(
					"success",
					"Password reset",
					"Check your inbox for the reset email."
				);
				setloading(false);
				setReset(false);
			})
			.catch((err) => {
				openNotificationWithIcon("error", "Could not verify your email, please try again.");
				setloading(false);
			});
	};

	const openNotificationWithIcon = (type, title, message) => {
		notification[type]({
			message: title,
			description: message,
		});
	};

	return !authd ? (
		<Col style={styles.main}>
			<Row justify="center" align="middle" style={{ height: "80%" }}>
				{!isMobile ? (
					<Row style={styles.card}>
						<Col style={styles.padding} span={24}>
							<Row justify="center" align="middle">
								<img src={logo} style={styles.logo} alt="login" />
							</Row>
							{!reset ? (
								<Form
									name="myForm"
									onFinish={login}
									required={true}
									data-testid="login"
								>
									<Form.Item
										data-testid="login-emailItem"
										name="email"
										rules={[{ required: true, message: "Please enter email" }]}
									>
										<Input
											placeholder="Email"
											type="email"
											data-testid="login-email"
										/>
									</Form.Item>
									<Form.Item
										name="password"
										rules={[
											{ required: true, message: "Please enter password" },
										]}
									>
										<Input.Password
											placeholder="Password"
											data-testid="login-password"
										/>
									</Form.Item>
									<Form.Item>
										<Row justify="end">
											<Button
												loading={loading}
												type="primary"
												htmlType="submit"
												data-testid="login-button"
											>
												Sign-in
											</Button>
										</Row>
									</Form.Item>
								</Form>
							) : (
								<Form
									name="reset"
									onFinish={resetPassword}
									required={true}
									data-testid="login"
								>
									<Form.Item
										data-testid="login-emailItem"
										name="email"
										rules={[{ required: true, message: "Please enter email" }]}
									>
										<Input
											placeholder="Email"
											type="email"
											data-testid="login-email"
										/>
									</Form.Item>
									<Form.Item>
										<Row justify="end">
											<Button
												loading={loading}
												type="primary"
												htmlType="submit"
												data-testid="login-button"
											>
												Reset Password
											</Button>
										</Row>
									</Form.Item>
								</Form>
							)}
							<Row justify="center">
								<Typography.Link onClick={() => setReset(!reset)}>
									{!reset ? "Forgot your password?" : "Login to your account"}
								</Typography.Link>
							</Row>
						</Col>
					</Row>
				) : (
					<Row style={{ ...styles.card, margin: 24 }}>
						<Col style={styles.padding} span={24}>
							<Row justify="center" align="middle">
								<img src={logo} style={styles.logo} alt="login" />
							</Row>
							{!reset ? (
								<Form
									name="myForm"
									onFinish={login}
									required={true}
									data-testid="login"
								>
									<Form.Item
										data-testid="login-emailItem"
										name="email"
										rules={[{ required: true, message: "Please enter email" }]}
									>
										<Input
											size="large"
											placeholder="Email"
											type="email"
											data-testid="login-email"
										/>
									</Form.Item>
									<Form.Item
										name="password"
										rules={[
											{ required: true, message: "Please enter password" },
										]}
									>
										<Input.Password
											size="large"
											placeholder="Password"
											data-testid="login-password"
										/>
									</Form.Item>
									<Form.Item>
										<Row justify="end">
											<Button
												size="large"
												loading={loading}
												type="primary"
												htmlType="submit"
												data-testid="login-button"
											>
												Sign-in
											</Button>
										</Row>
									</Form.Item>
								</Form>
							) : (
								<Form
									name="reset"
									onFinish={resetPassword}
									required={true}
									data-testid="login"
								>
									<Form.Item
										data-testid="login-emailItem"
										name="email"
										rules={[{ required: true, message: "Please enter email" }]}
									>
										<Input
											size="large"
											placeholder="Email"
											type="email"
											data-testid="login-email"
										/>
									</Form.Item>
									<Form.Item>
										<Row justify="end">
											<Button
												size="large"
												loading={loading}
												type="primary"
												htmlType="submit"
												data-testid="login-button"
											>
												Reset Password
											</Button>
										</Row>
									</Form.Item>
								</Form>
							)}
							<Row justify="center">
								<Typography.Link onClick={() => setReset(!reset)}>
									{!reset ? "Forgot your password?" : "Login to your account"}
								</Typography.Link>
							</Row>
						</Col>
					</Row>
				)}
			</Row>
		</Col>
	) : (
		<Redirect to="/Home" />
	);
};

const styles = {
	main: {
		height: "100vh",
		width: "100%",
		backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/careerbase-development-2610e.appspot.com/o/websiteAssets%2Floginbg.jpg?alt=media&token=59ae6afe-5cbc-476a-9912-6164ae5c1387)`,
		backgroundSize: "cover",
	},
	padding: {
		padding: 24,
	},
	logo: {
		width: 220,
		marginBottom: 56,
	},
	card: {
		width: 350,
		backgroundColor: "white",
		borderRadius: 3,
	},
	image: {
		height: "100%",
		width: "100%",
		backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/careerbase-development-2610e.appspot.com/o/websiteAssets%2FloginImage.jpg?alt=media&token=11d7c851-1738-4f2d-97e2-e5a71269eb0b)`,
		backgroundSize: "cover",
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
};

export default Login;
