import React, { useEffect, useState } from "react";
import { Modal, Button, message } from "antd";
import moment from "moment";
import BookingAvailability from "../ViewPackage/BookingModal/BookingAvailability";
import { firestore } from "firebase";

const BookingModal = ({
	isVisible,
	toggleModal,
	packData,
	coachData,
	blocks,
	modalTitle,
	selectedSession,
	setActiveKey,
}) => {
	const [session, setSession] = useState({});
	const [loading, setLoading] = useState(false);

	const handleBookSession = () => {
		setLoading(true);
		let timeline = packData.timeline;
		const startTime = moment(session.startTime).format();
		const endTime = moment(session.endTime).format();
		const ind = selectedSession.index;
		timeline[ind].startTime = startTime;
		timeline[ind].endTime = endTime;

		if (selectedSession.eventId) {
			firestore()
				.collection("Events")
				.doc(selectedSession.eventId)
				.update({
					startTime: startTime,
					endTime: endTime,
				})
				.then(() => {
					return firestore().collection("Programs").doc(packData.key).update({
						timeline: timeline,
					});
				})
				.then(() => {
					setLoading(false);
					message.success("Session booked!");
					toggleModal("", {});
					setActiveKey("2");
				})
				.catch((err) => {
					message.error("Something went wrong, check log for more info");
					console.log(err);
					setLoading(false);
				});
		} else {
			firestore()
				.collection("Events")
				.add({
					startTime: startTime,
					endTime: endTime,
					attendees: [packData.member, coachData.key],
					eventId: "TBD",
					eventSync: false,
					organiser: coachData.key,
					organiserType: "Coaches",
					programId: packData.key,
					sessionNum: ind + 1,
					title: selectedSession.name || "Coaching session",
					noSync:
						process.env.REACT_APP_PROJECT_ID === "careerbase-development-2610e"
							? true
							: false,
				})
				.then(() => {
					return firestore().collection("Programs").doc(packData.key).update({
						timeline: timeline,
					});
				})
				.then(() => {
					setLoading(false);
					message.success("Session booked!");
					toggleModal("", {});
					setActiveKey("2");
				})
				.catch((err) => {
					message.error("Something went wrong, check log for more info");
					console.log(err);
					setLoading(false);
				});
		}
	};

	useEffect(() => {
		console.log(selectedSession);
	}, [selectedSession]);

	const handleCloseModal = () => {
		setSession({});
		toggleModal();
	};

	const checkValidBooking = () => {
		if (session.startTime && session.endTime) {
			const start = moment(session.startTime);
			return (
				new Date(start) < new Date() || coachData.availability[start.day()].active === false
			);
		}
		return true;
	};

	return (
		<Modal
			title={
				modalTitle === "Edit" ? "Edit your upcoming session" : "Book your upcoming session"
			}
			visible={isVisible}
			onCancel={handleCloseModal}
			width={850}
			zIndex={9999999999}
			footer={[
				<Button key="cancel" onClick={handleCloseModal} data-testid="booking-cancel">
					Cancel
				</Button>,
				<Button
					key="submit"
					type="primary"
					disabled={checkValidBooking()}
					loading={loading}
					onClick={handleBookSession}
					data-testid="booking-next"
				>
					{modalTitle === "Edit" ? "Update Session" : "Book Session"}
				</Button>,
			]}
		>
			<BookingAvailability
				pickSession={setSession}
				coachData={coachData}
				packageData={packData}
				blocks={blocks}
			/>
		</Modal>
	);
};

export default BookingModal;
