/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Row, Col, Calendar } from "antd";
import moment from "moment";
import { colorsLight } from "../../../Styles/Themes";
import { getAvailableSlots } from "../../../Functions/CalBookingFunctions";

const BookingModal = ({ pickSession, coachData, blocks = [], packageData }) => {
	const [activeDate, setActiveDate] = useState(moment().add(1, "day"));
	const [availableSessions, setAvailableSessions] = useState([]);
	const [picked, setPicked] = useState();

	useEffect(() => {
		if ((coachData.key, packageData.duration)) {
			setAvailableSessions(getAvailableSlots(activeDate, blocks, coachData, packageData));
		}
		setPicked();
	}, [activeDate, coachData, packageData]);

	useEffect(() => {
		coachData.key && getActiveDate();
	}, []);

	const getActiveDate = () => {
		let date = moment().add(1, "day");
		let day = moment(date).day();
		let active;

		while (!active) {
			if (coachData.availability[day].active === true) {
				setActiveDate(date);
				active = date;
			} else {
				date = moment(date).add(1, "day");
				day = moment(date).day();
			}
		}
	};

	const toggleSelection = (index) => {
		if (picked !== index) {
			pickSession(availableSessions[index]);
			setPicked(index);
		} else {
			pickSession({});
			setPicked();
		}
	};

	const isBlocked = (date, duration) => {
		//GENERATES LIST OF OVERLAPPING EVENTS THIS DAY
		const day = moment(date).day();
		const ref = Number(moment(date).format("YYYYMMDD"));
		const hoursStart = moment(`${ref}${coachData.availability[day].start}`, "YYYYMMDDHHmm");
		const hoursEnd = moment(`${ref}${coachData.availability[day].finish}`, "YYYYMMDDHHmm");
		let overlaps = [];
		blocks.forEach((block) => {
			let start = moment(block.startTime);
			let end = moment(block.endTime);
			//ARE THERE OVERLAPS AT THE DATE LEVEL?
			if (
				moment(start).isBefore(hoursStart) &&
				moment(end).isAfter(moment(hoursEnd).subtract(duration - 1, "minutes"))
			) {
				overlaps.push(block);
			} else {
				return;
			}
		});
		if (overlaps.length > 0) {
			return true;
		} else {
			return false;
		}
	};

	const disabledCheck = (date) => {
		if (coachData.key) {
			let day = moment(date).day();
			let ref = Number(moment(date).format("YYYYMMDD"));
			let now = Number(moment().format("YYYYMMDD"));
			const duration = packageData.duration;
			if (coachData.availability[day].active === false || ref <= now) {
				return true;
			} else {
				let check1 = isBlocked(date, duration);
				if (check1) {
					return true;
				} else {
					return false;
				}
			}
		}
		return false;
	};

	return (
		<div>
			<>
				<Row justify="space-between">
					<Col
						sm={12}
						xs={24}
						style={{
							paddingTop: 12,
						}}
					>
						<div className="site-calendar-demo-card">
							<Calendar
								defaultValue={activeDate}
								fullscreen={false}
								disabledDate={disabledCheck}
								onChange={setActiveDate}
							/>
						</div>
					</Col>
					<Col sm={11} xs={24}>
						<p style={styles.dateLabel}>Showing available sessions on:</p>
						<p style={styles.dateText}>
							{moment(activeDate).format("dddd, Do MMMM YYYY")}
						</p>
						<Row
							style={{
								overflowY: "scroll",
								maxHeight: 250,
								paddingLeft: 6,
								paddingRight: 6,
							}}
						>
							{availableSessions.map((session, index) => {
								return (
									<div
										data-testid="booking-session"
										key={index}
										onClick={() => toggleSelection(index)}
										style={
											index === picked
												? styles.timeWrapActive
												: styles.timeWrapInactive
										}
									>
										<Row
											style={{ width: "100%", height: "100%" }}
											justify="center"
											align="middle"
										>
											<p style={{ margin: 0 }}>
												{moment(session.startTime).format("h:mm a")} -{" "}
												{moment(session.endTime).format("h:mm a")}{" "}
											</p>
										</Row>
									</div>
								);
							})}
						</Row>
					</Col>
				</Row>
			</>
		</div>
	);
};

const styles = {
	timeWrapActive: {
		height: 50,
		width: "100%",
		borderRadius: 12,
		borderWidth: 1,
		borderStyle: "solid",
		marginBottom: 12,
		borderColor: "grey",
		cursor: "pointer",
		color: "white",
		backgroundColor: "#1A90FF",
	},
	timeWrapInactive: {
		height: 50,
		width: "100%",
		borderRadius: 12,
		borderWidth: 1,
		borderStyle: "solid",
		marginBottom: 12,
		borderColor: "grey",
		cursor: "pointer",
		backgroundColor: "white",
	},
	timeFont: {
		margin: 0,
	},
	dateText: {
		fontSize: 22,
		color: colorsLight("grey"),

		fontWeight: "bold",
	},
	dateLabel: {
		fontSize: 14,
		color: colorsLight("grey"),

		margin: 0,
	},
};

export default BookingModal;
