import { Row, Col, Card, Typography } from "antd";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import UserContext from "../../Context/UserContext";
import { amountFormatter, priceFormatter } from "../../Functions/formatters";
import { colorsLight } from "../../Styles/Themes";

const MyAccountBalancePanel = () => {
	const { transactions, accountBalance } = useContext(UserContext);

	return (
		<Card style={{ borderRadius: 6, paddingBottom: 0 }} title="Account Balance">
			<Col>
				<Row>
					<p style={{ margin: 0, fontSize: 13 }}>Current Balance:</p>
				</Row>
				<Row>
					<p style={styles.balance} data-testid="account-balance">
						{priceFormatter(accountBalance) + " pts"}
					</p>
				</Row>
				<Row style={{ marginTop: 24 }}>
					<p style={{ margin: 0, fontSize: 13 }}>Recent Activity:</p>
				</Row>
				{transactions.length > 0 && (
					<>
						{transactions.slice(0, 2).map((item, index) => {
							const style = item.type === "credit" ? styles.credit : styles.debit;
							return (
								<Row
									key={index}
									justify="space-between"
									align="middle"
									style={styles.transactionWrapper}
									data-testid="account-activity"
								>
									<Col xs={16} sm={20} md={18} xl={16}>
										<Typography.Paragraph
											style={{ margin: 0 }}
											ellipsis={{
												rows: 1,
												symbol: "...",
											}}
										>
											{item.description}
										</Typography.Paragraph>
									</Col>
									<Col xs={8} sm={4} md={6} xl={8}>
										<Row justify="end">
											<p style={style}>
												{amountFormatter(item.amount, item.type)}
											</p>
										</Row>
									</Col>
								</Row>
							);
						})}
					</>
				)}
				<Row style={{ width: "100%", paddingTop: 12 }} justify="center" align="middle">
					<Link style={{ margin: 0 }} to="/My-Transactions" data-testid="account-button">
						View More
					</Link>
				</Row>
			</Col>
		</Card>
	);
};

const styles = {
	balance: {
		fontSize: 32,
		fontWeight: "bold",
		color: colorsLight("lightBlue"),
		margin: 0,
	},
	transactionWrapper: {
		backgroundColor: colorsLight("lightgrey"),
		paddingLeft: 6,
		paddingRight: 6,
		marginTop: 9,
		height: 30,
	},
	description: {
		fontSize: 14,
		fontWeight: 600,
		margin: 0,
	},
	credit: {
		fontSize: 14,
		fontWeight: 600,
		margin: 0,
		color: colorsLight("green"),
	},
	debit: {
		fontSize: 14,
		fontWeight: 600,
		margin: 0,
		color: colorsLight("darkRed"),
	},
};

export default MyAccountBalancePanel;
