import { Col, Row, Typography, Skeleton } from "antd";
import React, { useContext, useState } from "react";
import { colorsLight } from "../../Styles/Themes";
import moment from "moment";
import ViewContext from "../../Context/ViewContext";
import { motion } from "framer-motion";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const ViewServiceHeader = ({ data }) => {
	const { isMobile } = useContext(ViewContext);
	const [expDets, setExpDets] = useState(false);
	const styles = {
		imageHeader: {
			backgroundImage: `url(${data.coverPic})`,
			backgroundSize: "cover",
			width: "100%",
			minHeight: 150,
			backgroundPosition: "center",
		},
		imageCover: {
			backgroundColor: colorsLight("cardCover"),
			// height: "100%",
			width: "100%",
			padding: 12,
		},
		title: {
			color: "white",
			margin: 0,
		},
		row: {
			padding: isMobile ? "12px 12px" : "12px 24px",
		},
		icon: {
			fontSize: 18,
			marginTop: 6,
		},
	};

	const animVariants = {
		open: {
			height: null,
		},
		closed: {
			height: 0,
		},
	};

	const toggleExp = () => setExpDets(!expDets);

	return (
		<Col className="Card" style={{ padding: 0, overflow: "hidden" }}>
			<Row style={styles.imageHeader}>
				<Row justify="start" align="bottom" style={styles.imageCover}>
					{data.name ? (
						<Typography.Title style={styles.title} level={2}>
							{data.name}
						</Typography.Title>
					) : (
						<Skeleton active paragraph={false} title={{ width: "50%" }} />
					)}
				</Row>
			</Row>
			{data.availability === "dates" && (
				<>
					{data.dates.map((item, index) => (
						<Row
							key={index}
							gutter={16}
							style={{
								padding: isMobile ? "12px 12px 0px 12px" : "12px 24px 0px 24px",
							}}
						>
							<Col span={12}>
								<Typography.Title level={3}>When</Typography.Title>
								<p style={{ fontSize: 18, fontWeight: 300 }}>{`${moment(
									item.startTime
								).format("Do MMMM YYYY")} - ${moment(item.endTime).format(
									"Do MMMM YYYY"
								)}`}</p>
							</Col>
							<Col span={12}>
								<Typography.Title level={3}>Where</Typography.Title>
								<p style={{ fontSize: 18, fontWeight: 300 }}>{item.details}</p>
							</Col>
						</Row>
					))}
				</>
			)}
			<Row style={styles.row}>
				<Col span={24}>
					<Typography.Title level={4}>Summary</Typography.Title>
					{data.summary ? (
						<p>{data.summary}</p>
					) : (
						<Skeleton active paragraph={{ rows: 3 }} title={false} />
					)}
				</Col>
			</Row>
			{isMobile ? (
				<Row style={styles.row}>
					<Col span={24}>
						<Row justify="space-between" style={{ width: "100%" }}>
							<Typography.Title level={4}>Details</Typography.Title>
							<Row onClick={toggleExp}>
								{expDets ? (
									<UpOutlined style={styles.icon} />
								) : (
									<DownOutlined style={styles.icon} />
								)}
							</Row>
						</Row>

						<motion.div
							style={{ overflow: "hidden" }}
							variants={animVariants}
							animate={expDets ? "open" : "closed"}
						>
							<div dangerouslySetInnerHTML={{ __html: data.details }} />
						</motion.div>
					</Col>
				</Row>
			) : (
				<Row style={styles.row}>
					<Col span={24}>
						<Typography.Title level={4}>Details</Typography.Title>
						{data.details ? (
							<div dangerouslySetInnerHTML={{ __html: data.details }} />
						) : (
							<Skeleton active paragraph={{ rows: 3 }} title={false} />
						)}
					</Col>
				</Row>
			)}
		</Col>
	);
};

export default ViewServiceHeader;
