import React from "react";
import { Row, Col, Divider } from "antd";
import moment from "moment";
import { colorsLight } from "../../../Styles/Themes";
import { priceFormatter } from "../../../Functions/formatters";

const styles = {
	price: {
		fontWeight: "bold",
		fontSize: 36,
		color: colorsLight("lightBlue"),
		marginBottom: 0,
	},
	label: {
		fontSize: 14,

		color: colorsLight("grey"),
		marginBottom: 0,
	},
	desciption: {
		fontSize: 16,

		fontWeight: "bold",
		color: colorsLight("black"),
		marginBottom: 0,
		paddingBottom: 20,
	},
	divider: {
		marginTop: 5,
	},
	row: {
		textAlign: "right",
	},
};

const BookingConifrm = ({ session = {}, packData, coachData }) => (
	<div>
		<Row>
			<Col sm={8} xs={24}>
				<p style={styles.label}>Package Name:</p>
				<p style={styles.desciption} data-testid="booking-name">
					{packData.name}
				</p>
			</Col>
			<Col sm={8} xs={24}>
				<p style={styles.label}>Coach:</p>
				<p style={styles.desciption} data-testid="booking-coach">
					{packData.coachName}
				</p>
			</Col>
		</Row>
		<Row>
			<Col sm={8} xs={24}>
				<p style={styles.label}>Initial Session:</p>
				<p style={styles.desciption} data-testid="booking-sessionDate">
					{session.startTime
						? moment(session.startTime).format("dddd, Do MMMM @ h:mm a")
						: "TBD"}
				</p>
			</Col>
			<Col sm={8} xs={24}>
				<p style={styles.label}>Sessions:</p>
				<p style={styles.desciption} data-testid="booking-sessions">
					{packData.sessions}
				</p>
			</Col>
			<Col sm={8} xs={24}>
				<p style={styles.label}>Delivery:</p>
				<p style={styles.desciption} data-testid="booking-delivery">
					{packData.delivery}
				</p>
			</Col>
		</Row>
		<Divider stye={styles.divider} />
		<Row justify="end" style={styles.row}>
			<Col>
				<p style={styles.label}>You are about to spend:</p>
				<p style={styles.price} data-testid="booking-price">
					{priceFormatter(packData.price, "points") + " pts"}
				</p>
			</Col>
		</Row>
	</div>
);

export default BookingConifrm;
