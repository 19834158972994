import { Row, Col, Typography } from "antd";
import React from "react";

const NorthWestPanel = () => {
	return (
		<>
			<Row justify="space-between" style={{ paddingLeft: 12 }}>
				<Typography.Title
					style={{ fontWeight: 400, width: "66%" }}
					level={3}
					data-testid="action-title"
				>
					Your professional development sponsored by:
				</Typography.Title>
				<Row style={styles.logo}>
					<img
						style={{ width: "100%", height: "100%" }}
						src="https://firebasestorage.googleapis.com/v0/b/careerbase-development-2610e.appspot.com/o/websiteAssets%2Flogo.png?alt=media&token=fa26bc16-d0d2-4aee-a026-678000128e3f"
					/>
				</Row>
			</Row>

			<Row style={{ minHeight: 244, paddingTop: 64 }} data-testid="action-content">
				<Col span={8}>
					<Row style={{ overflowWrap: "revert" }}>
						<Col flex="20px">
							<Typography.Title>1.</Typography.Title>
						</Col>
						<Col flex="200px">
							<p style={styles.text}>
								Book time with your head coach for an overview and consultation
							</p>
						</Col>
					</Row>
				</Col>
				<Col span={8}>
					<Row style={{ overflowWrap: "revert" }}>
						<Col flex="20px">
							<Typography.Title>2.</Typography.Title>
						</Col>
						<Col flex="200px">
							<p style={styles.text}>
								Browse coaches, courses and services to choose a path that works for
								you
							</p>
						</Col>
					</Row>
				</Col>
				<Col span={8}>
					<Row style={{ overflowWrap: "revert" }}>
						<Col flex="20px">
							<Typography.Title>3.</Typography.Title>
						</Col>
						<Col flex="200px">
							<p style={styles.text}>Use your points and start your program</p>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};

const styles = {
	text: {
		marginTop: 3,
		marginLeft: 6,
	},
	logo: {
		width: 250,
		paddingTop: 12,
	},
};

export default NorthWestPanel;
