import { Button, Col, Modal, Row, Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";
import BookingModal from "../../Components/Common/BookingModal";
import { colorsLight } from "../../Styles/Themes";

const styles = {
	title: {
		textAlign: "center",
		paddingBottom: 20,
	},
	label: {
		fontSize: 14,
		color: colorsLight("grey"),
		marginBottom: 0,
	},
	desciption: {
		fontSize: 16,
		fontWeight: "bold",
		color: colorsLight("black"),
		marginBottom: 0,
	},
};

const ViewSessionModal = ({ isVisible, setIsVisible, session }) => {
	const [modalType, setModalType] = useState();
	const [showBookingModal, setShowBookingModal] = useState(false);
	const now = moment().format("YYYYMMDDHHmm");
	const eventTime = moment(session.endTime).format("YYYYMMDDHHmm");

	const openBookingModal = (type) => {
		setShowBookingModal(true);
		setModalType(type);
	};

	return (
		<>
			<Modal
				title="View Session"
				visible={isVisible}
				onCancel={() => setIsVisible(false)}
				width={850}
				zIndex={9999999999}
				footer={
					now > eventTime && session.startTime !== "TBA"
						? null
						: [
								<Button
									key="submit"
									type="primary"
									data-testid="session-edit"
									onClick={() =>
										openBookingModal(
											session.startTime === "TBA" ? "book" : "edit"
										)
									}
								>
									{session.startTime === "TBA" ? "Book Session" : "Edit"}
								</Button>,
						  ]
				}
			>
				<Typography.Title level={2} style={styles.title} data-testid="view-title">
					{session.title}
				</Typography.Title>
				<Row>
					<Col sm={8} xs={24}>
						<p style={styles.label}>Attendees:</p>
						<p style={styles.desciption} data-testid="view-attendees">
							John Doe, Jane Doe
						</p>
					</Col>
					<Col sm={8} xs={24}>
						<p style={styles.label}>Delivery:</p>
						<p style={styles.desciption} data-testid="view-delivery">
							Zoom or In-Person
						</p>
					</Col>
					<Col sm={8} xs={24}>
						<p style={styles.label}>Session Date:</p>
						<p style={styles.desciption} data-testid="view-date">
							{session.startTime !== "TBA" ? (
								<>
									{moment(session.startTime).format("dddd, D MMMM YYYY")} @
									{moment(session.startTime).format("HH:mm")} -
									{moment(session.endTime).format("HH:mm")}
								</>
							) : (
								"TBA"
							)}
						</p>
					</Col>
				</Row>
			</Modal>
			<BookingModal
				isVisible={showBookingModal}
				setIsVisible={setShowBookingModal}
				setModalType={setModalType}
				modalType={modalType}
			/>
		</>
	);
};

export default ViewSessionModal;
