import { Avatar, Col, Divider, Row, Skeleton, Steps, Typography } from "antd";
import moment from "moment";
import React, { useContext } from "react";
import ViewContext from "../../Context/ViewContext";
import { getInitials, priceFormatter } from "../../Functions/formatters";
import { getImage } from "../../Functions/utilities";
import { colorsLight } from "../../Styles/Themes";

const { Title } = Typography;
const { Step } = Steps;

const CoachDetails = ({ data = {}, loading = false }) => {
	const { isMobile } = useContext(ViewContext);
	const styles = {
		wrapper: {
			borderRadius: 9,
			backgroundColor: colorsLight("white"),
		},
		header: {
			width: "100%",
		},
		coverPhoto: {
			backgroundImage: data ? `url(${data.coverPic ? data.coverPic : getImage()})` : null,
			backgroundSize: "cover",
			minHeight: 97,
			width: "100%",
			borderRadius: "9px 9px 0px 0px",
		},
		name: {
			fontWeight: "bold",
			fontSize: 26,
			color: colorsLight("black"),
			marginBottom: 0,
			lineHeight: 1.3,
		},
		provider: {
			fontSize: 14,
			color: "#5c5c5c",
			marginBottom: 10,
		},
		avatar: {
			padding: 4,
			backgroundColor: "white",
			boxShadow: "1px 1px 6px rgba(0,0,0,0.2)",
			borderRadius: "50%",
			margin: "0px 0px 10px 0px",
			maxWidth: isMobile ? 103 : 145,
			height: isMobile ? 103 : 145,
			position: "absolute",
			top: 40,
		},
		providerHeader: {
			width: "100%",
			borderRadius: "9px 9px 0px 0px",
			margin: 0,
			padding: "0px 20px",
		},
		title: {
			fontWeight: "bold",
			fontSize: 22,
			color: colorsLight("black"),
			width: "100%",
		},
		description: {
			fontSize: 14,
			margin: "0px 0px 0px 10px",
			color: colorsLight("black"),
		},
		row: {
			padding: "20px 30px",
			width: "100%",
		},
		infoRow: {
			width: "100%",
		},
		info: {
			display: "flex",
			justifyContent: "space-between",
			padding: "5px 10px",
		},
		label: {
			fontWeight: "bold",
			fontSize: 14,
		},
		text: {
			fontSize: 14,
		},
	};

	const getStatus = () => {
		if (data.status) {
			switch (data.status) {
				case "Processing":
					return 2;
				case "Confirming":
					return 3;
				case "Completed":
					return 4;
				default:
					return 1;
			}
		} else {
			return 1;
		}
	};

	return (
		<Row style={styles.wrapper}>
			<Row style={styles.header}>
				<Row style={styles.coverPhoto} data-testid="program-coverPhoto"></Row>
				<Row justify="space-between" align="bottom" style={styles.providerHeader}>
					<Col style={styles.avatar}>
						{data.displayPic ? (
							<Avatar
								style={{ margin: 2 }}
								src={data.displayPic}
								size={isMobile ? 90 : 132}
								data-testid="program-avatar"
							>
								{getInitials(data.name || "")}
							</Avatar>
						) : (
							<Skeleton.Avatar
								active
								size={isMobile ? 90 : 132}
								style={{ margin: 2 }}
							/>
						)}
					</Col>
					<Col
						style={{ margin: isMobile ? "6px 0px 0px 115px" : "6px 0px 0px 160px" }}
						flex="auto"
					>
						{data.name && data.providerName ? (
							<>
								<p style={styles.name} data-testid="program-name">
									{data.name}
								</p>
								<p style={styles.provider} data-testid="program-provider">
									{data.providerName}
								</p>
							</>
						) : (
							<Skeleton
								active
								paragraph={{ rows: 1, width: "50%" }}
								title={{ width: "30%" }}
							/>
						)}
					</Col>
				</Row>
			</Row>
			<Divider style={{ margin: "10px 0px 0px" }} />
			<Row style={styles.row}>
				<Title style={styles.title}>Status</Title>
				<Steps current={getStatus()} responsive data-testid="program-status">
					<Step title="Ordered" />
					<Step title="Processing" />
					<Step title="Confirmed" />
					<Step title="Complete" />
				</Steps>
			</Row>
			<Divider style={{ margin: "10px 0px 0px" }} />
			<Row style={styles.row}>
				<Title style={styles.title}>Key Info</Title>
				<Row style={styles.infoRow}>
					<Col sm={12} xs={24} style={styles.info}>
						<div style={styles.label}>Sessions:</div>
						<div style={styles.text} data-testid="program-sessions">
							12
						</div>
					</Col>
					<Col sm={12} xs={24} style={styles.info}>
						<div style={styles.label}>Booking Date:</div>
						<div style={styles.text} data-testid="program-bookingDate">
							{data.created ? (
								moment(data.created).format("D/M/YYYY")
							) : (
								<Skeleton
									active
									paragraph={false}
									title={{ width: 100, style: { margin: 0 } }}
								/>
							)}
						</div>
					</Col>
					<Col sm={12} xs={24} style={styles.info}>
						<div style={styles.label}>Delivery:</div>
						<div style={styles.text} data-testid="program-delivery">
							In-person
						</div>
					</Col>
					<Col sm={12} xs={24} style={styles.info}>
						<div style={styles.label}>Booked By:</div>
						<div style={styles.text} data-testid="program-bookedBy">
							{data.initiatorName ? (
								data.initiatorName
							) : (
								<Skeleton
									active
									paragraph={false}
									title={{ width: 100, style: { margin: 0 } }}
								/>
							)}
						</div>
					</Col>
					<Col sm={12} xs={24} style={styles.info}>
						<div style={styles.label}>Price:</div>
						<div style={styles.text} data-testid="program-price">
							{data.price ? (
								priceFormatter(data.price, "points") + " pts"
							) : (
								<Skeleton
									active
									paragraph={false}
									title={{ width: 100, style: { margin: 0 } }}
								/>
							)}
						</div>
					</Col>
				</Row>
			</Row>
			<Divider style={{ margin: "10px 0px 0px" }} />
			<Row style={styles.row}>
				<Title style={styles.title}>Service Description</Title>
				{data.details ? (
					<div
						data-testid="coach-biography"
						dangerouslySetInnerHTML={{ __html: data.details }}
					/>
				) : (
					<Skeleton active paragraph={{ rows: 3 }} title={false} />
				)}
			</Row>
		</Row>
	);
};

export default CoachDetails;
