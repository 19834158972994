import { Col, Divider, Row, Typography } from "antd";
import React from "react";

const { Title } = Typography;

const CourseOverviewCard = ({ data, providerData }) => {
	return (
		<Col className="Card">
			<Row>
				<Title level={5}>Course Overview</Title>
			</Row>
			<Divider style={{ marginTop: 0, marginBottom: 6 }} />
			{providerData.courseAttributes && data && (
				<>
					{providerData.courseAttributes.map((item, index) => (
						<Row key={index} justify="space-between">
							<Col span={12}>
								<p className="Key" data-testid="course-label">
									{item.label}:
								</p>
							</Col>
							<Col span={12}>
								<p data-testid="course-ref">{data[item.ref] || "-"}</p>
							</Col>
						</Row>
					))}
				</>
			)}
		</Col>
	);
};

export default CourseOverviewCard;
