import { Breadcrumb, Col, Layout, message, Row, Typography } from "antd";
import { firestore } from "firebase";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import HeaderMain from "../../Components/Headers/Header";
import BackButton from "../../Components/Navigation/BackButton";
import CourseCardLite from "../../Components/Store/Cards/CourseCardLite";
import CourseOverviewCard from "../../Components/ViewCourse/CourseOverviewCard";
import CourseProviderInfoCard from "../../Components/ViewCourse/CourseProviderInfoCard";
import CoursePurchasePanel from "../../Components/ViewCourse/CoursePurchasePanel";
import ViewCourseHeader from "../../Components/ViewCourse/ViewCourseHeader";
import ViewContext from "../../Context/ViewContext";
import { colorsLight, maxContentWidth } from "../../Styles/Themes";

const { Content } = Layout;

const ViewCoursePage = () => {
	const { isMobile } = useContext(ViewContext);
	const { providerIdANDcourseId } = useParams();
	const history = useHistory();
	const [coursesData, setCoursesData] = useState({});
	const [providerData, setProviderData] = useState({});
	const [packages, setPackages] = useState([]);
	const providerId = providerIdANDcourseId.split("&")[0];
	const courseId = providerIdANDcourseId.split("&")[1];
	const scrollRef = React.createRef();

	useEffect(() => {
		if (coursesData.name) {
			setCoursesData({});
			setProviderData({});
			scrollRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
		} else {
			scrollRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
		}
		// eslint-disable-next-line
	}, [providerIdANDcourseId]);

	useEffect(() => {
		firestore()
			.collection("Courses")
			.doc(courseId)
			.get()
			.then((doc) => doc.exists && setCoursesData({ ...doc.data(), key: doc.id }))
			.catch((err) => message.error(err));

		firestore()
			.collection("CourseProviders")
			.doc(providerId)
			.get()
			.then((doc) => doc.exists && setProviderData({ ...doc.data(), key: doc.id }))
			.catch((err) => message.error(err));

		firestore()
			.collection("Courses")
			.where("owner", "==", providerId)
			.get()
			.then((snap) => {
				let list = [];
				snap.forEach((doc) => {
					if (doc.id !== courseId) {
						list.push({ ...doc.data(), key: doc.id });
					}
				});
				setPackages(list);
			})
			.catch((err) => message.error(err));
	}, [courseId, providerId]);

	const styles = {
		itemRow: {
			flexDirection: "row",
			overflowY: "hidden",
			overflowX: "scroll",
			display: "flex",
			marginBottom: 24,
			width: "100%",
		},
	};

	return (
		<Layout className={isMobile ? "layout" : "App"}>
			<HeaderMain />
			<Content
				style={{
					overflow: "scroll",
					padding: isMobile ? "0px 12px 120px 12px" : "12px 24px 24px 24px",
				}}
				className="content"
			>
				<Row justify="center">
					<Col
						style={{
							backgroundColor: colorsLight("lightgrey"),
							height: "100%",
							maxWidth: isMobile ? null : maxContentWidth,
						}}
						span={24}
					>
						<Row
							ref={scrollRef}
							style={{ height: isMobile ? 66 : 0, width: "100%" }}
						></Row>
						<Row
							justify="start"
							align="bottom"
							style={{ width: "100%", marginBottom: 12 }}
						>
							<BackButton marginLeft={0.1} history={history} />
						</Row>
						<Row style={{ width: "100%", marginBottom: 6 }}>
							<Breadcrumb>
								<Breadcrumb.Item>
									<Link to={`/Featured`}>Featured</Link>
								</Breadcrumb.Item>
								<Breadcrumb.Item>
									<Link to={`/view-provider-courses/${providerData.key}`}>
										{providerData.name}
									</Link>
								</Breadcrumb.Item>
							</Breadcrumb>
						</Row>

						<Row gutter={24}>
							<Col span={isMobile ? 24 : 16}>
								<ViewCourseHeader data={coursesData} />
							</Col>
							<Col span={isMobile ? 24 : 8}>
								{isMobile ? (
									<>
										<CourseOverviewCard
											data={coursesData}
											providerData={providerData}
										/>
										<CourseProviderInfoCard
											data={providerData}
											courseId={courseId}
										/>{" "}
									</>
								) : (
									<>
										<CourseProviderInfoCard
											data={providerData}
											courseId={courseId}
										/>
										<CourseOverviewCard
											data={coursesData}
											providerData={providerData}
										/>
										<CoursePurchasePanel
											data={coursesData}
											providerData={providerData}
										/>
									</>
								)}
							</Col>
						</Row>
						<Row style={{ marginTop: 12 }}>
							{packages.length > 0 && (
								<>
									<Row style={{ width: "100%" }}>
										<Typography.Title level={3}>
											You might also like
										</Typography.Title>
										<Link
											style={{ marginTop: 8, marginLeft: 12 }}
											to={`/view-provider-courses/${providerId}`}
										>
											See more
										</Link>
									</Row>

									{!isMobile ? (
										<Row
											style={{ width: "100%", overflow: "scroll" }}
											gutter={24}
										>
											{packages.map((item, index) => (
												<Col key={index} span={8}>
													<CourseCardLite data={item} />
												</Col>
											))}
										</Row>
									) : (
										<Row gutter={24} style={{ width: "100%" }}>
											<div style={styles.itemRow}>
												{packages.map((item, index) => (
													<Col key={index} span={24}>
														<CourseCardLite data={item} />
													</Col>
												))}
											</div>
										</Row>
									)}
								</>
							)}
						</Row>
					</Col>
				</Row>
			</Content>
		</Layout>
	);
};

export default ViewCoursePage;
