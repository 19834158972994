import { Button, Col, Divider, message, Modal, Row, Select, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../Context/UserContext";
import { priceFormatter } from "../../Functions/formatters";
import { colorsLight } from "../../Styles/Themes";
import moment from "moment";
import { app } from "firebase";
import ProcessingPayment from "../Common/ProcessingPayment";
import PurchaseOutcome from "../ViewPackage/BookingModal/PurchaseOutcome";
import { Link } from "react-router-dom";

const PurchaseModal = ({ visible, dismiss, data, providerData, type }) => {
	const [step, setStep] = useState(1);
	const { accountBalance, userInfo } = useContext(UserContext);
	const [dateSelection, setDateSelection] = useState();
	const [buttonStat, setButtonStat] = useState(false);
	const [loading, setLoading] = useState(false);
	const [programId, setProgramId] = useState("");

	const navBack = () => {
		if (step === 1) {
			setDateSelection();
			dismiss();
		} else {
			setStep(step - 1);
		}
	};

	const navForward = () => {
		if (step === 1) {
			if (data.availability === "dates") {
				if (typeof dateSelection === "number") {
					setStep(step + 1);
				} else {
					message.error("Please select a date");
				}
			} else {
				setStep(step + 1);
			}
		} else {
			confirmPurchase();
		}
	};

	useEffect(() => {
		if (data.availability === "dates" && typeof dateSelection === "number") {
			setButtonStat(false);
		} else if (data.availability !== "dates") {
			setButtonStat(false);
		} else {
			setButtonStat(true);
		}
	}, [data, dateSelection]);

	const confirmPurchase = () => {
		setLoading(true);
		setStep(4);
		app()
			.functions("asia-northeast1")
			.httpsCallable("PurchaseHandler")({
				env:
					process.env.REACT_APP_PROJECT_ID === "careerbase-development-2610e"
						? "development"
						: "production",
				purchaserId: userInfo.key,
				providerId: data.owner,
				packageId: data.key,
				initiatorData: userInfo,
				initialSession: false,
				type: type,
			})
			.then((res) => {
				if (res.data.status === "success") {
					setLoading(false);
					setDateSelection();
					setStep(5);
					setProgramId(res.data.programId);
					message.success("Added Course to your program");
				} else {
					dismiss();
					message.error(res.data.message);
				}
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	};

	return (
		<Modal
			width={700}
			visible={visible}
			onCancel={dismiss}
			footer={[
				<>
					{step <= 3 ? (
						<>
							<Button onClick={navBack} key="cancel">
								{step === 1 ? "Cancel" : "Back"}
							</Button>

							<Button
								loading={loading}
								disabled={buttonStat}
								onClick={navForward}
								type="primary"
								key="next"
							>
								{step === 1 ? "Next" : "Confirm"}
							</Button>
						</>
					) : (
						<>
							{step === 5 && (
								<Link to={programId ? `/View-${type}-Program/${programId}` : "/"}>
									<Button type="primary">View your program</Button>
								</Link>
							)}
						</>
					)}
				</>,
			]}
		>
			{/* PURCHASE PREVIEW SCREEN */}
			{step === 1 && (
				<Col>
					<Row justify="center">
						<Row style={styles.balanceWrapper}>
							<Col>
								<Row justify="center">
									<p style={styles.title}>Your account balance</p>
								</Row>
								<Row justify="center">
									<p style={styles.balance}>
										{priceFormatter(accountBalance, "points") + " pts"}
									</p>
								</Row>
							</Col>
						</Row>
					</Row>
					<Divider style={{ margin: "12px 0px 12px 0px" }} />
					<Row>
						<Col sm={8} xs={24}>
							<p style={styles.description}>Course:</p>
							<p style={styles.label} data-testid="booking-name">
								{data.name}
							</p>
						</Col>
						<Col sm={8} xs={24}>
							<p style={styles.description}>Provider:</p>
							<p style={styles.label} data-testid="booking-coach">
								{providerData.name}
							</p>
						</Col>
						{data.courseType && (
							<Col sm={8} xs={24}>
								<p style={styles.description}>Type:</p>
								<p style={styles.label} data-testid="booking-sessions">
									{data.courseType}
								</p>
							</Col>
						)}
					</Row>
					<Row justify="end">
						{data.availability === "dates" && (
							<Col style={{ paddingRight: 24 }} sm={16} xs={24}>
								<p style={{ ...styles.description, marginBottom: 6 }}>Dates:</p>
								<Select
									value={dateSelection}
									onChange={(val) => setDateSelection(val)}
									style={{ width: "100%", marginTop: 3 }}
								>
									{data.dates.map((item, index) => (
										<Select.Option value={index} key={index}>
											<Col>
												<Row>{`Dates: ${moment(item.startTime).format(
													"Do MMMM"
												)} - ${moment(item.endTime).format(
													"Do MMMM YYYY"
												)}`}</Row>
												<Row>{`Location: ${item.location}`}</Row>
											</Col>
										</Select.Option>
									))}
								</Select>
							</Col>
						)}
						<Col sm={8} xs={24}>
							<p style={{ ...styles.description, marginBottom: 6 }}>Price:</p>
							<p
								style={{
									...styles.label,
									color: colorsLight("lightBlue"),
									fontSize: 22,
									fontWeight: "bold",
								}}
								data-testid="booking-sessions"
							>
								{priceFormatter(data.price, "points") + " pts"}
							</p>
						</Col>
					</Row>
					{typeof dateSelection === "number" && (
						<Row>
							<Col sm={12} xs={24}>
								<p style={{ ...styles.description, marginBottom: 6, marginTop: 6 }}>
									Additional details:
								</p>
								<p style={styles.label} data-testid="booking-sessions">
									{data.dates[dateSelection].details}
								</p>
							</Col>
						</Row>
					)}
				</Col>
			)}
			{/* CONFIRMATION SCREEN */}
			{step === 2 && (
				<Col>
					<Row>
						<Typography.Title level={4}>
							Please confirm purchase details:
						</Typography.Title>
					</Row>
					<Divider style={{ margin: "0px 0px 12px 0px" }} />
					<Row>
						<Col sm={8} xs={24}>
							<p style={styles.description}>Course:</p>
							<p style={styles.label} data-testid="booking-name">
								{data.name}
							</p>
						</Col>
						<Col sm={8} xs={24}>
							<p style={styles.description}>Provider:</p>
							<p style={styles.desciption} data-testid="booking-coach">
								{providerData.name}
							</p>
						</Col>
						<Col sm={8} xs={24}>
							<p style={styles.description}>Type:</p>
							<p style={styles.label} data-testid="booking-sessions">
								{data.courseType}
							</p>
						</Col>
					</Row>
					{typeof dateSelection === "number" && (
						<>
							<Row>
								<Col sm={8} xs={24}>
									<p
										style={{
											...styles.description,
											marginBottom: 6,
											marginTop: 6,
										}}
									>
										Start date:
									</p>
									<p style={styles.label} data-testid="booking-sessions">
										{moment(data.dates[dateSelection].startTime).format(
											"Do MMMM YYYY"
										)}
									</p>
								</Col>
								<Col sm={8} xs={24}>
									<p
										style={{
											...styles.description,
											marginBottom: 6,
											marginTop: 6,
										}}
									>
										End date:
									</p>
									<p style={styles.label} data-testid="booking-sessions">
										{moment(data.dates[dateSelection].endTime).format(
											"Do MMMM YYYY"
										)}
									</p>
								</Col>
								<Col sm={8} xs={24}>
									<p style={{ ...styles.description, marginBottom: 6 }}>Price:</p>
									<p
										style={{
											...styles.label,
											color: colorsLight("lightBlue"),
											fontSize: 22,
											fontWeight: "bold",
										}}
										data-testid="booking-sessions"
									>
										{priceFormatter(data.price, "points") + " pts"}
									</p>
								</Col>
							</Row>
							<Row>
								<Col sm={12} xs={24}>
									<p
										style={{
											...styles.description,
											marginBottom: 6,
											marginTop: 6,
										}}
									>
										Additional details:
									</p>
									<p style={styles.label} data-testid="booking-sessions">
										{data.dates[dateSelection].details}
									</p>
								</Col>
							</Row>
						</>
					)}
				</Col>
			)}

			{step === 4 && <ProcessingPayment />}
			{step === 5 && <PurchaseOutcome programId={programId} type={type} />}
		</Modal>
	);
};

const styles = {
	balanceWrapper: {
		borderRadius: 9,
		borderStyle: "solid",
		borderColor: "grey",
		borderWidth: 1,
		padding: 12,
		marginBottom: 12,
	},
	balance: {
		fontSize: 32,
		fontWeight: "bold",
		color: colorsLight("lightBlue"),
		margin: 0,
	},
	title: {
		fontSize: 16,
		color: colorsLight("darkGrey"),
		marginBottom: 0,
	},
	label: {
		marginBottom: 12,
	},
	description: {
		fontSize: 16,
		fontWeight: "bold",
		color: colorsLight("black"),
		marginBottom: 0,
		paddingBottom: 0,
	},
};

export default PurchaseModal;
