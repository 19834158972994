import { PieChartOutlined, SearchOutlined } from "@ant-design/icons";
import { Menu, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../Assets/logo-small.png";
import { colorsLight } from "../../Styles/Themes";
import TopMenu from "../Account/TopMenu";

const DesktopMenu = ({ active }) => {
	// const styles = {
	// 	open: {
	// 		width: "calc(100% - 150px)",
	// 		padding: 0,
	// 		zIndex: 1005,
	// 		right: 0,
	// 		transition: "width 0.3s",
	// 		position: "fixed",
	// 		height: 69,
	// 	},
	// 	closed: {
	// 		position: "fixed",
	// 		display: "contents",
	// 		width: "100%",
	// 		height: 69,
	// 	},
	// };

	// const menus = [
	// 	{
	// 		name: "Home",
	// 		icon: <PieChartOutlined />,
	// 		link: "/Home",
	// 		testId: "menu-home",
	// 	},
	// 	{
	// 		name: "My Program",
	// 		icon: <PieChartOutlined />,
	// 		link: "/My-Program",
	// 		testId: "menu-program",
	// 	},
	// 	{
	// 		name: "Featured",
	// 		icon: <CrownOutlined />,
	// 		link: "/Featured",
	// 		testId: "menu-featured",
	// 	},
	// 	{
	// 		name: "Coaches",
	// 		icon: <RocketOutlined />,
	// 		link: "/Browse-Coaches",
	// 		testId: "menu-coaches",
	// 	},
	// 	{
	// 		name: "Courses",
	// 		icon: <SolutionOutlined />,
	// 		link: "/Browse-Courses",
	// 		testId: "menu-courses",
	// 	},
	// 	{
	// 		name: "Services",
	// 		icon: <ThunderboltOutlined />,
	// 		link: "/Browse-Services",
	// 		testId: "menu-services",
	// 	},
	// ];

	return (
		<Row
			justify="space-between"
			style={{
				height: 60,
				display: "table",
				zIndex: 2,
				backgroundColor: "white",
				width: "100%",
			}}
			data-testid="header-desktop"
		>
			<div
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					boxShadow: "1px 2px 7px rgba(0,0,0,0.1)",
				}}
				data-testid="menu-list"
			>
				<Row>
					<Row
						style={{
							backgroundColor: colorsLight("primary"),
							height: "100%",
							paddingLeft: 12,
							paddingRight: 12,
						}}
					>
						<img
							src={Logo}
							alt="Careerbase logo"
							style={{ height: 20, alignSelf: "center" }}
							data-testid="menu-icon"
						/>
					</Row>
					<Row align="bottom">
						<Menu
							theme="light"
							mode="horizontal"
							defaultSelectedKeys="1"
							selectedKeys={active}
							style={{ borderBottomColor: "white" }}
						>
							<Menu.Item key="1" icon={<PieChartOutlined />}>
								<Link to="/Home" data-testid="menu-home">
									Home
								</Link>
							</Menu.Item>
							<Menu.Item key="2" icon={<PieChartOutlined />}>
								<Link to="/My-Program" data-testid="menu-program">
									My Program
								</Link>
							</Menu.Item>

							<Menu.Item key="3" icon={<SearchOutlined />}>
								<Link to="/Featured" data-testid="menu-featured">
									Browse
								</Link>
							</Menu.Item>
							{/* <Menu.SubMenu key="3" icon={<SearchOutlined />} title="Browse">
								<Menu.Item key="3" icon={<PieChartOutlined />}>
									<Link to="/Featured" data-testid="menu-featured">
										Featured
									</Link>
								</Menu.Item>
								<Menu.Item key="4" icon={<RocketOutlined />}>
									<Link to="/Browse-Coaches" data-testid="menu-coaches">
										Coaches
									</Link>
								</Menu.Item>
								<Menu.Item key="5" icon={<SolutionOutlined />}>
									<Link to="/Browse-Courses" data-testid="menu-courses">
										Courses
									</Link>
								</Menu.Item>
								<Menu.Item key="6" icon={<SolutionOutlined />}>
									<Link to="/Browse-Services" data-testid="menu-services">
										Services
									</Link>
								</Menu.Item>
							</Menu.SubMenu> */}
						</Menu>
					</Row>
				</Row>
				<Row
					style={{ height: "100%", paddingRight: 12 }}
					justify="end"
					align="middle"
					data-testid="menu-options"
				>
					<TopMenu />
				</Row>
			</div>
		</Row>
	);
};

export default DesktopMenu;
