import React from "react";
import { Card, Row, Col } from "antd";
import { colorsLight } from "../../Styles/Themes";

const Schedule = () => {
	const styles = {
		number: {
			fontSize: 36,
			color: colorsLight("black"),
			marginRight: 10,
		},
		description: {
			fontSize: 14,
			color: colorsLight("black"),
		},
		row: {
			display: "flex",
			flexWrap: "nowrap",
			marginBottom: 10,
		},
	};
	return (
		<Card style={{ borderRadius: 12 }} title="How It Works?">
			<Row style={styles.row} align="middle" data-testid="process-1">
				<Col style={styles.number}>1.</Col>
				<Col style={styles.description}>
					Once you choose your course, we confirm your booking with the Provider.
				</Col>
			</Row>
			<Row style={styles.row} align="middle" data-testid="process-2">
				<Col style={styles.number}>2.</Col>
				<Col style={styles.description}>
					You will receive an email from the provider confirming your booking details.
				</Col>
			</Row>
			<Row style={styles.row} align="middle" data-testid="process-3">
				<Col style={styles.number}>3.</Col>
				<Col style={styles.description}>Start Upskilling</Col>
			</Row>
		</Card>
	);
};

export default Schedule;
