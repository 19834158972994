import { Col, Row, Typography } from "antd";
import React from "react";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import success from "../../../Assets/success.json";

const PurchaseOutcome = ({ programId, type }) => {
	const animOptions = {
		loop: false,
		autoplay: true,
		animationData: success,
	};
	return (
		<Col span={24}>
			<Row justify="center" align="middle" style={{ height: 300, width: "100%" }}>
				<Lottie options={animOptions} width={160} isStopped={false} isPaused={false} />
			</Row>
			<Row justify="center">
				<Link to={programId ? `/View-${type}-Program/${programId}` : "/"}>
					<Typography.Title style={{ margin: 0 }} level={3}>
						You're all set! Let's manage your program!
					</Typography.Title>
				</Link>
			</Row>
		</Col>
	);
};

export default PurchaseOutcome;
