import { Col, Layout, message, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import firebase from "../../API/firebase";
import HeaderMain from "../../Components/Headers/Header";
import BackButton from "../../Components/Navigation/BackButton";
import BookingModal from "../../Components/ViewPackage/BookingModal/BookingModal";
import BuyPackagePanel from "../../Components/ViewPackage/BuyPackagePanel";
import CoachDetailsPanel from "../../Components/ViewPackage/CoachDetailsPanel";
import OtherOffersPanel from "../../Components/ViewPackage/OtherOffersPanel";
import PackageDetails from "../../Components/ViewPackage/PackageDetails";
import DataContext from "../../Context/DataContext";
import UserContext from "../../Context/UserContext";
import ViewContext from "../../Context/ViewContext";
import { maxContentWidth } from "../../Styles/Themes";

const { Content } = Layout;

const ViewPackage = () => {
	const { isMobile, isIpad, isLaptop, isDesktop } = useContext(ViewContext);
	const { userInfo } = useContext(UserContext);
	const { packages } = useContext(DataContext);
	const [modal, showModal] = useState(false);
	const [packData, setPackData] = useState({});
	const [coachData, setCoachData] = useState({});
	const [otherPacks, setOtherPacks] = useState([]);
	const { coachIdANDpackageId } = useParams();
	const coachId = coachIdANDpackageId.split("&")[0];
	const packageId = coachIdANDpackageId.split("&")[1];
	const history = useHistory();
	const scrollRef = React.createRef();

	useEffect(() => {
		scrollRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
		// eslint-disable-next-line
	}, [coachIdANDpackageId]);

	useEffect(() => {
		firebase
			.firestore()
			.collection("Packages")
			.doc(packageId)
			.get()
			.then((doc) => {
				doc.exists && setPackData({ ...doc.data(), key: doc.id });
				return firebase.firestore().collection("Coaches").doc(coachId).get();
			})
			.then((doc) =>
				doc.exists
					? setCoachData({ ...doc.data(), key: doc.id })
					: console.log("could not get coach data")
			)
			.catch((err) => message.error(err));

		if (userInfo.activePlaylist) {
			console.log("filtering");
			const filtered = packages.filter(
				(item) => item.owner === coachId && item.key !== packageId
			);
			setOtherPacks(filtered);
		} else {
			firebase
				.firestore()
				.collection("Packages")
				.where("owner", "==", coachId)
				.get()
				.then(async (snap) => {
					if (snap.size > 1) {
						let list = [];
						snap.forEach((doc) => {
							doc.id !== packageId && list.push({ ...doc.data(), key: doc.id });
						});
						setOtherPacks(list);
					} else {
						let req = await firebase
							.firestore()
							.collection("Packages")
							.where("owner", "!=", coachId)
							.limit(2)
							.get();
						let list = [];
						req.forEach((doc) => {
							list.push({ ...doc.data(), key: doc.id });
						});
						setOtherPacks(list);
					}
				})
				.catch((err) => {
					message.error(err.message);
				});
		}

		// eslint-disable-next-line
	}, [coachIdANDpackageId, userInfo]);

	return (
		<Layout className={isMobile ? "layout" : "App"}>
			<HeaderMain />
			<Content
				style={{ overflow: "scroll", paddingBottom: isMobile ? 64 : 24 }}
				className="content"
			>
				<Row ref={scrollRef} justify="center" align="middle" style={{ width: "100%" }}>
					<Col
						style={{ maxWidth: isDesktop ? maxContentWidth : null, paddingTop: 12 }}
						span={24}
					>
						{(isLaptop || isDesktop) && (
							<div data-testid="package-laptopView">
								<Row style={{ padding: "0px 12px 12px 24px" }}>
									<BackButton history={history} />
								</Row>

								<Row style={{ padding: "0px 24px 12px" }}>
									<Col span={16}>
										<PackageDetails data={packData} />
									</Col>
									<Col
										style={{
											padding: "0px 0px 0px 24px",
										}}
										span={8}
									>
										<Row justify="space-between">
											<Col span={24}>
												<CoachDetailsPanel data={coachData} />
											</Col>
											<Col span={24}>
												<BuyPackagePanel
													packData={packData}
													buyPackage={showModal}
												/>
											</Col>
										</Row>
									</Col>
								</Row>
								{otherPacks.length > 0 && (
									<Row style={{ padding: "0px 24px 12px" }}>
										<Col>
											<OtherOffersPanel data={otherPacks} />
										</Col>
									</Row>
								)}
							</div>
						)}
						{isIpad && (
							<div data-testid="package-ipadView">
								<Row style={{ padding: "0px 0px 12px 24px" }}>
									<BackButton history={history} />
								</Row>
								<Row style={{ padding: "0px 24px 12px" }}>
									<Col span={24}>
										<PackageDetails data={packData} />
									</Col>
									<Col
										style={{
											padding: "24px 0px 0px",
										}}
										span={24}
									>
										<Row justify="space-between">
											<Col span={12}>
												<CoachDetailsPanel data={coachData} />
											</Col>
											<Col span={11}>
												<BuyPackagePanel
													packData={packData}
													buyPackage={showModal}
												/>
											</Col>
										</Row>
									</Col>
								</Row>
								{otherPacks.length > 0 && (
									<Row style={{ padding: "24px 24px 12px" }}>
										<Col>
											<OtherOffersPanel data={otherPacks} />
										</Col>
									</Row>
								)}
							</div>
						)}
						{isMobile && (
							<div data-testid="package-mobileView">
								<Row style={{ padding: "54px 0px 12px 12px" }}>
									<BackButton history={history} />
								</Row>
								<Row style={{ padding: "0px 12px 24px" }}>
									<Col span={24}>
										<PackageDetails data={packData} />
									</Col>
									<Col
										style={{
											padding: "24px 0px 0px",
										}}
										span={24}
									>
										<Row justify="space-between">
											<Col span={24}>
												<CoachDetailsPanel data={coachData} />
											</Col>
											<Col span={24}>
												<BuyPackagePanel
													packData={packData}
													buyPackage={showModal}
												/>
											</Col>
										</Row>
									</Col>
								</Row>
								{otherPacks.length > 0 && (
									<Row style={{ padding: "0px 0px 0px 12px" }}>
										<Col>
											<OtherOffersPanel data={otherPacks} />
										</Col>
									</Row>
								)}
							</div>
						)}
					</Col>
				</Row>
				<BookingModal
					packData={packData}
					isVisible={modal}
					setIsVisible={showModal}
					type="Coaches"
					coachData={coachData}
				/>
			</Content>
		</Layout>
	);
};

export default ViewPackage;
