import { Avatar, Col, Divider, Row, Typography, Skeleton } from "antd";
import React, { useContext } from "react";
import ViewContext from "../../Context/ViewContext";
import { getInitials } from "../../Functions/formatters";
import { getImage } from "../../Functions/utilities";
import { colorsLight } from "../../Styles/Themes";

const { Title, Paragraph } = Typography;

const CoachDetails = ({ data = {} }) => {
	const { isMobile } = useContext(ViewContext);
	const styles = {
		wrapper: {
			borderRadius: 6,
			backgroundColor: colorsLight("white"),
			overflow: "hidden",
		},
		header: {
			width: "100%",
			marginBottom: 12,
		},
		coverPhoto: {
			backgroundImage: `url(${data.coverPic ? data.coverPic : getImage()})`,
			backgroundSize: "cover",
			height: isMobile ? 100 : 140,
			width: "100%",
		},
		name: {
			fontWeight: "bold",
			fontSize: 26,
			color: colorsLight("black"),
			marginBottom: 0,
			lineHeight: 1.3,
		},
		coachType: {
			fontSize: 14,
			color: "#5c5c5c",
			margin: 0,
		},
		avatar: {
			padding: 4,
			backgroundColor: "white",
			boxShadow: "1px 1px 6px rgba(0,0,0,0.2)",
			borderRadius: "50%",
			margin: "0px 0px 10px 0px",

			position: "absolute",
			top: isMobile ? 44 : 70,
		},
		coachHeader: {
			width: "100%",
			borderRadius: "9px 9px 0px 0px",
			margin: 0,
			padding: isMobile ? "0px 12px" : "0px 24px",
		},
		title: {
			fontWeight: "bold",
			fontSize: 22,
		},
		description: {
			fontSize: 14,
			marginBottom: 0,
		},
		video: {
			marginTop: 10,
			width: "100%",
		},
		row: {
			padding: isMobile ? "20px 12px" : "20px 24px",
			width: "100%",
		},
	};

	return (
		<Row style={styles.wrapper}>
			<Row style={styles.header}>
				<Row style={styles.coverPhoto} data-testid="coach-coverPhoto" />
				<Row justify="space-between" align="bottom" style={styles.coachHeader}>
					<Row justify="space-between" align="center" style={styles.avatar}>
						{data.displayPic ? (
							<Avatar
								style={{ margin: 2 }}
								src={data.displayPic}
								size={isMobile ? 100 : 132}
								data-testid="coach-avatar"
							>
								{getInitials(data.name || "")}
							</Avatar>
						) : (
							<Skeleton.Avatar active size={isMobile ? 100 : 132} />
						)}
					</Row>
					<Col
						style={{ margin: isMobile ? "3px 0px 0px 120px" : "3px 0px 0px 160px" }}
						flex="auto"
					>
						{data.displayName && data.primaryCategory ? (
							<>
								<Typography.Title
									style={{ margin: 0 }}
									data-testid="coach-name"
									level={isMobile ? 3 : 2}
								>
									{data.displayName}
								</Typography.Title>
								<p style={styles.coachType} data-testid="coach-type">
									{data.primaryCategory} Coach
								</p>
							</>
						) : (
							<Skeleton active paragraph={{ rows: 1 }} />
						)}
					</Col>
				</Row>
			</Row>
			<Divider style={{ margin: "12px 0px 12px" }} />
			<Row style={styles.row}>
				<Title level={4}>About</Title>
				{data.summary ? (
					<Paragraph style={styles.description} data-testid="coach-description">
						{data.summary}
					</Paragraph>
				) : (
					<Skeleton active title={false} paragraph={{ rows: 4 }} />
				)}
			</Row>
			<Row style={styles.row}>
				<Title style={{ width: "100%" }} level={4}>
					Biography
				</Title>
				{data.details ? (
					<div
						data-testid="coach-biography"
						dangerouslySetInnerHTML={{ __html: data.details }}
					/>
				) : (
					<Skeleton active title={false} paragraph={{ rows: 4 }} />
				)}
			</Row>
			{/* <Row style={styles.row}>
				<Title level={4}>Media</Title>
				<video style={styles.video} controls data-testid="coach-media" />
			</Row> */}
		</Row>
	);
};

export default CoachDetails;
