import { Row, Col, Typography, Card, List, Divider } from "antd";
import React, { useContext, useState } from "react";
import moment from "moment";
import ViewContext from "../../Context/ViewContext";
import ViewSession from "./ViewSession";
const { Item } = List;

const ScheduleList = ({ sessions }) => {
	const { isMobile } = useContext(ViewContext);
	const [isVisible, setIsVisible] = useState(false);
	const [session, setSession] = useState({});

	const handleViewSession = (data) => {
		setSession(data);
		setIsVisible(true);
	};

	return (
		<>
			<List
				grid={{ column: isMobile ? 1 : 3, gutter: 24 }}
				dataSource={sessions}
				renderItem={(item) => (
					<Item
						style={{ cursor: "pointer" }}
						onClick={() => handleViewSession(item)}
						data-testid="schedule"
					>
						<Card bodyStyle={{ padding: 15 }}>
							{item.startTime !== "TBA" && (
								<Row align="middle">
									<Col>
										<Typography.Title
											level={1}
											style={{
												color: "#647284",
												fontSize: 50,
												fontWeight: "bolder",
												marginRight: 10,
												marginBottom: 0,
											}}
											data-testid="schedule-date"
										>
											{moment(item.startTime).format("D")}
										</Typography.Title>
									</Col>
									<Col>
										<Typography.Title
											level={5}
											style={{ color: "#647284", marginBottom: 0 }}
											data-testid="schedule-month"
										>
											{moment(item.startTime).format("MMMM")}
										</Typography.Title>
										<Typography.Title
											level={5}
											style={{ color: "#647284", margin: 0 }}
											data-testid="schedule-time"
										>
											{moment(item.startTime).format("HH:mm")} -{" "}
											{moment(item.endTime).format("HH:mm")}
										</Typography.Title>
									</Col>
								</Row>
							)}
							<Typography.Title level={2} data-testid="schedule-title">
								{item.title}
							</Typography.Title>
							<Divider style={{ marginBottom: 10 }} />
							<Typography.Title
								level={5}
								style={{ color: "#647284", marginBottom: 0 }}
								data-testid="schedule-delivery"
							>
								Zoom or In-Person
							</Typography.Title>
						</Card>
					</Item>
				)}
			/>
			<ViewSession isVisible={isVisible} session={session} setIsVisible={setIsVisible} />
		</>
	);
};

export default ScheduleList;
