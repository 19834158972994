/* eslint-disable */
import moment from "moment";

export const getAvailableSlots = (date, blocks, coachData, packageData) => {
	const day = moment(date).day();
	const dateRep = moment(date).format("YYYYMMDD");
	const hoursStart = moment(`${dateRep}${coachData.availability[day].start}`, "YYYYMMDDHHmm");
	const hoursEnd = moment(`${dateRep}${coachData.availability[day].finish}`, "YYYYMMDDHHmm");
	const duration = packageData.duration || 60;
	const bloks = isBlockPresentToday(date, blocks);
	let cursor = hoursStart;
	let sessions = [];
	let counter = 0;
	while (moment(cursor).isBefore(hoursEnd)) {
		counter++;
		let proposed = moment(cursor).add(duration, "minutes");
		if (moment(proposed).isBefore(hoursEnd)) {
			if (bloks.length > 0) {
				let clash = [];
				bloks.forEach((blok) => {
					if (
						moment(blok.startTime).isBefore(cursor) &&
						moment(blok.endTime).isAfter(cursor)
					) {
						clash.push("overlap found");
					} else if (
						moment(blok.startTime).isBefore(proposed) &&
						moment(blok.endTime).isAfter(proposed)
					) {
						clash.push("overlap found");
					} else if (
						moment(blok.startTime).format("YYYYMMDDHHmm") ===
							moment(cursor).format("YYYYMMDDHHmm") ||
						moment(blok.endTime).format("YYYYMMDDHHmm") ===
							moment(cursor).format("YYYYMMDDHHmm")
					) {
						clash.push("overlap found");
					} else if (
						moment(blok.startTime).format("YYYYMMDDHHmm") ===
							moment(proposed).format("YYYYMMDDHHmm") ||
						moment(blok.endTime).format("YYYYMMDDHHmm") ===
							moment(proposed).format("YYYYMMDDHHmm")
					) {
						clash.push("overlap found");
					} else if (
						moment(blok.startTime).format("YYYYMMDDHHmm") >=
							moment(cursor).format("YYYYMMDDHHmm") &&
						moment(blok.endTime).format("YYYYMMDDHHmm") <=
							moment(proposed).format("YYYYMMDDHHmm")
					) {
						clash.push("overlap found");
					} else {
						return;
					}
				});
				clash.length === 0 &&
					sessions.push({
						startTime: cursor,
						endTime: proposed,
					});
			} else {
				sessions.push({
					startTime: cursor,
					endTime: proposed,
				});
			}
		}

		cursor = proposed;
		if (counter > 20) {
			break;
		}
	}
	return sessions;
};

const isBlockPresentToday = (date, blocks) => {
	const ref = moment(date).format("YYYYMMDD");
	let list = [];
	blocks.forEach((block) => {
		const start = moment(block.startTime).format("YYYYMMDD");
		const end = moment(block.endTime).format("YYYYMMDD");
		if (start === ref || end === ref) {
			list.push(block);
		}
	});
	return list;
};
