import { Button, Col, Divider, Row, Typography, Skeleton } from "antd";
import React, { useContext } from "react";
import UserContext from "../../Context/UserContext";
import { priceFormatter } from "../../Functions/formatters";
import { getPoints } from "../../Functions/helpers";
import { colorsLight } from "../../Styles/Themes";

const BuyPackagePanel = ({ buyPackage, packData, coachData }) => {
	const { accountBalance } = useContext(UserContext);
	return (
		<Col className="Card" style={{ padding: 0 }}>
			<Row style={{ padding: "12px 12px 0px", margin: 0 }}>
				<Typography.Title style={{ margin: 0 }} level={4}>
					Redeem
				</Typography.Title>
			</Row>

			<Row style={{ padding: "12px 12px 12px" }}>
				<Divider style={{ margin: 0 }} />
			</Row>

			<Row justify="space-between" align="bottom" style={styles.row}>
				<Col span={8}>
					<p style={styles.name}>Your current balance</p>
				</Col>
				<Col span={16}>
					<p style={styles.balance} data-testid="redeem-balance">
						{accountBalance ? (
							priceFormatter(accountBalance, "points") + " pts"
						) : (
							<Skeleton
								active
								paragraph={false}
								title={{ width: "50%", style: { margin: 0, float: "right" } }}
							/>
						)}
					</p>
				</Col>
			</Row>
			<Row align="bottom" style={styles.row}>
				<Col span={8}>
					<p style={styles.name}>Package price</p>
				</Col>
				<Col span={16}>
					<p style={styles.price} data-testid="redeem-price">
						{packData.price ? (
							getPoints(packData.price)
						) : (
							<Skeleton
								active
								paragraph={false}
								title={{ width: "50%", style: { margin: 0, float: "right" } }}
							/>
						)}
					</p>
				</Col>
			</Row>
			<Row justify="end" style={{ ...styles.row, marginTop: 3, paddingBottom: 12 }}>
				<Button
					type="primary"
					data-testid="redeem-button"
					onClick={() => buyPackage(true)}
					disabled={!packData.price || !accountBalance}
				>
					Purchase Package
				</Button>
			</Row>
		</Col>
	);
};

const styles = {
	row: {
		marginBottom: 20,
		padding: "0px 12px 0px",
	},
	name: {
		fontSize: 14,
		marginBottom: 0,
	},
	price: {
		fontSize: 22,
		fontWeight: 700,
		color: colorsLight("lightBlue"),
		textAlign: "right",
		marginBottom: 0,
	},
	balance: {
		fontSize: 22,
		color: colorsLight("grey"),
		textAlign: "right",
		marginBottom: 0,
	},
};

export default BuyPackagePanel;
