import { createAuthButton } from "@kloudless/authenticator/react";
import { Badge, Button, Col, message, Row } from "antd";
import React, { useContext } from "react";
import firebase from "../../API/firebase";
import { colorsLight } from "../../Styles/Themes";
import UserContext from "../../Context/UserContext";

const AuthButton = (props) => (
	<Button data-testid="sync-button" onClick={props.onClick}>
		Connect Account
	</Button>
);

const CalendarSync = () => {
	const { uid, userInfo, setUserInfo } = useContext(UserContext);
	let extraData = JSON.stringify({ uid: `${uid}` });
	const CustomAuthButton = createAuthButton(AuthButton);

	const updateSyncStatus = (result) => {
		const payload = {
			kloudlessId: result.account.id,
			calendarSync: true,
			calendarService: result.account.service_name,
		};
		firebase
			.firestore()
			.collection("Members")
			.doc(uid)
			.update(payload)
			.then(() => {
				message.success("Successfully synced your calendar!");
				setUserInfo({ ...userInfo, ...payload });
			})
			.catch(() => {
				message.error(
					"We've linked your account, however we couldn't sync it. Try again later."
				);
			});
	};

	return (
		<Badge.Ribbon
			color={userInfo.calendarSync ? colorsLight("green") : "red"}
			text={
				<div data-testid="calendar-sync">
					{userInfo.calendarSync ? "ACTIVE" : "INACTIVE"}
				</div>
			}
			placement="start"
		>
			<Row
				style={{
					borderWidth: 1,
					borderRadius: 9,
					borderStyle: "solid",
					borderColor: colorsLight("lightgrey"),
					padding: 12,
					backgroundColor: "white",
					boxShadow: "1px 2px 2px rgba(0,0,0,0.2)",
				}}
				justify="space-between"
			>
				<Col style={{ paddingTop: 24 }}>
					<p style={{ fontSize: 22, fontWeight: 300, margin: 0 }}>
						Calendar Availability Sync
					</p>
					<Row>
						<p style={{ margin: 0, marginRight: 3 }}>Works with:</p>
						<p style={{ margin: 0, fontWeight: 600 }}>
							Google, Outlook, iCloud, Exchange Server & CalDAV
						</p>
					</Row>
				</Col>
				<Row align="bottom" data-testid="connect-btn">
					<CustomAuthButton
						options={{
							client_id: "udgTFUF07bdTYfIVp228VrhBbSdq26W_ezYi5k5SwlZo7MWh",
							scope: "any.calendar",
							custom_properties: extraData,
						}}
						onSuccess={(result) => {
							updateSyncStatus(result);
						}}
						onError={() => {
							message.error("Couldn't link your account, please try again.");
						}}
					/>
				</Row>
			</Row>
		</Badge.Ribbon>
	);
};

export default CalendarSync;
