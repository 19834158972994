import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, message, Row, Spin, Typography, Avatar } from "antd";
import moment from "moment";
import React, { useContext, useState } from "react";
import firebase from "../../API/firebase";
import UserContext from "../../Context/UserContext";
import ViewContext from "../../Context/ViewContext";
import { getInitials } from "../../Functions/formatters";
import ProfilePicUploadModal from "./ProfilePicUploadModal";

const EditDetails = ({ toggleEdit, toggleEditPic, editPic }) => {
	const { uid, userInfo, setUserInfo } = useContext(UserContext);
	const { isMobile } = useContext(ViewContext);
	const [, setLoading] = useState(false);
	const [hover, setHover] = useState(false);

	const updateInfo = (val) => {
		setLoading(true);
		const payload = { ...val, displayName: `${val.firstName} ${val.lastName}` };
		firebase
			.firestore()
			.collection("Members")
			.doc(uid)
			.update(payload)
			.then(() => {
				setLoading(false);
				setUserInfo({ ...userInfo, ...payload });
				toggleEdit();
				message.success("Successfully updated your info");
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
				message.error("Something went wrong");
			});
	};

	const loadingIcon = <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />;

	return (
		<>
			<Row justify="space-between">
				<Row>
					<Avatar src={userInfo.displayPic} size={80} data-testid="avatar">
						{userInfo.displayName ? (
							getInitials(userInfo.displayName)
						) : (
							<Spin indicator={loadingIcon} />
						)}
					</Avatar>
					<Row
						justify="center"
						align="middle"
						onClick={toggleEditPic}
						onMouseEnter={() => setHover(true)}
						onMouseLeave={() => setHover(false)}
						style={{
							height: 80,
							width: 80,
							borderRadius: 40,
							backgroundColor: hover ? "rgba(0,0,0,0.5)" : "rgba(0,0,0,0)",
							position: "absolute",
							cursor: "pointer",
						}}
						data-testid="edit-photo"
					>
						{hover && <p style={{ color: "white", margin: 0 }}>Edit Photo</p>}
					</Row>
					<ProfilePicUploadModal
						visible={editPic}
						dismiss={toggleEditPic}
						userInfo={userInfo}
						setUserInfo={setUserInfo}
					/>
					<Col style={{ marginLeft: 12, paddingTop: 6 }}>
						<Typography.Title style={{ margin: 0 }} level={2} data-testid="name">
							{userInfo.displayName}
						</Typography.Title>
					</Col>
				</Row>
				<Row style={{ paddingTop: 10 }}>
					<Button
						style={{ marginRight: 12 }}
						onClick={toggleEdit}
						size="small"
						data-testid="cancel"
					>
						Cancel
					</Button>
					<Button
						type="primary"
						form="editInfo"
						htmlType="submit"
						key="submit"
						size="small"
						data-testid="update"
					>
						Update Info
					</Button>
				</Row>
			</Row>
			<Row style={{ paddingLeft: 6, paddingRight: 6 }}>
				<Divider style={{ marginTop: 12, marginBottom: 12 }} />
			</Row>
			<Row>
				<Typography.Title level={4}>Basic Information</Typography.Title>
			</Row>
			<Form id="editInfo" initialValues={userInfo} onFinish={updateInfo}>
				<Row>
					<Col style={{ paddingLeft: 12, paddingRight: 12 }} span={isMobile ? 24 : 12}>
						<Row justify="space-between">
							<p className="Key">First Name</p>
							<Form.Item
								required={true}
								style={{ margin: 0, marginBottom: 6 }}
								name="firstName"
							>
								<Input
									style={{ textAlign: "end" }}
									placeholder="Please enter first name"
									data-testid="fname"
								/>
							</Form.Item>
						</Row>
						<Row justify="space-between">
							<p className="Key">Last Name</p>
							<Form.Item
								required={true}
								style={{ margin: 0, marginBottom: 6 }}
								name="lastName"
							>
								<Input
									style={{ textAlign: "end" }}
									placeholder="Please enter last name"
									data-testid="lname"
								/>
							</Form.Item>
						</Row>
						<Row justify="space-between">
							<p className="Key">Email</p>
							<Form.Item
								required={true}
								style={{ margin: 0, marginBottom: 6 }}
								name="email"
							>
								<Input
									style={{ textAlign: "end" }}
									placeholder="Please enter email"
									data-testid="email"
								/>
							</Form.Item>
						</Row>
					</Col>
					<Col style={{ paddingLeft: 12, paddingRight: 12 }} span={isMobile ? 24 : 12}>
						<Row justify="space-between">
							<p className="Key">Last Online</p>
							<p className="Value" data-testid="lastSignIn">
								{userInfo.lastSignIn !== "never"
									? moment(userInfo.lastSignIn).fromNow()
									: "Never"}
							</p>
						</Row>
					</Col>
				</Row>
			</Form>
		</>
	);
};

export default EditDetails;
