import React, { useContext } from "react";
import { Row, Spin } from "antd";
import { Route, Redirect } from "react-router-dom";
import UserContext from "../../Context/UserContext";
import { colorsLight } from "../../Styles/Themes";

const PrivateRoute = ({ children, ...rest }) => {
	const { authd, loading } = useContext(UserContext);

	if (!authd && !loading) {
		return <Redirect to="/login" />;
	}

	return !loading ? (
		<Route {...rest} render={() => children} />
	) : (
		<Row style={styles.wrapper} align="middle" justify="center" data-testid="loading">
			<Spin size="large" />
		</Row>
	);
};

const styles = {
	wrapper: {
		height: "100vh",
		width: "100vw",
		backgroundColor: colorsLight("primary"),
	},
};

export default PrivateRoute;
