import { Col, Layout, Row, Typography } from "antd";
import React, { useContext } from "react";
import HeaderMain from "../../Components/Headers/Header";
import ActionPanel from "../../Components/Home/ActionPanel";
import FacilitatorPanel from "../../Components/Home/FacilitatorPanel";
import MyAccountBalancePanel from "../../Components/Home/MyAccountBalance";
import UpcomingEvents from "../../Components/Home/UpcomingEvents";
import UserContext from "../../Context/UserContext";
import ViewContext from "../../Context/ViewContext";

const { Content } = Layout;

const Home = () => {
	const { isIpad, isMobile, isLaptop, isDesktop } = useContext(ViewContext);
	const { userInfo } = useContext(UserContext);

	return (
		<Layout style={{ overflow: "hidden" }} className={isMobile ? "layout" : "App"}>
			<HeaderMain active="1" />
			<Content
				style={{
					overflow: "scroll",
					padding: isMobile ? 12 : 24,
					paddingBottom: isMobile ? 42 : 24,
					paddingTop: isMobile ? 83 : 24,
				}}
				className="content"
			>
				<Typography.Title level={1} data-testid="home-user">
					{`Welcome, ${userInfo.firstName}!`}
				</Typography.Title>
				{(isLaptop || isDesktop) && (
					<div data-testid="home-laptopView">
						<Row style={{ paddingTop: 12 }}>
							<Col span={16}>
								<ActionPanel />
							</Col>
							<Col style={{ paddingLeft: 24 }} span={8}>
								<FacilitatorPanel />
							</Col>
						</Row>
						<Row style={{ marginTop: 0 }}>
							{/* <Col style={{ paddingRight: 12 }} span={8}>
								<UpcomingEvents />
							</Col> */}
							<Col style={{ paddingLeft: 12 }} span={8}>
								<MyAccountBalancePanel />
							</Col>
						</Row>
					</div>
				)}
				{isIpad && (
					<div data-testid="home-ipadView">
						<Row style={{ paddingTop: 12 }}>
							<Col span={24}>
								<ActionPanel />
							</Col>
							{/* <Col style={{ paddingLeft: 24 }} span={8}>
										<FacilitatorPanel />
									</Col> */}
						</Row>
						<Row style={{ marginTop: 24 }}>
							{/* <Col style={{ paddingRight: 12 }} span={12}>
								<UpcomingEvents />
							</Col> */}
							<Col style={{ paddingLeft: 12 }} span={12}>
								<MyAccountBalancePanel />
							</Col>
						</Row>
					</div>
				)}
				{isMobile && (
					<Col style={{ overflow: "scroll" }} span={24} data-testid="home-mobileView">
						<Row style={{ paddingTop: 12 }}>
							<Col span={24}>
								<ActionPanel />
							</Col>
							{/* <Col style={{ paddingLeft: 24 }} span={8}>
										<FacilitatorPanel />
									</Col> */}
						</Row>
						<Row style={{ marginTop: 24 }}>
							{/* <Col span={24}>
								<UpcomingEvents />
							</Col> */}
						</Row>
						<Row style={{ marginTop: 24 }}>
							<Col span={24}>
								<MyAccountBalancePanel />
							</Col>
						</Row>
					</Col>
				)}
			</Content>
		</Layout>
	);
};

export default Home;
