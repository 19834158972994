import { Col, Layout } from "antd";
import React, { useContext } from "react";
import HeaderMain from "../../Components/Headers/Header";
import ViewContext from "../../Context/ViewContext";

import { colorsLight } from "../../Styles/Themes";
const { Content } = Layout;

const ServicesBrowse = () => {
	const { isMobile } = useContext(ViewContext);
	return (
		<Layout className={isMobile ? "layout" : "App"}>
			<HeaderMain active="6" />
			<Content className="content">
				<Col
					style={{ backgroundColor: colorsLight("lightgrey"), height: "100%" }}
					span={24}
				></Col>
			</Content>
		</Layout>
	);
};

export default ServicesBrowse;
