import { Col, Layout, message, Row, Typography } from "antd";
import { firestore } from "firebase";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import ProviderMainPanel from "../../Components/Common/ProviderHeader";
import HeaderMain from "../../Components/Headers/Header";
import FacilitatorPanel from "../../Components/Home/FacilitatorPanel";
import BackButton from "../../Components/Navigation/BackButton";
import CourseCardLite from "../../Components/Store/Cards/CourseCardLite";
import ViewContext from "../../Context/ViewContext";
import { colorsLight, maxContentWidth } from "../../Styles/Themes";

const { Content } = Layout;

const ViewCourseProviderPage = () => {
	const { isMobile } = useContext(ViewContext);
	const { providerId } = useParams();
	const history = useHistory();
	const [coursesData, setCoursesData] = useState([]);
	const [providerData, setProviderData] = useState({});
	const scrollRef = React.createRef();

	useEffect(() => {
		scrollRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		firestore()
			.collection("Courses")
			.where("owner", "==", providerId)
			.get()
			.then((snap) => {
				let list = [];
				snap.forEach((doc) => {
					list.push({ ...doc.data(), key: doc.id });
				});
				setCoursesData(list);
			})
			.catch((err) => message.error(err));

		firestore()
			.collection("CourseProviders")
			.doc(providerId)
			.get()
			.then((doc) => doc.exists && setProviderData({ ...doc.data(), key: doc.id }))
			.catch((err) => message.error(err));
	}, [providerId]);

	const styles = {
		itemRow: {
			flexDirection: "row",
			overflowY: "hidden",
			overflowX: "scroll",
			display: "flex",
			marginBottom: 24,
			width: "100%",
		},
	};

	return (
		<Layout className={isMobile ? "layout" : "App"}>
			<HeaderMain />
			<Content
				style={{
					overflow: "scroll",
					padding: isMobile ? "0px 12px 120px 12px" : "12px 24px 24px 24px",
				}}
				className="content"
			>
				<Row justify="center">
					<Col
						style={{
							backgroundColor: colorsLight("lightgrey"),
							height: "100%",
							maxWidth: isMobile ? null : maxContentWidth,
						}}
						span={24}
					>
						<Row
							ref={scrollRef}
							style={{ height: isMobile ? 66 : 0, width: "100%" }}
						></Row>
						<Row
							justify="start"
							align="bottom"
							style={{ width: "100%", marginBottom: 6 }}
						>
							<BackButton marginLeft={0.1} history={history} />
						</Row>

						<Row gutter={24}>
							<Col span={isMobile ? 24 : 16}>
								<ProviderMainPanel data={providerData} />
							</Col>
							<Col span={isMobile ? 24 : 8}>
								<FacilitatorPanel />
							</Col>
						</Row>
						<Row style={{ marginTop: 12 }}>
							{coursesData.length > 0 && (
								<>
									<Row style={{ width: "100%" }}>
										<Typography.Title level={3}>
											Most popular courses
										</Typography.Title>
										<Link
											style={{ marginTop: 8, marginLeft: 12 }}
											to={`/view-provider-courses/${providerId}`}
										>
											See more
										</Link>
									</Row>

									{!isMobile ? (
										<Row
											style={{ width: "100%", overflow: "scroll" }}
											gutter={24}
										>
											{coursesData.map((item, index) => (
												<Col key={index} span={8}>
													<CourseCardLite data={item} />
												</Col>
											))}
										</Row>
									) : (
										<Row gutter={24} style={{ width: "100%" }}>
											<div style={styles.itemRow}>
												{coursesData.map((item, index) => (
													<Col key={index} span={24}>
														<CourseCardLite data={item} />
													</Col>
												))}
											</div>
										</Row>
									)}
								</>
							)}
						</Row>
					</Col>
				</Row>
			</Content>
		</Layout>
	);
};

export default ViewCourseProviderPage;
