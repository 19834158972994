import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Layout, List, Row, Select, Typography } from "antd";
import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import HeaderMain from "../../Components/Headers/Header";
import BackButton from "../../Components/Navigation/BackButton";
import CoachCard from "../../Components/Store/Cards/CoachPackage";
import DataContext from "../../Context/DataContext";
import ViewContext from "../../Context/ViewContext";
import { filterData } from "../../Functions/utilities";
import { maxContentWidth } from "../../Styles/Themes";

const { Content } = Layout;

const coachAttributes = [
	{ label: "Type", ref: "primaryCategory", filterable: true },
	{ label: "Delivery", ref: "delivery", filterable: true },
	{ label: "Sessions", ref: "sessions", filterable: true },
];

const SearchCoachingPackagesPage = () => {
	const [items, setItems] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const history = useHistory();
	const [attributes, setAttributes] = useState({});
	const [filters, setFilters] = useState({});
	const [formFilters] = Form.useForm();
	const { isMobile } = useContext(ViewContext);
	const { packages } = useContext(DataContext);
	const [expFilters, setExpFilters] = useState(false);
	const scrollRef = React.createRef();

	const scrollUp = () => scrollRef.current.scrollIntoView({ behavior: "smooth", top: 0 });

	useEffect(() => {
		setItems(packages);
		setFilteredData(packages);
	}, [packages]);

	useEffect(() => {
		if (items.length > 0) {
			let obj = {};
			let stateObj = { price: "" };
			coachAttributes.forEach((item) => {
				const ref = item.ref;
				let list = [];

				items.forEach((item) => {
					if (
						item[ref] &&
						!list
							.map((i) => i.toString().toLowerCase())
							.includes(item[ref].toString().toLowerCase())
					) {
						item[ref] && list.push(item[ref]);
					}
				});
				obj[item.ref] = list;
				stateObj[item.ref] = "";
			});
			setAttributes(obj);
			setFilters(stateObj);
		}
	}, [items]);

	const handleFilterState = (ref, val) => {
		let newState = filters;
		newState[ref] = val;
		setFilters(newState);
	};

	const applyFilters = () => {
		let res = filterData(items, filters);
		setFilteredData(res);
		setExpFilters(!expFilters);
	};

	const resetFilters = () => {
		let stateObj = { price: "" };
		coachAttributes.forEach((item) => {
			stateObj[item.ref] = "";
		});
		setFilters(stateObj);
		formFilters.resetFields();
		let filtrd = filterData(items, stateObj);
		setFilteredData(filtrd);
	};

	const mobileAnimVariants = {
		open: {
			height: null,
			opacity: 1,
		},
		closed: {
			height: 0,
			opacity: 0,
		},
	};

	const main = {
		padding: isMobile ? "0px 12px 0px 12px" : "12px 24px 24px 24px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		overflowY: "scroll",
		overflowX: "-moz-hidden-unscrollable",
	};

	return (
		<Layout className={isMobile ? "layout" : "App"}>
			<HeaderMain />

			<Content style={main} className="content">
				<Col style={{ maxWidth: maxContentWidth }} span={24}>
					<Row ref={scrollRef} style={{ width: "100%", height: isMobile ? 64 : 0 }} />
					<Row>
						<BackButton marginLeft={0.1} history={history} />
					</Row>
					<Row style={{ marginTop: 12 }}>
						<Typography.Title data-testid="course-name">
							Coaching Packages
						</Typography.Title>
					</Row>
					{/* ** DESKTOP VERSION ** */}{" "}
					{!isMobile ? (
						<Row className="Card">
							<Col span={24}>
								<Row>
									<Typography.Title level={4}>Filter courses</Typography.Title>
								</Row>
								<Divider style={{ margin: "0px 0px 6px 0px" }} />
								<Row>
									<Col flex="auto">
										<Form style={{ height: 60 }} form={formFilters}>
											<Row>
												{coachAttributes.map((item, index) => (
													<Col
														style={{ paddingRight: 12 }}
														flex="auto"
														key={index}
													>
														<p className="Key">{item.label}</p>
														<Form.Item name={item.ref}>
															<Select
																allowClear={true}
																onChange={(val) =>
																	handleFilterState(item.ref, val)
																}
																placeholder={`Filter by ${item.label.toLowerCase()}`}
																style={{
																	width: "100%",
																}}
															>
																{attributes[item.ref] &&
																	attributes[item.ref].map(
																		(item, index) => (
																			<Select.Option
																				key={index}
																				value={item}
																			>
																				{item}
																			</Select.Option>
																		)
																	)}
															</Select>
														</Form.Item>
													</Col>
												))}
												<Col style={{ paddingRight: 12 }} flex="auto">
													<p className="Key">Price</p>
													<Form.Item name="price">
														<Select
															onChange={(val) =>
																handleFilterState("price", val)
															}
															placeholder="Sort by price"
															style={{
																width: "100%",
															}}
														>
															<Select.Option key="1" value="desc">
																High to low
															</Select.Option>
															<Select.Option key="2" value="asc">
																Low to high
															</Select.Option>
														</Select>
													</Form.Item>
												</Col>
											</Row>
										</Form>
									</Col>
									<Col flex="164px">
										<Row
											style={{ height: "100%", width: "100%" }}
											justify="end"
											align="bottom"
										>
											<Button
												onClick={() => resetFilters()}
												data-testid="course-reset"
											>
												Reset
											</Button>
											<Button
												onClick={() => applyFilters()}
												type="primary"
												style={{ marginLeft: 12 }}
												data-testid="course-filter"
											>
												Apply
											</Button>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					) : (
						<Row className="Card">
							{/* ** MOBILE VERSION ** */}
							<Col span={24}>
								<Row justify="space-between" align="middle">
									<Typography.Title style={{ margin: 0 }} level={4}>
										Filter courses
									</Typography.Title>
									{expFilters ? (
										<UpOutlined
											style={{ fontSize: 18 }}
											onClick={() => setExpFilters(!expFilters)}
										/>
									) : (
										<DownOutlined
											style={{ fontSize: 18 }}
											onClick={() => setExpFilters(!expFilters)}
										/>
									)}
								</Row>

								<motion.div
									style={{ overflow: "hidden", height: 0 }}
									animate={expFilters ? "open" : "closed"}
									variants={mobileAnimVariants}
								>
									<Divider style={{ marginTop: 12, marginBottom: 12 }} />
									<Col flex="auto">
										<Form form={formFilters}>
											<Row>
												{coachAttributes.map((item, index) => (
													<Col
														style={{ paddingRight: 12 }}
														span={12}
														key={index}
													>
														<p className="Key">{item.label}</p>
														<Form.Item name={item.ref}>
															<Select
																onChange={(val) =>
																	handleFilterState(item.ref, val)
																}
																placeholder={`Filter by ${item.label.toLowerCase()}`}
																style={{
																	width: "100%",
																}}
															>
																{attributes[item.ref] &&
																	attributes[item.ref].map(
																		(item, index) => (
																			<Select.Option
																				key={index}
																				value={item}
																			>
																				{item}
																			</Select.Option>
																		)
																	)}
															</Select>
														</Form.Item>
													</Col>
												))}
												<Col style={{ paddingRight: 12 }} span={12}>
													<p className="Key">Price</p>
													<Form.Item name="price">
														<Select
															onChange={(val) =>
																handleFilterState("price", val)
															}
															placeholder="Sort by price"
															style={{
																width: "100%",
															}}
														>
															<Select.Option key="1" value="desc">
																High to low
															</Select.Option>
															<Select.Option key="2" value="asc">
																Low to high
															</Select.Option>
														</Select>
													</Form.Item>
												</Col>
											</Row>
										</Form>
									</Col>
									<Col span={24}>
										<Row
											style={{ height: "100%", width: "100%" }}
											justify="end"
											align="bottom"
										>
											<Button
												onClick={() => resetFilters()}
												data-testid="course-reset"
											>
												Reset
											</Button>
											<Button
												onClick={() => applyFilters()}
												type="primary"
												style={{ marginLeft: 12 }}
												data-testid="course-filter"
											>
												Apply
											</Button>
										</Row>
									</Col>
								</motion.div>
							</Col>
						</Row>
					)}
					<Row style={{ width: "100%", overflow: "visible" }}>
						<List
							pagination={
								filteredData.length > 0
									? {
											pageSize: 9,
											onChange: scrollUp,
									  }
									: false
							}
							style={{ width: "100%", height: "100%", overflow: "visible" }}
							grid={{ column: isMobile ? 1 : 3, gutter: 24 }}
							dataSource={filteredData}
							renderItem={(item, index) => (
								<Col style={{ marginBottom: 24 }} key={index}>
									<CoachCard width="free" data={item} />
								</Col>
							)}
							data-testid="course-list"
						/>
					</Row>
					<Row style={{ height: isMobile ? 44 : 24 }} />
				</Col>
			</Content>
		</Layout>
	);
};

export default SearchCoachingPackagesPage;
