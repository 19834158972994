import moment from "moment";

export const iDgen = (length) => {
	var result = "";
	var characters = "abcdefghijklmnopqrstuv0123456789";
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

export const getImage = () => {
	let num = Math.floor(Math.random() * 2);
	let images = [
		"https://firebasestorage.googleapis.com/v0/b/careerhub-a50a2.appspot.com/o/defaultImages%2Fdefault1.jpg?alt=media&token=28a1fbc4-00e5-4ed3-9c75-de577aa65662",
		"https://firebasestorage.googleapis.com/v0/b/careerhub-a50a2.appspot.com/o/defaultImages%2Fdefault2.jpg?alt=media&token=ca80f5c7-515f-4bbc-9f42-e166281a433a",
	];
	return images[num];
};

export const sortSessions = (sessions) => {
	let completed = [];
	let booked = [];
	let unbooked = [];
	let now = moment().format("YYYYMMDDHHmm");
	sessions &&
		sessions.forEach((session, index) => {
			if (session.startTime === "TBD") {
				unbooked.push({ ...session, index: index });
			} else if (
				now > moment(session.endTime).format("YYYYMMDDHHmm") &&
				session.startTime !== "TBD"
			) {
				completed.push({ ...session, index: index });
			} else {
				booked.push({ ...session, index: index });
			}
		});
	return { completed, booked, unbooked };
};

export const filterData = (data, filters) => {
	//Cleans the object and gets it ready for filtering
	const filterKeys = Object.keys(filters);
	let activeFilters = {};
	let sort = { active: false };
	filterKeys.forEach((key) => {
		if (filters[key] === "asc" || filters[key] === "desc") {
			sort.active = true;
			sort.key = key;
			sort.direction = filters[key];
		} else if (filters[key]) {
			activeFilters[key] = filters[key];
		}
	});

	//Array of keys that are to be filtered
	const activeKeys = Object.keys(activeFilters);

	//Takes raw data, and filters it for each attribute
	let filtered = data;

	//Makes sure all items have attribute present, adds if absent - will prevent bad things from happening....
	filtered.forEach((item, index) => {
		let keysToAdd = [];
		activeKeys.forEach((key) => {
			if (!item[key]) {
				keysToAdd.push(key);
			}
		});
		keysToAdd.forEach((key) => {
			filtered[index][key] = "";
		});
	});

	activeKeys.forEach((key) => {
		const attribute = activeFilters[key];
		const cleaned = filtered.filter(
			(obj) => obj[key].toString().toLowerCase() === attribute.toString().toLowerCase()
		);
		filtered = cleaned;
	});

	//Check if we need to sort the data by an attribute
	if (sort.active) {
		if (sort.direction === "asc") {
			let sorted = filtered.slice().sort((a, b) => a.price - b.price);
			return sorted;
		} else {
			let sorted = filtered.slice().sort((a, b) => b.price - a.price);
			return sorted;
		}
	} else {
		return filtered;
	}
};

export const getNextSession = (sessions) => {
	let list = sessions.sort((a, b) =>
		moment(a.startTime).format("YYYYMMDDHHmm") > moment(a.endTime).format("YYYYMMDDHHmm")
			? 1
			: -1
	);
	if (list[0]) {
		return moment(list[0].startTime).format("dddd Do MMMM @ h:mma");
	} else {
		return "No upcoming sessions";
	}
};
