import { Avatar, Col, Row, Skeleton } from "antd";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ViewContext from "../../../Context/ViewContext";
import { getInitials } from "../../../Functions/formatters";
import { getImage } from "../../../Functions/utilities";

const CourseProviderCard = ({ data, loading, link }) => {
	const { isMobile } = useContext(ViewContext);
	const styles = {
		headerImage: {
			backgroundImage: data ? `url(${data.coverPic ? data.coverPic : getImage()})` : null,
			backgroundSize: "cover",
			height: 150,
			borderRadius: 9,
			margin: "0px 24px 24px 10px",
			width: 380,
			cursor: "pointer",
		},
		name: {
			fontSize: 18,
			color: "white",
			fontWeight: "bold",
			lineHeight: 1.5,
		},
		imageOverlay: {
			backgroundColor: loading ? "rgb(216 216 216)" : "rgba(2, 48, 71,0.6)",
			height: "100%",
			width: "100%",
			borderRadius: 9,
			margin: 0,
		},
	};

	const mobStyles = {
		headerImage: {
			backgroundImage: data ? `url(${data.coverPic ? data.coverPic : getImage()})` : null,
			backgroundSize: "cover",
			height: 150,
			borderRadius: 9,
			margin: "0px 12px 12px 10px",
			width: 330,
			cursor: "pointer",
		},
		name: {
			fontSize: 18,
			color: "white",
			fontWeight: "bold",
			lineHeight: 1.5,
		},
		imageOverlay: {
			backgroundColor: loading ? "rgb(216 216 216)" : "rgba(2, 48, 71,0.6)",
			height: "100%",
			width: "100%",
			borderRadius: 9,
			margin: 0,
		},
	};

	return (
		<Link to={loading ? "#" : link}>
			<Row
				style={isMobile ? mobStyles.headerImage : styles.headerImage}
				data-testid="membership-header"
			>
				<div
					style={isMobile ? mobStyles.imageOverlay : styles.imageOverlay}
					data-testid="membership-overlay"
				>
					<Row
						justify="space-between"
						align="middle"
						style={{ height: "100%", width: "100%", padding: "0px 12px" }}
					>
						<Col
							span={16}
							style={isMobile ? mobStyles.name : styles.name}
							data-testid="membership-name"
						>
							{loading ? (
								<Skeleton active paragraph={false} title={{ width: "80%" }} />
							) : (
								<>{data.name || "Loading"}</>
							)}
						</Col>
						<Col span={8}>
							<Row justify="end" align="middle">
								<Row
									style={{
										padding: 4,
										backgroundColor: "white",
										boxShadow: "1px 1px 6px rgba(0,0,0,0.2)",
										borderRadius: 62,
										marginTop: 0,
									}}
								>
									{loading ? (
										<Skeleton.Avatar active size={90} shape="circle" />
									) : (
										<Avatar
											src={data.displayPic}
											size={90}
											data-testid="membership-provider"
										>
											{getInitials(data.name || "")}
										</Avatar>
									)}
								</Row>
							</Row>
						</Col>
					</Row>
				</div>
			</Row>
		</Link>
	);
};

export default CourseProviderCard;
