import { Col, Layout, Row, Typography } from "antd";
import React, { useContext, useState } from "react";
import HeaderMain from "../../Components/Headers/Header";
import CalendarSync from "../../Components/MyAccount/CalendarSync";
import AccountDetails from "../../Components/MyAccount/AccountDetails";
import EditDetails from "../../Components/MyAccount/EditDetails";
import ViewContext from "../../Context/ViewContext";
import { colorsLight } from "../../Styles/Themes";

const { Content } = Layout;

const MyAccountPage = () => {
	const { isMobile, isIpad } = useContext(ViewContext);
	const [editing, setEditing] = useState(false);
	const [editPic, setEditPic] = useState(false);

	const toggleEdit = () => setEditing(!editing);
	const toggleEditPic = () => setEditPic(!editPic);

	return (
		<Layout className={isMobile ? "layout" : "App"}>
			<HeaderMain active="2" />
			<Content
				style={{ overflowY: "scroll", paddingBottom: 42, paddingTop: isMobile ? 54 : 0 }}
				className="content"
			>
				<Col style={styles.main}>
					<Row>
						<Col span={isMobile || isIpad ? 24 : 12} className="Card">
							{editing ? (
								<EditDetails
									toggleEdit={toggleEdit}
									toggleEditPic={toggleEditPic}
									editPic={editPic}
								/>
							) : (
								<AccountDetails toggleEdit={toggleEdit} />
							)}
							<Row>
								<Typography.Title level={4}>Integrations</Typography.Title>
							</Row>
							<CalendarSync />
						</Col>
					</Row>
				</Col>
			</Content>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100%",
		backgroundColor: colorsLight("lightgrey"),
		padding: 24,
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
};

export default MyAccountPage;
