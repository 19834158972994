import { Col, Layout, Row, Tabs, Typography } from "antd";
import { firestore } from "firebase";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HeaderMain from "../../Components/Headers/Header";
import UpcomingEvents from "../../Components/Home/UpcomingEvents";
import ProgramCard from "../../Components/MyProgram/ProgramCard";
import DataContext from "../../Context/DataContext";
import UserContext from "../../Context/UserContext";
import ViewContext from "../../Context/ViewContext";
import { colorsLight, maxContentWidth } from "../../Styles/Themes";

const { Content } = Layout;

const MyProgram = () => {
	const { isMobile, isIpad } = useContext(ViewContext);
	const { programs, setPrograms } = useContext(DataContext);
	const { userInfo } = useContext(UserContext);
	const [loading, setLoading] = useState(false);
	const scrollRef = React.createRef();

	useEffect(() => {
		scrollRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (userInfo.key) {
			setLoading(true);
			let listener = firestore()
				.collection("Programs")
				.where("member", "==", userInfo.key)
				.onSnapshot((docs) => {
					let list = [];
					docs.forEach((doc) => {
						list.push({ ...doc.data(), key: doc.id });
					});
					setPrograms(list);
					setLoading(false);
				});

			return () => {
				listener();
			};
		}
		// eslint-disable-next-line
	}, [userInfo]);

	return (
		<Layout className={isMobile ? "layout" : "App"}>
			<HeaderMain active="2" />
			<Content
				style={{
					overflowY: "scroll",
					padding: isMobile ? 12 : 24,
					paddingBottom: isMobile ? 42 : 24,
					paddingTop: isMobile ? 83 : 24,
				}}
				className="content"
			>
				<Row justify="center" ref={scrollRef}>
					<Col
						style={{
							backgroundColor: colorsLight("lightgrey"),
							height: "100%",
							maxWidth: maxContentWidth,
						}}
						span={24}
					>
						<Typography.Title level={1} data-testid="programs-title">
							My Program
						</Typography.Title>
						<Row gutter={24}>
							<Col span={!isIpad && !isMobile ? 16 : 24}>
								<Tabs>
									<Tabs.TabPane tab="All Items" key="1">
										{programs.map((item) => (
											<ProgramCard data={item} />
										))}
									</Tabs.TabPane>
									<Tabs.TabPane tab="Coaches" key="2">
										{programs.length > 0 && (
											<>
												{programs
													.filter((item) => item.type === "Packages")
													.map((item) => (
														<ProgramCard data={item} />
													))}
											</>
										)}
									</Tabs.TabPane>
									<Tabs.TabPane tab="Courses" key="3">
										{programs.length > 0 && (
											<>
												{programs
													.filter((item) => item.type === "Courses")
													.map((item) => (
														<ProgramCard data={item} />
													))}
											</>
										)}
									</Tabs.TabPane>
									<Tabs.TabPane tab="Services" key="4">
										{programs.length > 0 && (
											<>
												{programs
													.filter((item) => item.type === "Services")
													.map((item) => (
														<ProgramCard data={item} />
													))}
											</>
										)}
									</Tabs.TabPane>
								</Tabs>
								{programs.length === 0 && !loading && (
									<Row style={{ paddingTop: 12 }}>
										<p style={{ fontSize: 18, color: "grey" }}>
											Your program is looking a little bare.{" "}
											<Link to="/Featured">Browse the store</Link> to find
											items to add.
										</p>
									</Row>
								)}
							</Col>
							{/* {!isIpad && !isMobile && (
								<Col style={{ marginTop: 60 }} span={8}>
									<UpcomingEvents amount={10} />
								</Col>
							)} */}
						</Row>
					</Col>
				</Row>
			</Content>
		</Layout>
	);
};

export default MyProgram;
