import { Avatar, Col, Divider, Row, Skeleton, Typography, Steps } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import ViewContext from "../../Context/ViewContext";
import { getInitials, priceFormatter } from "../../Functions/formatters";
import { getImage } from "../../Functions/utilities";
import { colorsLight } from "../../Styles/Themes";

const { Title } = Typography;
const { Step } = Steps;

const CoachDetails = ({ data = {} }) => {
	const { isMobile } = useContext(ViewContext);

	const styles = {
		wrapper: {
			borderRadius: 9,
			backgroundColor: colorsLight("white"),
		},
		header: {
			width: "100%",
		},
		coverPhoto: {
			backgroundImage: `url(${data.coverPic ? data.coverPic : getImage()})`,
			backgroundSize: "cover",
			minHeight: 140,
			width: "100%",
			borderRadius: "9px 9px 0px 0px",
			overflow: "hidden",
		},
		name: {
			fontWeight: 500,
			fontSize: 26,
			color: colorsLight("black"),
			marginBottom: 0,
			lineHeight: 1.3,
		},
		coachType: {
			fontSize: 14,
			color: "#5c5c5c",
			marginBottom: 5,
		},
		avatar: {
			padding: 4,
			backgroundColor: "white",
			boxShadow: "1px 1px 6px rgba(0,0,0,0.2)",
			borderRadius: "50%",
			margin: "0px 0px 10px 0px",
			maxWidth: isMobile ? 103 : 145,
			height: isMobile ? 103 : 145,
			position: "absolute",
			top: isMobile ? "-60px" : "-90px",
		},
		coachHeader: {
			width: "100%",
			borderRadius: "9px 9px 0px 0px",
			margin: 0,
			padding: "0px 20px",
			position: "relative",
		},
		title: {
			fontWeight: "bold",
			fontSize: 22,
			width: "100%",
			color: colorsLight("black"),
		},
		description: {
			fontSize: 14,
			margin: "0px 0px 0px 10px",
			color: colorsLight("black"),
		},
		video: {
			marginTop: 10,
			width: "100%",
		},
		row: {
			padding: "20px 30px",
			width: "100%",
		},
		packageName: {
			color: "#ffffff",
			margin: isMobile ? "60px 10px 10px 135px" : "60px 10px 10px 180px",
			wordBreak: "break-word",
		},
		infoRow: {
			width: "100%",
		},
		info: {
			display: "flex",
			justifyContent: "space-between",
			padding: "5px 10px",
		},
		label: {
			fontWeight: "bold",
			fontSize: 14,
		},
		text: {
			fontSize: 14,
		},
		bgCover: {
			backgroundColor: colorsLight("cardCover"),
			height: "100%",
			width: "100%",
		},
	};

	const getStatus = () => {
		if (data.status) {
			switch (data.status) {
				case "Processing":
					return 2;
				case "Booked":
					return 3;
				case "Completed":
					return 4;
				default:
					return 1;
			}
		} else {
			return 1;
		}
	};

	return (
		<Row style={styles.wrapper}>
			<Row style={styles.header}>
				<Row
					style={styles.coverPhoto}
					justify="space-between"
					align="middle"
					data-testid="program-coverPhoto"
				>
					<Row justify="space-between" align="middle" style={styles.bgCover}>
						{data.name ? (
							<Typography.Title style={styles.packageName} level={2}>
								{data.name}
							</Typography.Title>
						) : (
							<Skeleton active paragraph={false} title={{ width: "50%" }} />
						)}
					</Row>
				</Row>
				<Row justify="space-between" align="bottom" style={styles.coachHeader}>
					<Col style={styles.avatar}>
						{data.displayPic ? (
							<Avatar
								style={{ margin: 2 }}
								src={data.displayPic}
								size={isMobile ? 90 : 132}
								data-testid="program-avatar"
							>
								{getInitials(data.name || "")}
							</Avatar>
						) : (
							<Skeleton.Avatar
								active
								size={isMobile ? 90 : 132}
								style={{ margin: 2 }}
							/>
						)}
					</Col>
					<Col
						style={{ margin: isMobile ? "6px 0px 0px 115px" : "6px 0px 0px 160px" }}
						flex="auto"
					>
						{data.coachName && data.coachTitle ? (
							<>
								<p style={styles.name} data-testid="program-coachName">
									{data.coachName}
								</p>
								<p style={styles.coachType} data-testid="program-coachType">
									{data.coachTitle}
								</p>
							</>
						) : (
							<Skeleton
								active
								paragraph={{ rows: 1, width: "50%" }}
								title={{ width: "30%" }}
							/>
						)}
					</Col>
				</Row>
			</Row>
			<Divider style={{ margin: "10px 0px 0px" }} />
			<Row style={styles.row}>
				<Title style={styles.title}>Status</Title>
				<Steps current={getStatus()} data-testid="program-status" responsive>
					<Step title="Ordered" />
					<Step title="Processing" />
					<Step title="Booked" />
					<Step title="Complete" />
				</Steps>
			</Row>
			<Divider style={{ margin: "10px 0px 0px" }} />
			<Row style={styles.row}>
				<Title style={styles.title}>Key Info</Title>
				<Row style={styles.infoRow}>
					<Col sm={12} xs={24} style={styles.info}>
						<div style={styles.label}>Sessions:</div>
						<div style={styles.text} data-testid="program-sessions">
							{data.sessions ? (
								data.sessions
							) : (
								<Skeleton
									active
									paragraph={false}
									title={{ width: 100, style: { margin: 0 } }}
								/>
							)}
						</div>
					</Col>
					<Col sm={12} xs={24} style={styles.info}>
						<div style={styles.label}>Booking Date:</div>
						<div style={styles.text} data-testid="program-bookingDate">
							{data.created ? (
								moment(data.created).format("D/M/YYYY")
							) : (
								<Skeleton
									active
									paragraph={false}
									title={{ width: 100, style: { margin: 0 } }}
								/>
							)}
						</div>
					</Col>
					<Col sm={12} xs={24} style={styles.info}>
						<div style={styles.label}>Delivery:</div>
						<div style={styles.text} data-testid="program-delivery">
							{data.delivery ? (
								data.delivery
							) : (
								<Skeleton
									active
									paragraph={false}
									title={{ width: 100, style: { margin: 0 } }}
								/>
							)}
						</div>
					</Col>
					<Col sm={12} xs={24} style={styles.info}>
						<div style={styles.label}>Booked By:</div>
						<div style={styles.text} data-testid="program-bookedBy">
							{data.initiatorName ? (
								data.initiatorName
							) : (
								<Skeleton
									active
									paragraph={false}
									title={{ width: 100, style: { margin: 0 } }}
								/>
							)}
						</div>
					</Col>
					<Col sm={12} xs={24} style={styles.info}>
						<div style={styles.label}>Price:</div>
						<div style={styles.text} data-testid="program-price">
							{data.price ? (
								priceFormatter(data.price, "points") + " pts"
							) : (
								<Skeleton
									active
									paragraph={false}
									title={{ width: 100, style: { margin: 0 } }}
								/>
							)}
						</div>
					</Col>
				</Row>
			</Row>
			{/* <Divider style={{ margin: "10px 0px 0px" }} />
			<Row style={styles.row}>
				<Title style={styles.title}>Program Notes</Title>
				<Paragraph style={styles.description} data-testid="program-notes">
					{data.notes}
				</Paragraph>
			</Row> */}
			<Divider style={{ margin: "10px 0px 0px" }} />
			<Row style={styles.row}>
				<Title style={styles.title}>Program Details</Title>
				{data.details ? (
					<div dangerouslySetInnerHTML={{ __html: data.details }} />
				) : (
					<Skeleton active paragraph={{ rows: 3 }} title={false} />
				)}
			</Row>
		</Row>
	);
};

export default CoachDetails;
