import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Layout, List, message, Row, Select, Typography } from "antd";
import { firestore } from "firebase";
import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import HeaderMain from "../../Components/Headers/Header";
import BackButton from "../../Components/Navigation/BackButton";
import CourseCardLite from "../../Components/Store/Cards/CourseCardLite";
import DataContext from "../../Context/DataContext";
import ViewContext from "../../Context/ViewContext";
import { filterData } from "../../Functions/utilities";
import { maxContentWidth } from "../../Styles/Themes";

const { Content } = Layout;

const ViewProviderCourses = () => {
	const [data, setData] = useState({});
	const [items, setItems] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const { providerId } = useParams();
	const history = useHistory();
	const [attributes, setAttributes] = useState({});
	const [filters, setFilters] = useState({});
	const [formFilters] = Form.useForm();
	const { isMobile } = useContext(ViewContext);
	const { courses } = useContext(DataContext);
	const [expFilters, setExpFilters] = useState(false);

	const [courseAttis, setCourseAttis] = useState([]);

	useEffect(() => {
		if (courses.length > 0) {
			firestore()
				.collection("CourseProviders")
				.doc(providerId)
				.get()
				.then((doc) => {
					doc.exists && setData({ ...doc.data(), key: doc.id });
				})
				.catch((err) => {
					message.error(err);
				});

			let cleanedCourses = courses.filter((course) => course.owner === providerId);
			setItems(cleanedCourses);
			setFilteredData(cleanedCourses);
		}
	}, [providerId, courses]);

	useEffect(() => {
		if (data.courseAttributes) {
			const cleanAttis = data.courseAttributes.filter((item) => item.filterable === true);
			setCourseAttis(cleanAttis);
		}
	}, [data]);

	useEffect(() => {
		if (items.length > 0 && courseAttis) {
			let obj = {};
			let stateObj = { price: "" };
			courseAttis.forEach((item) => {
				const ref = item.ref;
				let list = [];

				items.forEach((item) => {
					if (
						item[ref] &&
						!list
							.map((i) => i.toString().toLowerCase())
							.includes(item[ref].toString().toLowerCase())
					) {
						item[ref] && list.push(item[ref]);
					}
				});
				obj[item.ref] = list;
				stateObj[item.ref] = "";
			});
			setAttributes(obj);
			setFilters(stateObj);
		}
	}, [items, courseAttis]);

	const handleFilterState = (ref, val) => {
		let newState = filters;
		newState[ref] = val;
		setFilters(newState);
	};

	const applyFilters = () => {
		let res = filterData(items, filters);
		setFilteredData(res);
		setExpFilters(!expFilters);
	};

	useEffect(() => {
		console.log(filteredData);
	}, [filteredData]);

	const resetFilters = () => {
		let stateObj = { price: "" };
		courseAttis.forEach((item) => {
			stateObj[item.ref] = "";
		});
		setFilters(stateObj);
		formFilters.resetFields();
		let filtrd = filterData(items, stateObj);
		setFilteredData(filtrd);
	};

	const mobileAnimVariants = {
		open: {
			height: null,
			opacity: 1,
		},
		closed: {
			height: 0,
			opacity: 0,
		},
	};

	const main = {
		padding: isMobile ? "54px 12px 120px 12px" : "0px 24px 48px 24px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		overflowY: "scroll",
		overflowX: "-moz-hidden-unscrollable",
	};

	return (
		<Layout className={isMobile ? "layout" : "App"}>
			<HeaderMain />

			<Content style={main} className="content">
				<Col style={{ maxWidth: maxContentWidth, height: "100%" }} span={24}>
					<Row style={{ paddingTop: "12px" }}>
						<BackButton marginLeft={0.1} history={history} />
					</Row>
					<Row style={{ marginTop: 12 }}>
						<Typography.Title data-testid="course-name">
							{data.name || "Loading"}
						</Typography.Title>
					</Row>
					{/* ** DESKTOP VERSION ** */}{" "}
					{!isMobile ? (
						<Row className="Card">
							<Col span={24}>
								<Row>
									<Typography.Title level={4}>Filter courses</Typography.Title>
								</Row>
								<Divider style={{ margin: "0px 0px 6px 0px" }} />
								<Row>
									<Col flex="auto">
										<Form style={{ height: 60 }} form={formFilters}>
											<Row>
												{courseAttis.length > 0 && (
													<>
														{courseAttis.map((item, index) => (
															<Col
																style={{ paddingRight: 12 }}
																flex="auto"
																key={index}
															>
																<p className="Key">{item.label}</p>
																<Form.Item name={item.ref}>
																	<Select
																		allowClear={true}
																		onChange={(val) =>
																			handleFilterState(
																				item.ref,
																				val
																			)
																		}
																		placeholder={`Filter by ${item.label.toLowerCase()}`}
																		style={{
																			width: "100%",
																		}}
																	>
																		{attributes[item.ref] &&
																			attributes[
																				item.ref
																			].map((item, index) => (
																				<Select.Option
																					key={index}
																					value={item}
																				>
																					{item}
																				</Select.Option>
																			))}
																	</Select>
																</Form.Item>
															</Col>
														))}
														<Col
															style={{ paddingRight: 12 }}
															flex="auto"
														>
															<p className="Key">Price</p>
															<Form.Item name="price">
																<Select
																	onChange={(val) =>
																		handleFilterState(
																			"price",
																			val
																		)
																	}
																	placeholder="Sort by price"
																	style={{
																		width: "100%",
																	}}
																>
																	<Select.Option
																		key="1"
																		value="desc"
																	>
																		High to low
																	</Select.Option>
																	<Select.Option
																		key="2"
																		value="asc"
																	>
																		Low to high
																	</Select.Option>
																</Select>
															</Form.Item>
														</Col>
													</>
												)}
											</Row>
										</Form>
									</Col>
									<Col flex="auto">
										<Row
											style={{
												height: "100%",
												width: "100%",
												paddingTop: 10,
											}}
											justify="end"
											align="bottom"
										>
											<Button
												onClick={() => resetFilters()}
												data-testid="course-reset"
											>
												Reset
											</Button>
											<Button
												onClick={() => applyFilters()}
												type="primary"
												style={{ marginLeft: 12 }}
												data-testid="course-filter"
											>
												Apply
											</Button>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					) : (
						<Row className="Card">
							{/* ** MOBILE VERSION ** */}
							<Col span={24}>
								<Row justify="space-between" align="middle">
									<Typography.Title style={{ margin: 0 }} level={4}>
										Filter courses
									</Typography.Title>
									{expFilters ? (
										<UpOutlined
											style={{ fontSize: 18 }}
											onClick={() => setExpFilters(!expFilters)}
										/>
									) : (
										<DownOutlined
											style={{ fontSize: 18 }}
											onClick={() => setExpFilters(!expFilters)}
										/>
									)}
								</Row>

								<motion.div
									style={{ overflow: "hidden", height: 0 }}
									animate={expFilters ? "open" : "closed"}
									variants={mobileAnimVariants}
								>
									<Divider style={{ marginTop: 12, marginBottom: 12 }} />
									<Col flex="auto">
										<Form form={formFilters}>
											<Row>
												{courseAttis.length > 0 && (
													<>
														{courseAttis.map((item, index) => (
															<Col
																style={{ paddingRight: 12 }}
																span={12}
																key={index}
															>
																<p className="Key">{item.label}</p>
																<Form.Item name={item.ref}>
																	<Select
																		onChange={(val) =>
																			handleFilterState(
																				item.ref,
																				val
																			)
																		}
																		placeholder={`Filter by ${item.label.toLowerCase()}`}
																		style={{
																			width: "100%",
																		}}
																	>
																		{attributes[item.ref] &&
																			attributes[
																				item.ref
																			].map((item, index) => (
																				<Select.Option
																					key={index}
																					value={item}
																				>
																					{item}
																				</Select.Option>
																			))}
																	</Select>
																</Form.Item>
															</Col>
														))}
														<Col style={{ paddingRight: 12 }} span={12}>
															<p className="Key">Price</p>
															<Form.Item name="price">
																<Select
																	onChange={(val) =>
																		handleFilterState(
																			"price",
																			val
																		)
																	}
																	placeholder="Sort by price"
																	style={{
																		width: "100%",
																	}}
																>
																	<Select.Option
																		key="1"
																		value="desc"
																	>
																		High to low
																	</Select.Option>
																	<Select.Option
																		key="2"
																		value="asc"
																	>
																		Low to high
																	</Select.Option>
																</Select>
															</Form.Item>
														</Col>
													</>
												)}
											</Row>
										</Form>
									</Col>
									<Col span={24}>
										<Row
											style={{ height: "100%", width: "100%" }}
											justify="end"
											align="bottom"
										>
											<Button
												onClick={() => resetFilters()}
												data-testid="course-reset"
											>
												Reset
											</Button>
											<Button
												onClick={() => applyFilters()}
												type="primary"
												style={{ marginLeft: 12 }}
												data-testid="course-filter"
											>
												Apply
											</Button>
										</Row>
									</Col>
								</motion.div>
							</Col>
						</Row>
					)}
					<Row style={{ width: "100%", overflow: "hidden", marginBottom: 42 }}>
						<List
							pagination={
								filteredData.length > 0
									? {
											pageSize: 9,
									  }
									: false
							}
							style={{
								width: "100%",
								height: "100%",
								overflow: "hidden",
								paddingBottom: 24,
							}}
							grid={{ column: isMobile ? 1 : 3, gutter: 24 }}
							dataSource={filteredData}
							renderItem={(item, index) => (
								<Col key={index}>{<CourseCardLite data={item} />}</Col>
							)}
							data-testid="course-list"
						/>
					</Row>
				</Col>
			</Content>
		</Layout>
	);
};

export default ViewProviderCourses;
