import { Row, Col, Typography, Divider, Button, Skeleton } from "antd";
import React, { useContext, useState } from "react";
import UserContext from "../../Context/UserContext";
import { priceFormatter } from "../../Functions/formatters";
import { colorsLight } from "../../Styles/Themes";
import PurchaseModal from "../PurchaseModal(courses+services)/PurchaseModal";

const CoursePurchasePanel = ({ data, providerData }) => {
	const [modal, setModal] = useState(false);
	const { accountBalance } = useContext(UserContext);

	const toggleModal = () => setModal(!modal);

	return (
		<Col className="Card">
			<Typography.Title level={5}>Redeem</Typography.Title>
			<Divider style={{ margin: "0px 0px 10px" }} />
			<Row justify="space-between" align="bottom" style={styles.row}>
				<Col span={12}>
					<p style={styles.name}>Your current balance</p>
				</Col>
				<Col span={12}>
					<p style={styles.balance}>
						{accountBalance ? (
							priceFormatter(accountBalance || 0, "points") + " pts"
						) : (
							<Skeleton
								active
								paragraph={false}
								title={{ width: "80%", style: { margin: 0, float: "right" } }}
							/>
						)}
					</p>
				</Col>
			</Row>
			<Row align="bottom" style={styles.row}>
				<Col span={12}>
					<p style={styles.name}>Price</p>
				</Col>
				<Col span={12}>
					<p style={styles.price}>
						{data.price ? (
							priceFormatter(data.price || 0, "points") + " pts"
						) : (
							<Skeleton
								active
								paragraph={false}
								title={{ width: "80%", style: { margin: 0, float: "right" } }}
							/>
						)}
					</p>
				</Col>
			</Row>
			<Row justify="end">
				<Button
					onClick={toggleModal}
					type="primary"
					disabled={!data.price || !accountBalance}
				>
					Purchase Now
				</Button>
			</Row>
			<PurchaseModal
				type="course"
				visible={modal}
				dismiss={toggleModal}
				data={data}
				providerData={providerData}
			/>
		</Col>
	);
};

const styles = {
	row: {
		marginBottom: 20,
		padding: "0px 12px 0px",
	},
	name: {
		fontSize: 14,
		marginBottom: 0,
	},
	price: {
		fontSize: 22,
		fontWeight: 700,
		color: colorsLight("lightBlue"),
		textAlign: "right",
		marginBottom: 0,
	},
	balance: {
		fontSize: 22,
		color: colorsLight("grey"),
		textAlign: "right",
		marginBottom: 0,
	},
};

export default CoursePurchasePanel;
