import { Col, Row } from "antd";
import React from "react";
import Lottie from "react-lottie";
import processing from "../../Assets/processing.json";

const ProcessingPayment = () => {
	const animOptions = {
		loop: true,
		autoplay: true,
		animationData: processing,
	};
	return (
		<Col span={24}>
			<Row justify="center" align="middle" style={{ height: 300, width: "100%" }}>
				<Lottie options={animOptions} width={160} isStopped={false} isPaused={false} />
			</Row>
		</Col>
	);
};

export default ProcessingPayment;
