import { firestore } from "firebase";
import React, { useContext, useEffect, useState } from "react";
import UserContext from "./UserContext";

const DataContext = React.createContext();

export const DataProvider = ({ children }) => {
	const [coaches, setCoaches] = useState([]);
	const [packages, setPackages] = useState([]);
	const [courses, setCourses] = useState([]);
	const [courseProviders, setCourseProviders] = useState([]);
	const [services, setServices] = useState([]);
	const [serviceProviders, setServiceProviders] = useState([]);
	const [programs, setPrograms] = useState([]);
	const { userInfo, playlistData } = useContext(UserContext);

	//ONCE PLAYLIST DATA IS AVAILABLE, GETS THE ITEM DATA AND SAVES TO STATE
	useEffect(() => {
		if (userInfo.activePlaylist && playlistData !== "none") {
			getPlaylistData(playlistData);
		} else if (userInfo.firstName.length > 1 && playlistData === false) {
			console.log("No playlist detected");
			firestore()
				.collection("Packages")
				.get()
				.then((snap) => {
					let list = [];
					snap.forEach((doc) => {
						list.push({ ...doc.data(), key: doc.id });
					});
					setPackages(list);
				});

			firestore()
				.collection("Courses")
				.get()
				.then((snap) => {
					let list = [];
					snap.forEach((doc) => {
						list.push({ ...doc.data(), key: doc.id });
					});
					setCourses(list);
				});

			firestore()
				.collection("CourseProviders")
				.where("status", "==", "active")
				.get()
				.then((snap) => {
					let list = [];
					snap.forEach((doc) => {
						list.push({ ...doc.data(), key: doc.id });
					});
					setCourseProviders(list);
				});

			firestore()
				.collection("Services")
				.get()
				.then((snap) => {
					let list = [];
					snap.forEach((doc) => {
						list.push({ ...doc.data(), key: doc.id });
					});
					setServiceProviders(list);
				});

			firestore()
				.collection("ServiceProviders")
				.where("status", "==", "active")
				.get()
				.then((snap) => {
					let list = [];
					snap.forEach((doc) => {
						list.push({ ...doc.data(), key: doc.id });
					});
					setServiceProviders(list);
				});
		}
		// eslint-disable-next-line
	}, [playlistData, userInfo]);

	const getPlaylistData = async (activePlaylist) => {
		const serviceProviderIds = getProviders(activePlaylist.services);
		const courseProviderIds = getProviders(activePlaylist.courses);

		let courseProviderPromises = [];
		let coursePromises = [];

		let serviceProviderPromises = [];
		let servicePromises = [];

		let packagePromises = [];

		//SETS UP COACH PACKAGE PROMISES
		activePlaylist.packages.forEach((item) => {
			packagePromises.push(firestore().collection("Packages").doc(item.key).get());
		});

		//SETS UP COURSE PROVIDER PROMISES
		courseProviderIds.forEach((item) => {
			courseProviderPromises.push(firestore().collection("CourseProviders").doc(item).get());
		});

		//SETS UP SERVICE PROVIDER PROMISES
		serviceProviderIds.forEach((item) => {
			serviceProviderPromises.push(
				firestore().collection("ServiceProviders").doc(item).get()
			);
		});

		//SETS UP COURSE PROMISES
		activePlaylist.courses.forEach((item) => {
			coursePromises.push(firestore().collection("Courses").doc(item.key).get());
		});

		//SETS UP SERVICE PROMISES
		activePlaylist.services.forEach((item) => {
			servicePromises.push(firestore().collection("Services").doc(item.key).get());
		});

		try {
			//GETS THE COACHING PACKINGS
			await Promise.all(packagePromises)
				.then((docs) => {
					let list = [];
					docs.forEach((doc) => {
						list.push({ ...doc.data(), key: doc.id });
					});
					setPackages(list);
				})
				.catch((err) => {
					console.log("Error resolving coaching packages");
				});

			//GETS THE COURSE PROVIDERS
			await Promise.all(courseProviderPromises)
				.then((docs) => {
					let list = [];
					docs.forEach((doc) => {
						list.push({ ...doc.data(), key: doc.id });
					});
					setCourseProviders(list);
				})
				.catch((err) => {
					console.log("Error resolving course providers");
				});

			//GETS THE SERVICE PROVIDERS
			await Promise.all(serviceProviderPromises)
				.then((docs) => {
					let list = [];
					docs.forEach((doc) => {
						list.push({ ...doc.data(), key: doc.id });
					});
					setServiceProviders(list);
				})
				.catch((err) => {
					console.log("Error resolving service providers");
				});

			//GETS THE COURSES
			await Promise.all(coursePromises)
				.then((docs) => {
					let list = [];
					docs.forEach((doc) => {
						list.push({ ...doc.data(), key: doc.id });
					});
					setCourses(list);
				})
				.catch((err) => {
					console.log("Error resolving courses");
				});

			//GETS THE SERVICES
			await Promise.all(servicePromises)
				.then((docs) => {
					let list = [];
					docs.forEach((doc) => {
						list.push({ ...doc.data(), key: doc.id });
					});
					setServices(list);
				})
				.catch((err) => {
					console.log("Error resolving services");
				});
		} catch (err) {
			console.log(err);
		}
	};

	const getProviders = (array) => {
		let providers = [];
		array.forEach((item) => {
			if (!providers.includes(item.provider)) {
				providers.push(item.provider);
			}
		});
		return providers;
	};

	return (
		<DataContext.Provider
			value={{
				coaches,
				setCoaches,
				packages,
				setPackages,
				courseProviders,
				setCourseProviders,
				serviceProviders,
				setServiceProviders,
				services,
				setServices,
				courses,
				setCourses,
				programs,
				setPrograms,
			}}
		>
			{children}
		</DataContext.Provider>
	);
};

export default DataContext;
