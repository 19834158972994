import { Row, Col, Typography } from "antd";
import React, { useContext } from "react";
import UserContext from "../../Context/UserContext";
import NorthWestPanel from "./NorthWestActionPanel";

const ActionPanel = () => {
	const { userInfo } = useContext(UserContext);
	return (
		<Col className="Card">
			{userInfo.parentAccount !== "O76t18jL40Vn2BHIufLP" ? (
				<>
					{" "}
					<Row style={{ paddingLeft: 12 }}>
						<Typography.Title
							style={{ fontWeight: 400, width: "66%" }}
							level={3}
							data-testid="action-title"
						>
							Explore learning & upskilling opportunities, wellbeing programs and
							coaching packages.
						</Typography.Title>
					</Row>
					<Row style={{ minHeight: 244, paddingTop: 64 }} data-testid="action-content">
						<Col span={8}>
							<Row style={{ overflowWrap: "revert" }}>
								<Col flex="20px">
									<Typography.Title>1.</Typography.Title>
								</Col>
								<Col flex="200px">
									<p style={styles.text}>
										Book time with your head coach for an overview and
										consultation
									</p>
								</Col>
							</Row>
						</Col>
						<Col span={8}>
							<Row style={{ overflowWrap: "revert" }}>
								<Col flex="20px">
									<Typography.Title>2.</Typography.Title>
								</Col>
								<Col flex="200px">
									<p style={styles.text}>
										Browse coaches, courses and services to choose a path that
										works for you
									</p>
								</Col>
							</Row>
						</Col>
						<Col span={8}>
							<Row style={{ overflowWrap: "revert" }}>
								<Col flex="20px">
									<Typography.Title>3.</Typography.Title>
								</Col>
								<Col flex="200px">
									<p style={styles.text}>
										Use your points and start your program
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</>
			) : (
				<NorthWestPanel />
			)}
		</Col>
	);
};

const styles = {
	text: {
		marginTop: 3,
		marginLeft: 6,
	},
};

export default ActionPanel;
