import { Col, Layout } from "antd";
import React, { useContext } from "react";
import HeaderMain from "../../Components/Headers/Header";
import ViewContext from "../../Context/ViewContext";
import { colorsLight } from "../../Styles/Themes";

const { Content } = Layout;

const CoursesBrowse = () => {
	const { isMobile } = useContext(ViewContext);

	return (
		<Layout className={isMobile ? "layout" : "App"}>
			<HeaderMain active="5" />
			<Content className="content">
				<Col
					style={{ backgroundColor: colorsLight("lightgrey"), height: "100%" }}
					span={24}
				></Col>
			</Content>
		</Layout>
	);
};

export default CoursesBrowse;
