import { colorsLight } from "../Styles/Themes";
import { randomColour } from "./helpers";

export const getInitials = (name = "") => {
	const parts = name.split(" ");
	let initials = "";
	for (var i = 0; i < parts.length; i++) {
		if (parts[i].length > 0 && parts[i] !== "") {
			initials += parts[i][0];
		}
	}
	return initials;
};

export const coachTagFormatter = (type) => {
	switch (type) {
		case "Executive":
			return "magenta";
		case "Leadership":
			return "cyan";
		case "Career":
			return "purple";
		case "Job Search":
			return "orange";
		case "Wellness":
			return "red";
		case "Wellbeing":
			return "rebeccaPurple";
		case "Fitness":
			return "orangeRed";
		case "Parental":
			return "salmon";
		case "Meditation":
			return "slateBlue";
		case "Negotiation":
			return "teal";
		case "Public Relations":
			return "springGreen";
		case "Branding":
			return "darkSlateBlue";
		case "Transition":
			return "darkSlateGrey";
		case "Outplacement":
			return "mediumSpringGreen";
		case "Finance":
			return "seaGreen";
		default:
			return randomColour();
	}
};

// "Executive",
// 	"Leadership",
// 	"Career",
// 	"Job Search",
// 	"Wellness",
// 	"Wellbeing",
// 	"Fitness",
// 	"Parental",
// 	"Meditation",
// 	"Negotiation",
// 	"Public Relations",
// 	"Branding",
// 	"Transition",
// 	"Outplacement",
// 	"Finance",

export const statusTagFormatter = (type) => {
	switch (type) {
		case "inactive":
			return "#FE4D4F";
		case "invited":
			return colorsLight("lightBlue");
		case "disabled":
			return "#FE4D4F";
		case "active":
			return colorsLight("green");
		case "pending":
			return colorsLight("burntOrange");
		default:
			return "orange";
	}
};

export const markupCalculator = (costPrice, markup) => {
	let markupAsPerc = markup / 100;
	let profitMargin = costPrice * markupAsPerc;
	let result = Number((costPrice + profitMargin).toFixed(2));
	return result;
};

export const convertArrayToObject = (array, key) => {
	const initialValue = {};
	return array.reduce((obj, item) => {
		return {
			...obj,
			[item[key]]: item,
		};
	}, initialValue);
};

export const priceFormatter = (num, type) => {
	if (type === "dollars") {
		const res = num.toLocaleString("en-AU", { style: "currency", currency: "AUD" });
		return res;
	} else {
		const converted = num * 3.142;
		const res = Number(converted.toFixed(0)).toLocaleString();
		return res;
	}
};

export const amountFormatter = (num, type) => {
	const cleaned = num * 3.142;
	const formatted = Number(cleaned.toFixed(0)).toLocaleString();
	if (type === "credit") {
		return `+${formatted} pts`;
	} else if (type === "purchase" || type === "purchase") {
		return `-${formatted} pts`;
	} else {
		return "error";
	}
};

export const getTitleSize = (string, isMobile) => {
	const size = string.length;
	if (isMobile) {
		if (size <= 24) {
			return 2;
		} else if (size > 24 && size <= 42) {
			return 3;
		} else if (size > 42 && size <= 100) {
			return 4;
		} else if (size > 100) {
			return 5;
		} else {
			return 5;
		}
	} else {
		if (size <= 24) {
			return 1;
		} else if (size > 24 && size <= 42) {
			return 2;
		} else if (size > 42 && size <= 100) {
			return 3;
		} else if (size > 100) {
			return 4;
		} else {
			return 5;
		}
	}
};
