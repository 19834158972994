import React, { useContext } from "react";
import { Col, Row, Divider, Typography, Badge, Skeleton } from "antd";
import { getImage } from "../../Functions/utilities";
import { colorsLight } from "../../Styles/Themes";
import { getPoints } from "../../Functions/helpers";
import { coachTagFormatter } from "../../Functions/formatters";
import ViewContext from "../../Context/ViewContext";

const { Title, Paragraph } = Typography;

const PackageDetails = ({ data, loading = false }) => {
	const { isMobile } = useContext(ViewContext);
	const styles = {
		wrapper: {
			borderRadius: 9,
			backgroundColor: colorsLight("white"),
		},
		header: {
			backgroundImage: data ? `url(${data.coverPic ? data.coverPic : getImage()})` : null,
			backgroundSize: "cover",
			height: 117,
			width: "100%",
			borderRadius: "9px 9px 0px 0px",
		},
		imageOverlay: {
			backgroundColor: loading ? "rgb(216 216 216)" : "rgba(2, 48, 71,0.7)",
			height: "100%",
			width: "100%",
			borderRadius: "9px 9px 0px 0px",
			margin: 0,
			padding: isMobile ? "0px 12px" : "0px 20px",
		},
		name: {
			color: "#ffffff",
			marginBottom: 12,
		},
		coachType: {
			fontSize: 14,
			color: "#5c5c5c",
			marginBottom: 15,
		},
		avatar: {
			padding: 4,
			backgroundColor: "white",
			boxShadow: "1px 1px 6px rgba(0,0,0,0.2)",
			borderRadius: "50%",
			margin: "0px 0px 10px 0px",
			maxWidth: 145,
			height: 145,
			position: "absolute",
			top: 70,
		},
		coachHeader: {
			width: "100%",
			borderRadius: "9px 9px 0px 0px",
			margin: 0,
			padding: "0px 20px",
		},
		title: {
			fontWeight: "bold",
			fontSize: 22,
			width: "100%",
		},
		description: {
			fontSize: 14,
			marginBottom: 0,
		},
		video: {
			marginTop: 10,
			width: "100%",
		},
		row: {
			padding: isMobile ? "20px 12px" : "20px 30px",
			width: "100%",
		},
		label: {
			fontSize: isMobile ? 16 : 18,
			color: "#565656",
			fontWeight: 600,
		},
		text: {
			fontSize: isMobile ? 16 : 18,
			color: "#000000",
		},
		pts: {
			fontWeight: 600,
			fontSize: 18,
			color: colorsLight("lightBlue"),
		},
	};

	return (
		<Badge.Ribbon
			placement="start"
			color={data.primaryCategory ? coachTagFormatter(data.primaryCategory) : "blue"}
			text={data.primaryCategory ? data.primaryCategory.toUpperCase() : "Loading"}
			style={{ display: data.primaryCategory ? "block" : "none" }}
		>
			<Row style={styles.wrapper}>
				<Row style={styles.header}>
					<Row
						justify="space-between"
						align="bottom"
						style={styles.imageOverlay}
						data-testid="package-overlay"
					>
						{data.name ? (
							<Typography.Title
								style={styles.name}
								data-testid="package-name"
								level={isMobile ? 4 : 3}
							>
								{data.name || "Loading"}
							</Typography.Title>
						) : (
							<Skeleton paragraph={false} title={{ width: "50%" }} active />
						)}
					</Row>
				</Row>
				<Row justify="space-between" style={styles.row}>
					<Col>
						<div style={styles.label}>Delivery:</div>
						<div style={styles.text} data-testid="package-delivery">
							{data.delivery ? (
								<>{data.delivery}</>
							) : (
								<Skeleton active paragraph={false} title={{ width: "80%" }} />
							)}
						</div>
					</Col>
					<Col>
						<div style={styles.label}>Duration:</div>
						<div style={styles.text} data-testid="package-sessions">
							{data.sessions ? (
								<>{`${data.sessions} Sessions`}</>
							) : (
								<Skeleton active paragraph={false} title={{ width: "80%" }} />
							)}
						</div>
					</Col>
					<Col>
						<div style={styles.label}>Price:</div>
						<div style={styles.pts} data-testid="package-price">
							{data.price ? (
								<>{getPoints(data.price || 0)}</>
							) : (
								<Skeleton active paragraph={false} title={{ width: "80%" }} />
							)}
						</div>
					</Col>
				</Row>
				<Divider style={{ margin: "10px 0px 0px" }} />
				<Row style={styles.row}>
					<Title style={styles.title}>Summary</Title>
					{data.summary ? (
						<Paragraph style={styles.description} data-testid="package-summary">
							{data.summary}
						</Paragraph>
					) : (
						<Skeleton active paragraph={{ rows: 3 }} title={false} />
					)}
				</Row>
				{data.details !== "<p> </p>" && (
					<Row style={styles.row}>
						<Title style={styles.title}>Details</Title>
						{data.details ? (
							<div
								dangerouslySetInnerHTML={{ __html: data.details }}
								data-testid="package-details"
							/>
						) : (
							<Skeleton active paragraph={{ rows: 3 }} title={false} />
						)}
					</Row>
				)}
			</Row>
		</Badge.Ribbon>
	);
};

export default PackageDetails;
