import { Col, Row, Typography } from "antd";
import React, { useContext } from "react";
import ViewContext from "../../Context/ViewContext";
import CoachCard from "../Store/Cards/CoachPackage";
const { Title } = Typography;

const OtherOffersBuyPanel = ({ data, loading = false }) => {
	const { isMobile } = useContext(ViewContext);
	const styles = {
		title: {
			marginBottom: 32,
			paddingTop: 12,
			maxWidth: "66%",
		},
		list: {
			overflowX: "scroll",
			display: "flex",
			flexWrap: "nowrap",
			width: "100%",
			paddingLeft: 6,
		},
		itemRow: {
			flexDirection: "row",
			overflowY: "hidden",
			overflowX: "scroll",
			display: "flex",
			marginBottom: 24,
			width: "100%",
		},
	};

	return (
		<Col>
			<Title style={styles.title} level={3}>
				You might also be interested in
			</Title>
			<Row style={{ width: "100%" }}>
				{data.length > 0 && (
					<div style={styles.itemRow}>
						{data.map((item, index) => (
							<div
								style={{ padding: isMobile ? "0px 12px 0px 0px" : "0px 0px" }}
								key={index}
							>
								<CoachCard data={item} />
							</div>
						))}
					</div>
				)}
			</Row>
		</Col>
	);
};

export default OtherOffersBuyPanel;
