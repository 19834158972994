import { Button, Col, Row, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { priceFormatter } from "../../Functions/formatters";
import { colorsLight } from "../../Styles/Themes";

const ServiceCardLite = ({ data = {} }) => {
	const styles = {
		card: {
			padding: 0,
			overflow: "hidden",
		},
		imageHeader: {
			backgroundImage: `url(${data.coverPic})`,
			backgroundSize: "cover",
			width: "100%",
			height: 120,
		},
		imageCover: {
			backgroundColor: colorsLight("cardCover"),
			height: "100%",
			width: "100%",
			padding: 12,
		},
	};

	return (
		<Col className="Card" style={styles.card}>
			<Row style={styles.imageHeader}>
				<Row justify="start" align="bottom" style={styles.imageCover}>
					<Typography.Title
						style={{ width: "66%", color: "white" }}
						level={4}
						data-testid="course-name"
					>
						{data.name}
					</Typography.Title>
				</Row>
			</Row>

			<Row style={{ padding: 12 }}>
				<Col span={24}>
					<p className="Key">Summary</p>
					<Typography.Paragraph
						ellipsis={{
							rows: 3,
							expandable: false,
							symbol: "...",
							suffix: <a href={`/View-Service/${data.owner}&${data.key}`}>Read on</a>,
						}}
						data-testid="course-summary"
					>
						{data.summary}
					</Typography.Paragraph>
				</Col>
			</Row>
			<Row style={{ backgroundColor: "white", padding: "12px 0px 0px 12px" }}>
				<Col span={15} />
				<Col span={8}>
					<p className="Key">Price</p>
					<p
						style={{
							fontSize: 18,
							color: colorsLight("lightBlue"),
							fontWeight: "bold",
						}}
						data-testid="course-price"
					>
						{`${priceFormatter(data.price, "points")} pts` || "-"}
					</p>
				</Col>
			</Row>
			<Row style={{ padding: "0px 12px 12px 0px" }} justify="end">
				<Link to={`/View-Service/${data.owner}&${data.key}`} data-testid="course-view">
					<Button>View Service</Button>
				</Link>
			</Row>
		</Col>
	);
};

export default ServiceCardLite;
